import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { EmailValidator, EmailTypes } from '../services/EmailValidator';
import '../styles/components/SubmitPlanModal.scss';

/** SubmitPlanModal functional component */
const SubmitPlanModal = ({
  isOpen,
  handleClose,
  onSubmitApproval,
  template,
  plan,
  emails,
  confirmButtonText,
}) => {
  const [toEmail, setToEmail] = useState(emails.avp?.join(', ') || '');
  const [toCC, setToCC] = useState(emails.vp?.join(', ') || '');
  const [showToEmailError, setShowToEmailError] = useState(false);
  const [showCCEmailError, setShowCCEmailError] = useState(false);
  const { t } = useTranslation('common');
  const [emailComments, setEmailComments] = useState('');

  const customStyles = {
    overlay: {
      zIndex: '99',
      background: 'rgba(192,192,192,0.75)',
    },
    content: {
      margin: 'auto',
      height: '650px',
      width: '750px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflow: 'visible',
      zIndex: '99',
      borderRadius: '1rem',
    },
  };

  useEffect(() => {
    setToEmail(emails.avp?.join(', ') || '');
    setToCC(emails.vp?.join(', ') || '');
  }, [isOpen]);

  /** Clears any validation errors and calls prop close function */
  const onModalClose = () => {
    setShowToEmailError(false);
    setShowCCEmailError(false);
    handleClose();
  };

  /**
   * Verifies emails from user input are valid before sending
   */
  const sendEmail = () => {
    const [formattedTo, validTo] = EmailValidator(toEmail, EmailTypes.To);
    const [formattedCC, validCC] = EmailValidator(toCC, 'cc');
    setShowToEmailError(!validTo);
    setShowCCEmailError(!validCC);
    if (validTo && validCC) {
      onSubmitApproval(formattedTo, formattedCC, emailComments);
    }
  };

  /** Replaces specific template variables from the email template */
  const parseEmailTemplate = (emailTemplate) => emailTemplate
    .replace('{{planName}}', plan.name)
    .replace('{{viewPlanLink}}', `${window.location.protocol}//${window.location.host}/view-plan/?planId=${plan._id}`);
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} style={customStyles} ariaHideApp={false}>
      <p className="email-modal-text">{t('editEmail')}</p>
      <div className="input-div">
        <p className="input-text">{t('to')}</p>
        <input
          id="send-to-email"
          type="text"
          className="to-email-input"
          value={toEmail}
          onChange={(e) => setToEmail(e.target.value)}
        />
        {
          showToEmailError && (
            <p className="email-error">{t('emailError')}</p>
          )
        }
      </div>
      <div className="input-div">
        <p className="input-text">cc</p>
        <input
          id="send-to-cc"
          type="text"
          className="to-email-input"
          value={toCC}
          onChange={(e) => setToCC(e.target.value)}
        />
        {
          showCCEmailError && (
            <p className="email-error">{t('emailError')}</p>
          )
        }
      </div>
      <div className="email-container">
        {/* Email templates are DB constants; end users are not permitted to modify them */}
        {/* DOMPurify is included as an extra safeguard */}
        {/* eslint-disable-next-line react/no-danger */}
        <div id="email-template" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(parseEmailTemplate(template)) }} />
      </div>
      <textarea
        className="email-textarea"
        id="user-comments"
        placeholder={t('addNotes')}
        value={emailComments}
        onChange={(e) => setEmailComments(e.target.value)}
      />

      <div className="modal-buttons">
        <button type="button" id="okButton" onClick={sendEmail}>
          {confirmButtonText || t('ok')}
        </button>
        <button type="button" id="cancelButton" onClick={onModalClose}>
          {t('cancel')}
        </button>
      </div>
    </Modal>
  );
};

SubmitPlanModal.defaultProps = {
  isOpen: false,
  confirmButtonText: '',
  template: '',
};

SubmitPlanModal.propTypes = {
  plan: PropTypes.shape({
    name: PropTypes.string,
    _id: PropTypes.string,
  }).isRequired,
  emails: PropTypes.shape({
    vp: PropTypes.arrayOf(PropTypes.string),
    avp: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  template: PropTypes.string,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  onSubmitApproval: PropTypes.func.isRequired,
  confirmButtonText: PropTypes.string,
};

export default SubmitPlanModal;
