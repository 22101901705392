import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { EmailTypes, EmailValidator } from '../services/EmailValidator';
import { Status } from '../enums/PlanStatus';

import '../styles/components/AproveModal.scss';

/**
 * ApproveModal functional component
 * */
const ApproveModal = ({
  isOpen,
  handleClose,
  handleApprove,
  template,
  plan,
  emails,
  userName,
}) => {
  const customStyles = {
    overlay: {
      zIndex: '99',
      background: 'rgba(192,192,192,0.75)',
    },
    content: {
      margin: 'auto',
      height: '675px',
      width: '750px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflow: 'visible',
      zIndex: '99',
    },
  };

  const [toEmail, setToEmail] = useState('');
  const [toCC, setToCC] = useState('');
  const [showToEmailError, setShowToEmailError] = useState(false);
  const [showCCEmailError, setShowCCEmailError] = useState(false);
  const [comments, setComments] = useState('');
  const { t } = useTranslation('common');

  useEffect(() => {
    setToEmail(emails?.join(', ') || '');
  }, [isOpen]);

  /** Clears any validation errors and calls prop close function */
  const onModalClose = () => {
    setShowToEmailError(false);
    setShowCCEmailError(false);
    handleClose();
  };

  /**
   * Verifies emails from user input are valid before sending
   */
  const approvePlan = () => {
    const [formattedTo, validTo] = EmailValidator(toEmail, EmailTypes.To);
    const [formattedCC, validCC] = EmailValidator(toCC, EmailTypes.CC);
    setShowToEmailError(!validTo);
    setShowCCEmailError(!validCC);
    if (validTo && validCC) {
      handleApprove(formattedTo, formattedCC, comments, Status.Approved);
    }
  };

  /**
   * Gets approval email template
   */
  const parseEmailTemplate = (emailTemplate) => emailTemplate
    .replace('{{planName}}', plan.name)
    .replace('{{userName}}', userName)
    .replace('{{planDate}}', moment().add(6, 'months').format('Do MMMM y'))
    .replace('{{downloadPlanLink}}', `${window.location.protocol}//${window.location.host}/view-plan/?planId=${plan._id}`)
    .replace('{{downloadName}}', 'demo.pdf')
    .replace('{{bcpEmail}}', 'business_continuity@cooperators.ca')
    .replace('{{viewPlanLink}}', `${window.location.protocol}//${window.location.host}/view-plan/?planId=${plan._id}`);

  return (
    <Modal
      className="modal"
      isOpen={isOpen}
      handleClose={handleClose}
      style={customStyles}
      ariaHideApp={false}
    >
      <p className="ap-email-modal-text">{t('editApproveEmail')}</p>
      <div className="input-div">
        <p className="ap-input-text">{t('to')}</p>
        <input
          type="text"
          id="send-to-email"
          className="to-email-input"
          value={toEmail}
          onChange={(e) => setToEmail(e.target.value)}
        />
        {
          showToEmailError && (
            <p className="approve-email-error">{t('emailError')}</p>
          )
        }
      </div>
      <div className="input-div">
        <p className="ap-input-text">cc</p>
        <input
          type="text"
          id="send-to-cc"
          className="to-email-input"
          value={toCC}
          onChange={(e) => setToCC(e.target.value)}
        />
        {
          showCCEmailError && (
            <p className="approve-email-error">{t('emailError')}</p>
          )
        }
      </div>

      <div className="ap-email-container">
        {/* Email templates are DB constants; end users are not permitted to modify them */}
        {/* DOMPurify is included as an extra safeguard */}
        {/* eslint-disable-next-line react/no-danger */}
        <div className="ap-email-template" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(parseEmailTemplate(template)) }} />
      </div>
      <textarea
        className="ap-email-textarea"
        placeholder={t('addNotes')}
        onChange={(e) => setComments(e.target.value)}
      />

      <div className="apButtons">
        <button type="button" className="approveApButton" id="approveButton" onClick={approvePlan}>
          {t('approve')}
        </button>
        <button type="button" className="cancelApButton" id="cancelButton" onClick={onModalClose}>
          {t('cancel')}
        </button>
      </div>
    </Modal>
  );
};

ApproveModal.defaultProps = {
  isOpen: false,
};

ApproveModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  plan: PropTypes.shape({
    name: PropTypes.string,
    _id: PropTypes.string,
  }).isRequired,
  emails: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleApprove: PropTypes.func.isRequired,
  template: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
};

export default ApproveModal;
