import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import ConfirmDeleteModal from './ConfirmDeleteModal';

import '../styles/components/BusinessActivitesOptionsDropdown.scss';

/** BusinessActivitesOptionsDropdown functional component */
const BusinessActivitesOptionsDropdown = ({
  activityName,
  activitiesLength,
  deleteBA,
  handleCloneBusinessActivityClick,
  handleNewBusinessActivityClick,
}) => {
  const { t } = useTranslation('common');

  const [showModal, setShowModal] = useState(false);

  return (
    <div className="dropdown-list">
      <button className="dropbtn" type="button">{`${t('edit')} ▼`}</button>
      <div className="dropdown-content-business-activites">
        <button
          data-tip
          data-for="cloneTip"
          type="button"
          onClick={handleCloneBusinessActivityClick}
        >
          {t('clone')}
        </button>
        <button
          data-tip
          data-for="addNewTip"
          type="button"
          onClick={handleNewBusinessActivityClick}
        >
          {t('addNew')}
        </button>
        <button
          data-tip
          data-for="deleteTip"
          type="button"
          onClick={() => { setShowModal(true); }}
        >
          {t('delete')}
        </button>
        <ReactTooltip id="cloneTip" place="right" type="light" effect="solid" border>
          <div>{t('cloneBusinessActivity')}</div>
        </ReactTooltip>
        <ReactTooltip id="addNewTip" place="right" type="light" effect="solid" border>
          <div>{t('addNewBusinessActivity')}</div>
        </ReactTooltip>
        <ReactTooltip id="deleteTip" place="right" type="light" effect="solid" border>
          <div>{t('deleteBusinessActivity')}</div>
        </ReactTooltip>
      </div>
      <ConfirmDeleteModal
        deleteBA={deleteBA}
        activityName={activityName}
        isOpen={showModal}
        handleClose={() => { setShowModal(false); }}
        lengthEqualOne={activitiesLength === 1}
        dropdown
      />
    </div>
  );
};

BusinessActivitesOptionsDropdown.defaultProps = {
  activityName: null,
  activitiesLength: null,
  deleteBA: () => { },
  handleCloneBusinessActivityClick: () => { },
  handleNewBusinessActivityClick: () => { },
};

BusinessActivitesOptionsDropdown.propTypes = {
  activityName: PropTypes.string,
  activitiesLength: PropTypes.number,
  deleteBA: PropTypes.func,
  handleCloneBusinessActivityClick: PropTypes.func,
  handleNewBusinessActivityClick: PropTypes.func,
};

export default BusinessActivitesOptionsDropdown;
