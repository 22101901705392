import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';
import PlanBaseInput from './PlanBaseInput';
import '../styles/components/ReportsHintModal.scss';
import '../styles/components/PlanInput.scss';

/**
 * PlanDataSelectInput functional component
 * Renders a select element intended to handle plan inputs with a data source
 * @param {*} props component props
 * @returns {JSX} component JSX
 */
export const PlanDataSelectInput = (props) => {
  const {
    inputProperties,
    parent,
    selectedIndex,
    onChange,
  } = props;
  const { t } = useTranslation('common');
  const { i18n } = useTranslation();
  const { language } = i18n;

  const { data } = inputProperties;
  const { displayKey } = inputProperties.dataSource;
  const id = `${parent.join('-')}`;
  const [otherSelected, setOtherSelected] = useState(false);
  const [customTextValue, setCustomTextValue] = useState('');

  const customOnChange = (inputKey, valueObject) => {
    if (valueObject && valueObject.name && valueObject.name === '*Other...') {
      onChange(inputKey, null);
      setOtherSelected(true);
    } else {
      onChange(inputKey, valueObject);
    }
  };

  const handleInputChange = (event) => {
    setCustomTextValue(event.target.value);
    onChange(parent, { name: event.target.value });
  };

  const handleOtherClose = () => {
    onChange(parent, null);
    setCustomTextValue('');
    setOtherSelected(false);
  };

  // Determine placeholder text to display before user selects item
  const placeholder = (inputProperties.placeholder ? inputProperties.placeholder[language] : t('select'));
  const inputElement = (
    <div className="plan-select-input">
      {otherSelected ? (
        <>
          <input
            className="plan-text-input"
            type="text"
            value={customTextValue || ''}
            placeholder={t('enterCustomEBP')}
            onChange={handleInputChange}
          />
          <button className="modal-close-button" type="button" id="cancelOtherSelectButton" onClick={handleOtherClose}>
            x
          </button>

        </>
      ) : (
        <>
          <select
            id={id}
            onChange={(e) => customOnChange(parent, data[e.target.value])}
            value={selectedIndex}
          >
            {inputProperties.displayNullOption
              ? (<option value={-1}>{placeholder}</option>)
              : (<option value={-1} disabled hidden>{placeholder}</option>)}
            {data.map((value, index) => (
              <option key={`select-${value[displayKey]}-${id}-${uuidv4()}`} value={index}>
                {value[displayKey]}
              </option>
            ))}
          </select>
          <FontAwesomeIcon className="select-caret" icon={faCaretRight} />
        </>
      ) }
    </div>
  );

  return (
    <PlanBaseInput
      displayLabel={false}
      parent={parent}
      inputProperties={inputProperties}
      inputElement={inputElement}
      onChange={onChange}
    />
  );
};

PlanDataSelectInput.propTypes = {
  inputProperties: PropTypes.shape({
    placeholder: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    allowUserDefined: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.shape({})),
    dataKey: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({})]),
    ),
    dataSource: PropTypes.shape({
      displayKey: PropTypes.string,
    }),
    description: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    isDisabled: PropTypes.bool,
    isOptional: PropTypes.bool,
    isRepeatable: PropTypes.bool,
    label: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    itemLabel: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    type: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
    ),
    displayNullOption: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  parent: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  selectedIndex: PropTypes.number.isRequired,
};

export default PlanDataSelectInput;
