/**
 * set initial state
 */
const initalState = {
  renderSidebar: false,
};
/**
 * Reducer for getting plans
 * @param {object} state The previous state value
 * @param {object} action The redux action
 * @return {string} The new state
 */
export default (state = initalState, action) => {
  switch (action.type) {
    case 'SIDEBAR':
      return { renderSidebar: !state.renderSidebar };
    default:
      return state;
  }
};
