import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import PlanBaseInput from './PlanBaseInput';

import '../styles/components/PlanSelectInput.scss';

/**
 * PlanSelectInput functional component
 * Renders a select input element
 * @param {*} props component props
 * @returns {JSX} component JSX
 */
export const PlanSelectInput = (props) => {
  const {
    inputProperties,
    parent,
    selectedValue,
    disabledOptions,
    onChange,
  } = props;
  const { t } = useTranslation('common');
  const { i18n } = useTranslation();
  const { language } = i18n;

  const { values, data } = inputProperties;
  const displayKey = inputProperties.dataSource ? inputProperties.dataSource.displayKey : undefined;
  const id = `${parent.join('-')}`;

  // Determine placeholder text to display before user selects item
  const placeholder = (inputProperties.placeholder ? inputProperties.placeholder[language] : t('select'));

  const isPriorityAlert = () => {
    const activityType = document.querySelector('select[id *= "activity type"]');
    if (activityType && selectedValue) {
      return activityType.value !== 'Other' && (selectedValue === 'Medium' || selectedValue === 'Low');
    }
    return true;
  };

  /**
   * Determines which options are already selected and disables them in the priority dropdown
   */
  const isOptionDisabled = (value) => {
    if (!Array.isArray(disabledOptions)) {
      return false;
    }
    return !!disabledOptions.find((option) => option.priorityLevel === value);
  };

  const inputElement = (
    <div className="plan-select-input">
      <select
        id={id}
        onChange={(e) => onChange(parent, e.target.value, inputProperties)}
        value={(!inputProperties.isLocationDropDown && selectedValue) || ''}
      >
        {inputProperties.displayNullOption
          ? (<option value="">{placeholder}</option>)
          : (<option value="" disabled hidden>{placeholder}</option>)}
        {
          values.length > 1
            ? values.map((value) => (
              <option
                key={`select-${value.label[language]}-${id}`}
                id={`select-${value.label[language]}-${id}`}
                value={value.label.en}
                disabled={isOptionDisabled(value.label[language])}
              >
                {value.label ? value.label[language] : Object.values(value)[0]}
              </option>
            )) : data.map((value) => (
              <option key={`select-${value[displayKey]}-${id}`} id={`select-${value[displayKey]}-${id}`} value={value[displayKey]}>
                {value[displayKey] ? value[displayKey] : Object.values(value)[0]}
              </option>
            ))
        }
      </select>
      <FontAwesomeIcon className="select-caret" icon={faCaretRight} />
      {
        inputProperties.dataKey === 'priorityLevel'
        && !isPriorityAlert()
        && <p id="priority-alert">{t('priorityAlert')}</p>
      }
    </div>
  );

  return (
    <PlanBaseInput
      parent={parent}
      inputProperties={inputProperties}
      inputElement={inputElement}
      onChange={onChange}
    />
  );
};

PlanSelectInput.defaultProps = {
  values: [],
  selectedValue: null,
  disabledOptions: [],
};

PlanSelectInput.propTypes = {
  inputProperties: PropTypes.shape({
    placeholder: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    allowUserDefined: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.shape({})),
    data: PropTypes.arrayOf(PropTypes.shape({})),
    dataKey: PropTypes.string,
    dataSource: PropTypes.shape({
      displayKey: PropTypes.string,
    }),
    description: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    isLocationDropDown: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isOptional: PropTypes.bool,
    isRepeatable: PropTypes.bool,
    label: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    itemLabel: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    type: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
    ),
    displayNullOption: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  parent: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({})]),
  ),
  selectedValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  disabledOptions: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
};

export default PlanSelectInput;
