import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import '../styles/components/NavbarItem.scss';

/**
 * Navbar item component
 * @param {object} item
 * @returns {JSX} JSX to render the component
 */

const NavbarItem = ({ item }) => {
  const keyDown = (e) => {
    if (e.keyCode === 13) {
      item.onClick();
    }
  };

  return (
    <>
      {item.onClick ? (
        <div
          className="nav-item"
          role="button"
          id={item.id}
          onKeyDown={(e) => keyDown(e)}
          onClick={() => item.onClick()}
          tabIndex="0"
        >
          {item.id}
        </div>
      ) : (
        <Link className="nav-item" to={item.url}>
          {item.id}
        </Link>
      )}
    </>
  );
};

NavbarItem.defaultProps = {
  item: null,
};

NavbarItem.propTypes = {
  item: propTypes.shape({
    url: propTypes.string,
    id: propTypes.string,
    label: propTypes.string,
    onClick: propTypes.func,
  }),
};

export default NavbarItem;
