import React from 'react';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';

import '../styles/components/Sidebar.scss';

/**
 * Sidebar component
 * @param {closeSidebar} function
 * @returns {JSX} JSX to render the component
 */
const Sidebar = ({ items, closeSidebar }) => {
  const keyDown = (e, onClick) => {
    if (e.keyCode === 13) {
      onClick();
    }
  };

  // function that renders horizontal line separating sections
  // only for item with URL and className = 'item-edit-final'
  // can also call without parameter to insert horzLine
  const renderHorzLine = (item = { className: 'title-edit' }) => (item.className.includes('title-edit') && !item.className.includes('no-border') ? (
    <div className="side-bar__horz-container">
      <hr className="side-bar__horz-line" />
    </div>
  ) : (
    <></>
  ));

  const renderOnClick = (item) => (item.onClick ? (
    <>
      { item.id === 'Red Book App' ? renderHorzLine() : renderHorzLine(item)}
      <div
        className={`side-bar__${item.className}`}
        role="button"
        id={item.id}
        onKeyDown={(e) => keyDown(e, item.onClick)}
        onClick={() => item.onClick()}
        tabIndex="0"
      >
        {item.label}
      </div>
    </>
  ) : (
    <>
      {renderHorzLine()}
      <Link
        className={`side-bar__${item.className}`}
        to={item.url}
      >
        {item.label}
      </Link>
    </>
  ));

  return (
    <div className="side-bar">
      <div className="side-bar__top-container">
        <div
          className={`side-bar__${items[0].className}`}
          onClick={() => { if (items[0].onClick) items[0].onClick(); }}
          role="button"
          tabIndex="0"
          onKeyDown={(e) => keyDown(e)}
        >
          {items[0].label}
        </div>
        <div
          className="side-bar__exit-button"
          id="exitButton"
          role="button"
          onKeyDown={(e) => keyDown(e)}
          onClick={() => closeSidebar()}
          tabIndex="0"
        >
          X
        </div>
      </div>
      {
        items.map((item, index) => (index !== 0
          ? <React.Fragment key={item.label}>{renderOnClick(item)}</React.Fragment>
          : <React.Fragment key={item.label}>{}</React.Fragment>))
        }
    </div>
  );
};

Sidebar.defaultProps = {
  closeSidebar: null,
};

Sidebar.propTypes = {
  closeSidebar: propTypes.func,
  items: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.string,
      url: propTypes.string,
      label: propTypes.string,
      className: propTypes.string,
      onClick: propTypes.func,
    }),
  ).isRequired,
};

export default Sidebar;
