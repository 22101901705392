import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import '../styles/components/UnauthorizedModal.scss';

/** UnauthorizedModal functional component */
const UnauthorizedModal = ({ isOpen, handleClose, text }) => {
  const customStyles = {
    overlay: {
      zIndex: '99',
      background: 'rgba(192,192,192,0.75)',
    },
    content: {
      margin: 'auto',
      height: '20%',
      width: '40%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflow: 'visible',
      zIndex: '99',
    },
  };

  const { t } = useTranslation('common');

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} style={customStyles} ariaHideApp={false}>
      <p className="modal-text">{t('unauthorizedText')}</p>
      <div className="modal-buttons">
        <button type="button" id="okButton" onClick={handleClose}>
          {t(text)}
        </button>
      </div>
    </Modal>
  );
};

UnauthorizedModal.defaultProps = {
  isOpen: false,
  handleClose: null,
  text: '',
};

UnauthorizedModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  text: PropTypes.string,
};

export default UnauthorizedModal;
