import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  AuthenticatedTemplate, UnauthenticatedTemplate, MsalProvider, useMsal,
} from '@azure/msal-react';
import { initializeMSAL } from './services/AuthService';
import configureStore from './store/configureStore';
import LoginPage from './components/pages/LoginPage';
import AppRouter from './routers/AppRouter';
import initializeI18n from './i18n';
import packageJson from '../package.json';
import 'normalize.css/normalize.css';
import './styles/styles.scss';

/**
 * App component
 * @returns JSX AppRouter wrapped in store
 */
const App = () => {
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const store = configureStore(account.idToken);

  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
};

/**
 * Initializes application
 * based on MSAL
 */
const initializeApp = async () => {
  initializeI18n();
  global.appVersion = packageJson.version;
  const msalInstance = await initializeMSAL();

  ReactDOM.render(
    <MsalProvider instance={msalInstance}>
      <AuthenticatedTemplate>
        <App />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
    </MsalProvider>,
    document.getElementById('app'),
  );
};

initializeApp();
