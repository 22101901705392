import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import '../styles/components/UploadStaffingModal.scss';

/** UploadStaffingModal functional component */
const UploadStaffingModal = ({
  isOpen, handleClose, parseStaffingInfoUpload,
}) => {
  const [add, setAdd] = useState(false);
  const [replace, setReplace] = useState(false);

  const customStyles = {
    overlay: {
      zIndex: '99',
    },
    content: {
      margin: 'auto',
      height: '170px',
      width: '443px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      zIndex: '99',
    },
  };

  const { t } = useTranslation('common');

  /**
   * function to set state variable and click file upload input
   * @param {*} type if add or replace was clicked
   */
  const triggerFileInput = (type) => {
    if (type === 'add') setAdd(true);
    else if (type === 'replace') setReplace(true);
    document.getElementById('staffing-file-upload').click();
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} style={customStyles} ariaHideApp={false}>
      <>
        <input
          id="staffing-file-upload"
          type="file"
          hidden
          accept=".xls, .xlsx"
          onChange={() => {
            parseStaffingInfoUpload(add, replace);
            setAdd(false);
            setReplace(false);
            handleClose();
          }}
        />
        <p className="modal-text">{t('staffingUploadModal')}</p>
        <div className="staffing-modal-buttons">
          <button type="button" id="cancelButton" onClick={handleClose}>
            {t('cancel')}
          </button>
          <button
            type="button"
            id="addButton"
            onClick={() => { triggerFileInput('add'); }}
          >
            {t('add')}
          </button>
          <button
            type="button"
            id="replaceButton"
            onClick={() => { triggerFileInput('replace'); }}
          >
            {t('replace')}
          </button>
        </div>
      </>
    </Modal>
  );
};

UploadStaffingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  parseStaffingInfoUpload: PropTypes.func.isRequired,
};

export default UploadStaffingModal;
