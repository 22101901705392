import React from 'react';
import PropTypes from 'prop-types';
import PlanBaseInput from './PlanBaseInput';

import '../styles/components/PlanRadioInput.scss';

/**
 * PlanRadioInput functional component
 * Renders a radio input element
 * @param {*} props component props
 * @returns {JSX} component JSX
 */
export const PlanRadioInput = (props) => {
  const {
    inputProperties,
    parent,
    checked,
    label,
    onChange,
  } = props;

  const id = `${parent.join('-')}`;

  const inputElement = (
    <div className="plan-radio-input">
      <label htmlFor={`radio-${id}`} id={`radio-label-${id}`}>
        <input
          id={`radio-${id}`}
          className="radio-input"
          type="radio"
          name={id}
          onClick={() => onChange(parent, checked, inputProperties.isRepeatable)}
          checked={checked}
          readOnly
        />
        <span className="radio-label">{label}</span>
      </label>
    </div>
  );

  return (
    <PlanBaseInput
      uniqueKey="uniqueKey-"
      parent={parent}
      inputProperties={inputProperties}
      inputElement={inputElement}
      onChange={onChange}
      displayDecorativeSpacing={!inputProperties.alwaysDisplayDesc}
    />
  );
};

PlanRadioInput.propTypes = {
  inputProperties: PropTypes.shape({
    allowUserDefined: PropTypes.bool,
    alwaysDisplayDesc: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.shape({})),
    dataKey: PropTypes.string,
    dataSource: PropTypes.shape({
      displayKey: PropTypes.string,
    }),
    description: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    isDisabled: PropTypes.bool,
    isOptional: PropTypes.bool,
    isRepeatable: PropTypes.bool,
    label: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    type: PropTypes.string,
    values: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
    ),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  parent: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

export default PlanRadioInput;
