/**
 * Action creator for updating the client information in the redux store
 * @param {object} planTables An object containing all staffing tables of the plan
 * @returns {object} An object containing type' and 'payload' used to add
 * the contents to the redux store
 */
export default (planTables) => ({
  type: 'UPDATE_PLAN_TABLES',
  payload: planTables,
});
