import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import '../styles/components/CreatePlanModal.scss';

/** IncorrectFileModal functional component */
const IncorrectFileModal = ({
  isOpen, handleClose,
}) => {
  const customStyles = {
    overlay: {
      zIndex: '99',
    },
    content: {
      margin: 'auto',
      height: '200px',
      width: '443px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      zIndex: '99',
    },
  };

  const { t } = useTranslation('common');

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} style={customStyles} ariaHideApp={false}>

      <>
        <p className="confirm-text">{t('pleaseUploadFile')}</p>
        <div className="modal-buttons">
          <button type="button" id="cancelButton" onClick={handleClose}>
            {t('ok')}
          </button>
        </div>
      </>

    </Modal>
  );
};

IncorrectFileModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default IncorrectFileModal;
