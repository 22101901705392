import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDrag, useDrop } from 'react-dnd';
import editIcon from '../assets/images/Edit_Icon.png';
import deleteIcon from '../assets/images/Delete_Icon.png';
import dotsIcon from '../assets/images/Dots_Icon.png';
import cloneIcon from '../assets/images/Clone_Icon.png';

/**
 * DraggableRow functional component
 * */
const DraggableRow = (props) => {
  const {
    input,
    index,
    itemEditId,
    val,
    valueKey,
    moveRow,
    editItem,
    removeItem,
    saveEditedItem,
    cloneItem,
  } = props;

  const { i18n } = useTranslation();
  const { t } = useTranslation('common');
  const { language } = i18n;

  const DND_ITEM_TYPE = 'row';

  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveRow(dragIndex, hoverIndex);

      // Despite lint error, improves cleaness of drag
      /* eslint-disable-next-line no-param-reassign */
      item.index = hoverIndex;
    },
  });

  const [, drag, preview] = useDrag({
    type: DND_ITEM_TYPE,
    item: { type: DND_ITEM_TYPE, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(dropRef));
  drag(dragRef);

  /**
 * Renders the edit and remove buttons for each of the DraggableRow components
 * @param {*} index The position of the row in the table
 */
  const renderButtons = (order) => (
    itemEditId === `${input.dataKey}-table-row-${order + 1}` ? (
      <button
        type="button"
        id={`save-${input.dataKey}-${order + 1}`}
        className="save-value-button"
        onClick={() => saveEditedItem(valueKey, input, order)}
      >
        {t('save')}
      </button>
    ) : (
      <>
        {
        input.dataKey !== 'location' && (
        <button
          type="button"
          id={`edit-${input.dataKey}-${order + 1}`}
          className="edit-value-button"
          onClick={() => editItem(valueKey, input, order)}
          title="Edit"
        >
          <img src={editIcon} alt="edit" />
        </button>
        )
      }
        {input.dataKey === 'BAU' && (
          <button
            type="button"
            id={`clone-${input.dataKey}-${order + 1}`}
            className="clone-value-button"
            onClick={() => cloneItem(valueKey, order)}
            title="Clone"
          >
            <img src={cloneIcon} alt="clone" />
          </button>
        )}
        <button
          type="button"
          id={`remove-${input.dataKey}-${order + 1}`}
          className="remove-value-button"
          onClick={() => removeItem(valueKey, order)}
          title="Delete"
        >
          <img src={deleteIcon} alt="remove" />
        </button>
      </>
    ));

  /**
   * Returns the string to display in the table cell
   * @param {*} value Input value
   * @param {*} inputKey Input key
   * @returns
   * Depending on input type, returns either a pure input value,
   * or a concatenated string of labels corresponding to each select value
   */
  const getDisplayValue = (value, inputKey) => {
    const childInput = input.children.find((child) => child.dataKey === inputKey);
    if (childInput && childInput.type === 'selectMultiple') {
      // Backwards compatibility for plans that use a multiselect
      // on a field that was previously a single value
      return Array.isArray(value) ? value.map(
        (v) => childInput.values.find((item) => item.value === v).label[language] || v,
      ).join('; ') : value;
    }
    return t(`${value}`);
  };

  return (
    <tr
      className="table-row"
      id={`${input.dataKey}-table-row-${index + 1}`}
      key={`${input.dataKey}-table-row-${index + 1}`}
      ref={dropRef}
    >
      { typeof val === 'object' && (
        itemEditId === `${input.dataKey}-table-row-${index + 1}` ? (
          <td colSpan={input.children.length} id={`${input.dataKey}-editing`}>
            {t('editing')}
          </td>
        ) : (
          Object.entries(val).map(([inputKey, repeatable], dataIndex) => (
            dataIndex < input.children.length && (
              <td
                key={`${input.dataKey}-table-data-${dataIndex + 1}`}
                id={`${input.dataKey}-table-row-${index + 1}-data-${dataIndex + 1}`}
                ref={dataIndex === 0 ? dragRef : null}
                style={{ cursor: 'grab' }}
              >
                {dataIndex === 0 && (
                  <img
                    src={dotsIcon}
                    alt="move"
                    style={{ display: 'inline', float: 'left', marginTop: '5%' }}
                  />
                )}
                {getDisplayValue(repeatable, inputKey) ?? ''}
              </td>
            )
          ))
        ))}
      {
        typeof val === 'string'
        && (
        <td
          key={`${input.dataKey}-table-data-${index + 1}`}
          id={`${input.dataKey}-table-row-${index + 1}`}
          ref={dragRef}
          style={{ cursor: 'grab' }}
        >
          <img src={dotsIcon} alt="move" style={{ display: 'inline', float: 'left' }} />
          <p style={{ display: 'inline', marginLeft: '5%' }}>{val}</p>
        </td>
        )
      }
      <td>
        {
          renderButtons(index)
        }
      </td>
    </tr>
  );
};

DraggableRow.defaultProps = {
  index: 0,
  valueKey: [],
  itemEditId: '',
  moveRow: () => { },
  editItem: () => {},
  saveEditedItem: () => {},
  cloneItem: () => {},
};

DraggableRow.propTypes = {
  input: PropTypes.shape({
    dataKey: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({
      dataKey: PropTypes.string,
      isRequired: PropTypes.bool,
    })),
  }).isRequired,
  index: PropTypes.number,
  val: PropTypes.oneOfType([PropTypes.shape({
    address: PropTypes.string,
    locationName: PropTypes.string,
    notes: PropTypes.string,
    phoneNumber: PropTypes.string,
  }), PropTypes.string]).isRequired,
  section: PropTypes.shape({
    label: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    content: PropTypes.arrayOf(PropTypes.shape({
      dataKey: PropTypes.string,
    })),
    dataKey: PropTypes.string,
  }).isRequired,
  valueKey: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  itemEditId: PropTypes.string,
  moveRow: PropTypes.func,
  editItem: PropTypes.func,
  removeItem: PropTypes.func.isRequired,
  saveEditedItem: PropTypes.func,
  cloneItem: PropTypes.func,
};

export default DraggableRow;
