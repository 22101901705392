import React from 'react';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Logo } from 'kdc-component-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import NavbarItem from './NavbarItem';
import '../styles/components/Navbar.scss';

/**
 * Navbar component
 * @param {object} props
 * @returns {JSX} JSX to render the component
 */

const Navbar = ({
  items, logo, onMenuClick,
}) => {
  const urlHome = '/';
  const { i18n } = useTranslation;
  const additionalStyles = {
    cursor: 'pointer',
    height: 'auto',
    width: '120%',
  };

  const keyDown = (e) => {
    if (e.keyCode === 13) {
      onMenuClick();
    }
  };

  return (
    <div className="nav-bar">
      <div className="nav-bar__title-bar">
        <FontAwesomeIcon
          icon={faBars}
          className="nav-bar__menu-burger"
          aria-label="Burger Menu"
          onClick={() => onMenuClick()}
          onKeyDown={(e) => keyDown(e)}
        />
        <Link to={urlHome}>
          <Logo
            type="CoopBannerWhite"
            i18n={i18n}
            namespace="common"
            additionalStyles={additionalStyles}
          />
        </Link>
        <div className="nav-bar__navbar-item">
          {items.map((item) => (
            <NavbarItem key={item.id} item={item} />
          ))}
        </div>
        <a className="nav-bar__title-logo" target="_blank" href="https://source-cooperators.ca/home/work/departments/erm/programs/business-continuity" rel="noreferrer">
          <img
            className="nav-bar__title-logo-img"
            src={logo}
            alt="Business Continuity Plan Logo"
          />
        </a>
      </div>
    </div>
  );
};

Navbar.defaultProps = {
  items: null,
  logo: '',
  onMenuClick: null,
};

Navbar.propTypes = {
  items: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.string,
      url: propTypes.string,
      label: propTypes.string,
    }),
  ),
  // found this on stack overflow for proptypes of a image
  logo: propTypes.oneOfType([
    propTypes.shape({
      uri: propTypes.string,
      headers: propTypes.objectOf(propTypes.string),
    }),
    propTypes.number,
    propTypes.string,
    propTypes.arrayOf(
      propTypes.shape({
        uri: propTypes.string,
        width: propTypes.number,
        height: propTypes.number,
        headers: propTypes.objectOf(propTypes.string),
      }),
    ),
  ]),
  onMenuClick: propTypes.func,
};

export default Navbar;
