import Role from './Role';

/** Users with these roles should have access to BCP App */
const AccessEnum = [
  Role.AssociateVicePresident,
  Role.BusinessContinuityCoordinator,
  Role.BusinessTeam,
  Role.InfrastructureTeam,
  Role.LogisticsTeam,
  Role.Executive,
  Role.IncidentManagementTeamSupport,
  Role.VicePresident,
  Role.OfficeLead,
  Role.CGLBusinessContinuityCoordinator,
];

export default AccessEnum;
