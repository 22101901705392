import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PlanBaseInput from './PlanBaseInput';

import '../styles/components/PlanSelectMultipleInput.scss';

/**
 * PlanSelectInput functional component
 * Renders a select input element
 * @param {*} props component props
 * @returns {JSX} component JSX
 */
export const PlanSelectMultipleInput = (props) => {
  const {
    inputProperties,
    parent,
    selectedValue,
    onChange,
  } = props;
  const { i18n } = useTranslation();
  const { t } = useTranslation('common');
  const { language } = i18n;
  const { values } = inputProperties;
  const id = `${parent.join('-')}`;

  const getSelectedOptions = (options) => {
    const selectedOptions = [];
    for (let i = 0; i < options.length; i += 1) {
      if (options[i].selected) selectedOptions.push(options[i].value);
    }
    return selectedOptions;
  };

  const inputElement = (
    <div className="plan-select-multiple-input">
      <select
        id={id}
        onChange={(e) => onChange(parent, getSelectedOptions(e.target.options), inputProperties)}
        value={Array.isArray(selectedValue) ? selectedValue : []}
        multiple
      >
        {
          values.map((value) => (
            <option key={`select-${value.value}-${id}`} value={value.value}>
              {value.label ? value.label[language] : Object.values(value)[0]}
            </option>
          ))
        }
      </select>
      <p className="select-multiple-footnote">{t('selectMultipleOptions')}</p>
    </div>
  );

  return (
    <PlanBaseInput
      parent={parent}
      inputProperties={inputProperties}
      inputElement={inputElement}
      onChange={onChange}
    />
  );
};

PlanSelectMultipleInput.defaultProps = {
  values: [],
  selectedValue: null,
};

PlanSelectMultipleInput.propTypes = {
  inputProperties: PropTypes.shape({
    allowUserDefined: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.shape({})),
    dataKey: PropTypes.string,
    dataSource: PropTypes.shape({
      displayKey: PropTypes.string,
    }),
    description: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    isDisabled: PropTypes.bool,
    isOptional: PropTypes.bool,
    isRepeatable: PropTypes.bool,
    label: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    itemLabel: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    type: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
    ),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  parent: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({})]),
  ),
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default PlanSelectMultipleInput;
