import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import {
  enKdcComponentTranslations,
  frKdcComponentTranslations,
} from 'kdc-component-library';
import enCommon from './assets/translations/en/common.json';
import frCommon from './assets/translations/fr/common.json';

/**
 * Initialization of the i18next module
 * @returns {void}
 */
export default () => {
  i18next.use(initReactI18next).init({
    interpolation: { escapeValue: false },
    lng: 'en',
    fallbackLng: 'en',
    ns: ['common'],
    defaultNS: 'common',
    fallbackNS: 'common',
    resources: {
      en: {
        common: {
          ...enKdcComponentTranslations,
          ...enKdcComponentTranslations.footer,
          ...enCommon,
          ...enKdcComponentTranslations.login,
        },
      },
      fr: {
        common: {
          ...frKdcComponentTranslations,
          ...frKdcComponentTranslations.footer,
          ...frCommon,
          ...frKdcComponentTranslations.login,
        },
      },
    },
  });
  i18next.changeLanguage(navigator && navigator.language && navigator.language.substring(0, 2).toLowerCase() === 'fr' ? 'fr' : 'en');
};
