import { PublicClientApplication } from '@azure/msal-browser';
import MsalConfig from '../config/MsalConfig';

const msalInstance = new PublicClientApplication(MsalConfig);
/**
 * Configures MSAL authentication
 * @return {AuthenticationContext} MSAL authentication object
 */
export const initializeMSAL = async () => {
  await msalInstance.initialize();

  msalInstance.handleRedirectPromise().then((response) => {
    if (response) {
      msalInstance.setActiveAccount(response.account);
      return;
    }

    const accounts = msalInstance.getAllAccounts();

    if (!msalInstance.getActiveAccount() && accounts && accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }
  });

  return msalInstance;
};

export default msalInstance;
