import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import '../styles/components/EditPlanNameModal.scss';
import { useTranslation } from 'react-i18next';

/** EditPlanNameModal functional component */
const EditPlanNameModal = ({
  editPlanName, oldName, isOpen, handleClose,
}) => {
  const customStyles = {
    overlay: {
      zIndex: '99',
    },
    content: {
      margin: 'auto',
      height: '200px',
      width: '443px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      zIndex: '99',
    },
  };

  const { t } = useTranslation('common');

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} style={customStyles} ariaHideApp={false}>
      <p className="modal-text">{t('enterNewPlanName')}</p>
      <div className="edit-plan-name-input-div input-div">
        <input
          placeholder={t('enterPlanName')}
          defaultValue={oldName}
          id="edit-plan-name-input"
          type="text"
          className="modal-input"
        />
        <p className="plan-text">{t('plan')}</p>
      </div>
      <div className="modal-buttons">
        <button type="button" id="okButton" onClick={editPlanName}>
          {t('ok')}
        </button>
        <button type="button" id="cancelButton" onClick={handleClose}>
          {t('cancel')}
        </button>
      </div>
    </Modal>
  );
};

EditPlanNameModal.defaultProps = {
  editPlanName: null,
  oldName: '',
  isOpen: false,
  handleClose: null,

};

EditPlanNameModal.propTypes = {
  editPlanName: PropTypes.func,
  oldName: PropTypes.string,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default EditPlanNameModal;
