import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import SaveButton from './SaveButton';

import '../styles/components/MessageModal.scss';

const MessageModal = ({
  resetSaveButtons,
  currentBusinessActivity,
  checkForMissingFields,
  section,
  isOpen,
  onConfirm,
  onCancel,
  onDiscard,
  message,
  handleClose,
  unmount,
  planId,
  setOpenWarningModal,
  action,
}) => {
  const customStyles = {
    overlay: {
      zIndex: '99',
    },
    content: {
      top: '25%',
      height: '286px',
      width: '443px',
      display: 'flex',
      margin: 'auto',
      alignItems: 'stretch',
      flexDirection: 'column',
      justifyContent: 'space-between',
      zIndex: '99',
    },
  };

  const { t } = useTranslation('common');

  const renderButtons = () => {
    // render Save, Discard and Cancel button
    if (unmount) {
      return (
        <>
          <SaveButton
            resetSaveButtons={resetSaveButtons}
            currentBusinessActivity={currentBusinessActivity}
            checkForMissingFields={checkForMissingFields}
            section={section}
            reduxSelectors={['values', 'urgentStaffTable', 'highStaffTable', 'medStaffTable', 'lowStaffTable']}
            planId={planId}
            onCancel={onCancel}
            onConfirm={onConfirm}
            setOpenWarningModal={setOpenWarningModal}
            action={action}
          />
          <button
            type="button"
            id="discardButton"
            onClick={() => {
              onDiscard();
              onConfirm();
            }}
          >
            {t('discard')}
          </button>
          <button type="button" id="cancelButton" onClick={onCancel}>
            {t('cancel')}
          </button>
        </>
      );
    }
    // render Ok button
    return <button type="button">{t('ok')}</button>;
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} style={customStyles} ariaHideApp={false}>
      <p className="modal-text">{message}</p>
      <div className="modal-buttons">{renderButtons()}</div>
    </Modal>
  );
};

MessageModal.defaultProps = {
  currentBusinessActivity: 0,
  isOpen: false,
  onConfirm: null,
  onCancel: null,
  onDiscard: null,
  message: null,
  handleClose: null,
  unmount: null,
  planId: null,
  setOpenWarningModal: () => { },
  action: null,
};

MessageModal.propTypes = {
  resetSaveButtons: PropTypes.func.isRequired,
  currentBusinessActivity: PropTypes.number,
  checkForMissingFields: PropTypes.func.isRequired,
  section: PropTypes.shape({
    label: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    content: PropTypes.arrayOf(PropTypes.shape({
      dataKey: PropTypes.string,
      children: PropTypes.arrayOf(PropTypes.shape({
        isRequired: PropTypes.bool,
        dataKey: PropTypes.string,
      })),
    })),
    dataKey: PropTypes.string,
  }).isRequired,
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onDiscard: PropTypes.func,
  message: PropTypes.string,
  handleClose: PropTypes.func,
  unmount: PropTypes.bool,
  planId: PropTypes.string,
  setOpenWarningModal: PropTypes.func,
  action: PropTypes.string,
};

export default MessageModal;
