/** Plan Status enum */
const Status = {
  New: 'New',
  ChangesSaved: 'Changes Saved',
  PendingApproval: 'Pending Approval',
  Finalized: 'Finalized',
  Approved: 'Approved',
  Declined: 'Declined',
};

/** Plan Status translation key enum */
const StatusTranslation = {
  [Status.ChangesSaved]: 'changesSaved',
  [Status.PendingApproval]: 'pendingApproval',
  [Status.Finalized]: 'finalized',
  [Status.Approved]: 'approved',
  [Status.Declined]: 'declined',
};

export {
  Status,
  StatusTranslation,
};
