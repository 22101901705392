/** Plan Type enum */
const PlanType = {
  BCP: 'BCP Plan',
  BCC: 'BCC Plan',
  Logistics: 'Logistics Plan',
  None: 'No Plan',
};

/** Plan Type translation key enum */
const PlanTypeTranslation = {
  [PlanType.BCP]: 'bcpPlan',
  [PlanType.BCC]: 'bccPlan',
  [PlanType.Logistics]: 'logisticsPlan',
  [PlanType.None]: 'noPlan',
};

export {
  PlanType,
  PlanTypeTranslation,
};
