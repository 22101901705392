import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import '../styles/components/PlanInput.scss';
import HelpTooltip from './HelpTooltip';
import BusinessActivitesOptionsDropdown from './BusinessActivitiesOptionsDropdown';

/**
 * PlanInput functional component
 * Renders a user input along with its name and description
 * @param {boolean} props.displayDecorativeSpacing
 * If not displaying label text next to the input component,
 * the label span can still be rendered for spacing/styling purposes.
 * Set this to false to not render the label span
 * @returns {JSX} component JSX
 */
export const PlanBaseInput = (props) => {
  const {
    uniqueKey = '',
    displayLabel,
    inputProperties,
    inputElement,
    parent,
    displayDecorativeSpacing,
    activityName,
    activitiesLength,
    deleteBA,
    handleCloneBusinessActivityClick,
    handleNewBusinessActivityClick,
  } = props;
  const { i18n } = useTranslation();
  const { language } = i18n;
  const labelId = inputProperties.label ? inputProperties.label[language]
    : inputProperties.itemLabel[language];
  let className;
  if (inputProperties.type === 'object' || inputProperties.isHeader) {
    className = 'plan-input-label input-header';
  } else if (inputProperties.isSubquestion) {
    className = 'plan-input-label plan-subquestion';
  } else {
    className = 'plan-input-label';
  }
  let parentKey = '';
  if (uniqueKey === '') {
    parentKey = `-${parent[parent.length - 2]}`;
    if (parentKey.match('[0-9]$')) {
      parentKey = `-${parent[parent.length - 3]}`;
    }
  }
  return (
    <>
      <div className="plan-input-row" id={`${uniqueKey}input${parentKey}-${labelId}`}>
        {
          displayDecorativeSpacing && (
            <span
              className={className}
              id={inputProperties.type === 'object' ? `${inputProperties.dataKey}` : `label-${parent.join('-')}`}
            >
              {(displayLabel
                && inputProperties.label && inputProperties.label[language])}
              {displayLabel && inputProperties.help && (
                <HelpTooltip id={`help-${parent.join('-')}`} content={inputProperties.help[language]} />
              )}
              {
                inputProperties.isRequired
                && displayLabel
                && inputProperties.label
                && parent.length > 3
                && <p className="required-label">*</p>
              }
              {
                inputProperties.dataKey === 'businessActivities'
                && (
                <BusinessActivitesOptionsDropdown
                  activityName={activityName}
                  activitiesLength={activitiesLength}
                  deleteBA={deleteBA}
                  handleCloneBusinessActivityClick={handleCloneBusinessActivityClick}
                  handleNewBusinessActivityClick={handleNewBusinessActivityClick}
                />
                )
              }
            </span>
          )
        }
        {inputElement}
      </div>
    </>
  );
};

PlanBaseInput.defaultProps = {
  displayLabel: true,
  inputElement: null,
  uniqueKey: '',
  displayDecorativeSpacing: true,
  activityName: null,
  activitiesLength: null,
  deleteBA: () => { },
  handleCloneBusinessActivityClick: () => { },
  handleNewBusinessActivityClick: () => { },
};

PlanBaseInput.propTypes = {
  uniqueKey: PropTypes.string,
  inputProperties: PropTypes.shape({
    allowUserDefined: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.shape({})),
    dataKey: PropTypes.string,
    dataSource: PropTypes.shape({
      displayKey: PropTypes.string,
    }),
    description: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    isHeader: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isOptional: PropTypes.bool,
    isSubquestion: PropTypes.bool,
    isRepeatable: PropTypes.bool,
    isRequired: PropTypes.bool,
    label: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    itemLabel: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    help: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    type: PropTypes.string,
    values: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
    ),
  }).isRequired,
  displayLabel: PropTypes.bool,
  inputElement: PropTypes.element,
  parent: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  displayDecorativeSpacing: PropTypes.bool,
  activityName: PropTypes.string,
  activitiesLength: PropTypes.number,
  deleteBA: PropTypes.func,
  handleCloneBusinessActivityClick: PropTypes.func,
  handleNewBusinessActivityClick: PropTypes.func,
};

export default PlanBaseInput;
