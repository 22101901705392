import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Status } from '../enums/PlanStatus';

import '../styles/components/FinalizeModal.scss';

/** FinalizeModal functional component */
const FinalizeModal = ({
  isOpen,
  handleClose,
  onExit,
  planName,
  planStatus,
}) => {
  const customStyles = {
    overlay: {
      zIndex: '99',
      background: 'rgba(192,192,192,0.75)',
    },
    content: {
      margin: 'auto',
      height: '200px',
      width: '443px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflow: 'visible',
      zIndex: '99',
    },
  };

  const { t } = useTranslation('common');

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} style={customStyles} ariaHideApp={false}>
      <p className="modal-text">
        {
          planStatus === Status.PendingApproval
            ? t('alreadySubmitted')
            : t('finalizeText', { planName, nextRevisionDate: `${moment().add(6, 'months').format('MM DD, YYYY')}` })
        }
      </p>
      <div className="modal-buttons">
        {
          planStatus === Status.PendingApproval
            ? (
              <>
                <button
                  type="button"
                  id="exitButton"
                  onClick={() => {
                    handleClose();
                    onExit();
                  }}
                >
                  {t('exit')}
                </button>
                <button type="button" id="cancelButton" onClick={handleClose}>
                  {t('cancel')}
                </button>
              </>
            )
            : (
              <button
                type="button"
                id="finalizeOkButton"
                onClick={handleClose}
              >
                {t('ok')}
              </button>
            )
        }
      </div>
    </Modal>
  );
};

FinalizeModal.defaultProps = {
  isOpen: false,
  handleClose: null,
  onExit: null,
  planName: '',
  planStatus: '',
};

FinalizeModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  onExit: PropTypes.func,
  planName: PropTypes.string,
  planStatus: PropTypes.string,
};

export default FinalizeModal;
