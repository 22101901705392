import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/** Query hook to obtain search parameters from the URL */
const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export default useQuery;
