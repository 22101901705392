/**
 * Action creator for updating the client information in the redux store
 * @param {object} planValues An object containing all details about the plan
 * @returns {object} An object containing type' and 'payload' used to add
 * the contents to the redux store
 */
export default (planValues) => ({
  type: 'UPDATE_PLAN_VALUES',
  payload: planValues,
});
