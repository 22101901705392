import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { EmailValidator, EmailTypes } from '../services/EmailValidator';
import { Status } from '../enums/PlanStatus';

import '../styles/components/DeclinePlanModal.scss';

/** CreateDeclineModal functional component */
const CreateDeclineModal = ({
  isOpen, handleClose, template, plan, emails, mailAndStatus,
}) => {
  const [toEmail, setToEmail] = useState('');
  const [toCC, setToCC] = useState('');
  const [showToEmailError, setShowToEmailError] = useState(false);
  const [showCCEmailError, setShowCCEmailError] = useState(false);
  const [comments, setComments] = useState('');
  const { t } = useTranslation('common');

  useEffect(() => {
    setToEmail(emails?.join(', ') || '');
  }, [isOpen]);

  const customStyles = {
    overlay: {
      zIndex: '99',
      background: 'rgba(192,192,192,0.75)',
    },
    content: {
      margin: 'auto',
      height: '675px',
      width: '750px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflow: 'visible',
      zIndex: '99',
    },
  };
  /**
   * Verifies emails from user input are valid before sending
   */
  const validateEmails = () => {
    const [formattedTo, validTo] = EmailValidator(toEmail, EmailTypes.To);
    const [formattedCC, validCC] = EmailValidator(toCC, EmailTypes.CC);
    setShowToEmailError(!validTo);
    setShowCCEmailError(!validCC);
    if (validTo && validCC) {
      mailAndStatus(formattedTo, formattedCC, comments, Status.Declined);
    }
  };

  /** Clears any validation errors and calls prop close function */
  const onModalClose = () => {
    setShowToEmailError(false);
    setShowCCEmailError(false);
    handleClose();
  };

  const parseEmailTemplate = (emailTemplate) => emailTemplate
    .replace('{{planName}}', plan.name)
    .replace('{{viewPlanLink}}', `${window.location.protocol}//${window.location.host}/view-plan/?planId=${plan._id}`);

  return (
    <Modal className="modal" isOpen={isOpen} handleClose={handleClose} style={customStyles} ariaHideApp={false}>
      <p className="decline-email-modal-text">{t('editDeclineEmail')}</p>
      <div className="input-div">
        <p className="decline-input-text">{t('to')}</p>
        <input
          id="send-to-email"
          type="text"
          className="to-email-input"
          value={toEmail}
          onChange={(e) => setToEmail(e.target.value)}
        />
        {
          showToEmailError && (
            <p className="decline-email-error">{t('emailError')}</p>
          )
        }
      </div>
      <div className="input-div">
        <p className="decline-input-text">{t('cc')}</p>
        <input
          id="send-to-cc"
          type="text"
          className="to-email-input"
          value={toCC}
          onChange={(e) => setToCC(e.target.value)}
        />
        {
          showCCEmailError && (
            <p className="decline-email-error">{t('emailError')}</p>
          )
        }
      </div>
      <div className="decline-email-container">
        {/* Email templates are DB constants; end users are not permitted to modify them */}
        {/* DOMPurify is included as an extra safeguard */}
        {/* eslint-disable-next-line react/no-danger */}
        <div className="decline-email-template" id="decline-email-template" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(parseEmailTemplate(template)) }} />
        <textarea
          className="decline-email-textarea"
          id="user-comments"
        />
      </div>
      <div className="decline-email-comments">
        <textarea
          className="decline-email-textarea-com"
          placeholder={t('addNotes')}
          onChange={(e) => setComments(e.target.value)}
        />
      </div>
      <div className="declineButtons">
        <button className="approveDeclineButton" type="button" id="declineButton" onClick={validateEmails}>
          {t('decline')}
        </button>
        <button className="cancelDeclineButton" type="button" id="cancelDeclineButton" onClick={onModalClose}>
          {t('cancel')}
        </button>
      </div>
    </Modal>
  );
};

CreateDeclineModal.defaultProps = {
  isOpen: false,
  handleClose: null,
  emails: [],
  mailAndStatus: () => {},
};

CreateDeclineModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  plan: PropTypes.shape({
    name: PropTypes.string,
    _id: PropTypes.string,
  }).isRequired,
  emails: PropTypes.arrayOf(PropTypes.string),
  template: PropTypes.string.isRequired,
  mailAndStatus: PropTypes.func,
};

export default CreateDeclineModal;
