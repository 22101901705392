import Role from './Role';

/** Users with these roles should have access to create edit and view features
 * (Disable buttons on landing page if not)
 * */
const createEditViewEnum = [
  Role.AssociateVicePresident,
  Role.BusinessContinuityCoordinator,
  Role.BusinessTeam,
  Role.VicePresident,
  Role.OfficeLead,
  Role.LogisticsTeam,
];

export default createEditViewEnum;
