import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import '../styles/components/ReportsHintModal.scss';

const ReportsHintModal = ({
  isOpen, handleClose, text, title,
}) => {
  const customStyles = {
    overlay: {
      zIndex: '99',
    },
    content: {
      position: 'relative',
      margin: 'auto',
      height: 'fit-content',
      width: '300px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      zIndex: '99',
    },
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} style={customStyles} ariaHideApp={false}>
      <>
        <button
          id="modal-close-button"
          className="modal-close-button"
          type="button"
          onClick={handleClose}
        >
          X
        </button>
        <div className="modal-text-div">
          <p className="modal-title">{title}</p>
          <p className="modal-desc">{text}</p>
        </div>
      </>

    </Modal>
  );
};

ReportsHintModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default ReportsHintModal;
