import React from 'react';
import DOMPurify from 'dompurify';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import '../styles/components/HelpTooltip.scss';

/**
 * HelpTooltip functional component
 * Displays a tooltip to the user when hovered over
 * @param {string|HTML} props Sets element id and content
 * @returns {JSX} component JSX
 */
const HelpTooltip = ({
  id,
  content,
}) => (
  <div
    className="help"
    onPointerEnter={() => {
      if (document.activeElement.type === 'select-one') document.activeElement.blur();
    }}
  >
    <FontAwesomeIcon icon={faQuestionCircle} data-tip data-for={id} tabIndex="-1" />
    <ReactTooltip
      id={id}
      place="right"
      type="light"
      effect="solid"
      border
    >
      {/* eslint-disable react/no-danger */}
      <div
        dangerouslySetInnerHTML={
          { __html: DOMPurify.sanitize(content) }
        }
        className={id}
      />
    </ReactTooltip>
  </div>
);

HelpTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default HelpTooltip;
