import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dialog } from 'kdc-component-library';
import { v4 as uuidv4 } from 'uuid';
import * as XLSX from 'xlsx';
import DOMPurify from 'dompurify';
import ReactTooltip from 'react-tooltip';
import PlanBaseInput from './PlanBaseInput';
import PlanTextInput from './PlanTextInput';
import PlanTextAreaInput from './PlanTextAreaInput';
import PlanSelectInput from './PlanSelectInput';
import PlanSelectMultipleInput from './PlanSelectMultipleInput';
import PlanRadioInput from './PlanRadioInput';
import PlanDataSelectInput from './PlanDataSelectInput';
import PlanSearchInput from './PlanSearchInput';
import updatePlanValuesAction from '../store/actions/updatePlanValuesAction';
import SectionBanner from './SectionBanner';
import StaffingTable from './StaffingTable';
import PlanTable from './PlanTable';
import '../styles/components/PlanSection.scss';
import IncorrectFileModal from './IncorrectFileModal';
import axios from '../services/Api';
import UploadStaffingModal from './UploadStaffingModal';
import HelpTooltip from './HelpTooltip';

/** PlanSection functional component */
export const PlanSection = (props) => {
  const {
    unsavedChanges,
    resetSaveButtons,
    currentBusinessActivity,
    removeErrorMessage,
    checkForMissingFields,
    section,
    values,
    tables,
    updatePlanValues,
    plan,
    deleteBA,
    handleCloneBusinessActivityClick,
    handleNewBusinessActivityClick,
    setNextDisabled,
  } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [openFileModal, setOpenFileModal] = useState(false);
  const { i18n } = useTranslation();
  const { t } = useTranslation('common');
  const { language } = i18n;
  const fileExt = ['.pdf', '.doc', '.docx', '.xls', '.xlsx'];
  const [itemEditId, setItemEditId] = useState('');
  const [priorityArrayState, setPriorityArrayState] = useState([]);
  const [openStaffingModal, setOpenStaffingModal] = useState(false);
  const [staffingTemplateLink, setStaffingTemplateLink] = useState('');
  const [locationTableItems, setLocationTableItems] = values.businessImpactAnalysis
    ? useState(values.businessImpactAnalysis.businessActivities[currentBusinessActivity].location)
    : useState([]);

  const planSearchInputRef = useRef();

  useEffect(() => {
    setLocationTableItems(values.businessImpactAnalysis
      ? values.businessImpactAnalysis.businessActivities[currentBusinessActivity].location
      : []);
  }, [currentBusinessActivity]);

  useEffect(() => {
    const fileInput = document.getElementById('file-upload');
    if (fileInput) {
      const initialLastIndex = values.recovery.procedures.length - 1;
      const fileNameField = document.getElementById(`recovery-procedures-${initialLastIndex}-fileName`);
      // checks if the file is supported and opens the modal
      if (!fileExt.some((ext) => fileNameField.value.includes(ext))) {
        fileNameField.value = '';
      } else {
        fileNameField.value = values.recovery.procedures[initialLastIndex].fileName;
      }
    }

    /**
   * Returns array with priority levels set in all business activities
   * @returns an array without duplicate priority levels to be used for staffing table component
   */
    const priorityArray = () => {
      const priorityLevelArray = [];
      const priorityLevels = {
        Urgent: false,
        High: false,
        Medium: false,
        Low: false,
      };
      if (values.businessImpactAnalysis) {
        values.businessImpactAnalysis.businessActivities.forEach((activity) => {
          activity.priorityLevel.forEach(((level) => {
            if (level.priorityLevel) {
              priorityLevels[level.priorityLevel] = true;
            }
          }));
        });
        Object.keys(priorityLevels).forEach((priority) => {
          if (priorityLevels[priority]) { priorityLevelArray.push(priority); }
        });
        setPriorityArrayState(priorityLevelArray);
      }
    };
    priorityArray();
  }, [section]);

  const onFileUpload = () => {
    try {
      const fileInput = document.getElementById('file-upload');
      const lastIndex = values.recovery.procedures.length - 1;
      const [file] = fileInput.files;
      values.recovery.procedures[lastIndex].file = file;

      if (!fileExt.some((ext) => file.name.includes(ext))) {
        values.recovery.procedures[lastIndex].fileName = undefined;
        values.recovery.procedures[lastIndex].file = undefined;
        document.getElementById(`recovery-procedures-${lastIndex}-fileName`).value = '';
        setOpenFileModal(true);
      } else {
        values.recovery.procedures[lastIndex].fileName = file.name;
        document.getElementById(`recovery-procedures-${lastIndex}-fileName`).value = file.name;
      }
    } catch (e) { /**/ }
  };

  /**
   * Retrieves a value from the plan given its position
   * @param {Array} inputKey A list of object properties pointing to the value
   * @returns {*} The value at the location of the inputKey
   */
  const getValue = (inputKey) => {
    let value = values;
    inputKey.forEach((key) => {
      if (value) {
        value = value[key];
      }
    });
    return value;
  };

  /**
   *
   * @param {*} priority priority level
   * @returns table data based on priority
   */
  const getTableVal = (priority) => {
    if (priority === 'Urgent') {
      return tables.urgentStaffTable;
    } if (priority === 'High') {
      return tables.highStaffTable;
    } if (priority === 'Medium') {
      return tables.medStaffTable;
    } if (priority === 'Low') {
      return tables.lowStaffTable;
    }
    return null;
  };

  /**
   * Updates a plan value
   * @param {*} inputKey  list of object properties pointing to the value to update
   * @param {*} newValue The new value to insert into the plan values
   */
  const setValue = (inputKey, newValue) => {
    const valueToChange = inputKey[inputKey.length - 1];
    const originalValues = { ...values };
    let valueLocation = originalValues;
    inputKey.slice(0, inputKey.length - 1).forEach((key) => {
      valueLocation = valueLocation[key];
    });
    valueLocation[valueToChange] = newValue;
    updatePlanValues(originalValues);
    const emptySection = !Object.values(valueLocation)
      .some((value) => value !== null && value !== '' && value.length > 0);
    // Third last item is section
    const sectionName = inputKey[inputKey.length - 3];
    if (emptySection && newValue.length === 0) {
      resetSaveButtons(sectionName);
    } else {
      unsavedChanges(false, sectionName);
    }
  };

  /**
   * Updates multiple plan values given an object from an input data source
   * @param {*} inputKey list of object properties pointing to the value to update
   * @param {*} valueObject Object containing new values to insert
   * @param {boolean} search Whether the data source was a search or other
   */
  const setDataSourceValue = (inputKey, valueObject, search = false) => {
    const originalValues = { ...values };
    let valueLocation = originalValues;
    inputKey.forEach((key) => {
      valueLocation = valueLocation[key];
    });
    Object.keys({ ...valueObject }).forEach((key) => {
      if (valueObject && valueObject[key]) {
        valueLocation[key] = valueObject[key];
      } else {
        valueLocation[key] = '';
      }
    });
    updatePlanValues(originalValues);
    const sectionName = !search ? inputKey[inputKey.length - 2] : null;
    if (!valueObject || valueObject.name === '') {
      resetSaveButtons(sectionName);
    } else {
      unsavedChanges(false, sectionName);
    }
  };

  /**
   * Updates an object input to be applicable or not applicable
   * @param {Array} inputKey A list of object properties pointing to the input
   * @param {boolean} isApplicable Whether or not the input is applicable
   * @param {boolean} isRepeatable Handles removing all values if input is repeatable
   */
  const setIsApplicable = (inputKey, isApplicable, isRepeatable = false) => {
    let valueToChange = inputKey[inputKey.length - 1];
    const originalValues = { ...values };
    let valueLocation = originalValues;
    inputKey.slice(0, inputKey.length - 1).forEach((key) => {
      valueLocation = valueLocation[key];
    });
    // If setting repeatable input to not applicable, remove any existing items
    if (isRepeatable && !isApplicable && !Number.isNaN(valueToChange)) {
      valueLocation.splice(1);
      valueToChange = 0;
    }
    Object.keys(valueLocation[valueToChange]).forEach((key) => {
      valueLocation[valueToChange][key] = isApplicable ? null : 'N/A';
    });
    updatePlanValues(originalValues);
  };

  /**
   * Verifies whether there are any errors with the input's values
   * @param {object} input Input object to validate against
   * @param {object} inputValue Object containing the currently entered values for the input
   * @returns {boolean} true if there are no errors
   */
  /*
   const validateInput = (input, inputValue) => {
    let isValid = false;
    if (input.type === 'object') {
      const hasInvalidProperty = Object.entries(inputValue).some(
        (inputProperty) => {
          const childInput = input.children.find(
            (child) => child.dataKey === inputProperty[0],
          );
          // Assume property is required unless explicitly marked as optional
          let propertyIsRequired = true;
          if (typeof childInput.isOptional === 'boolean') {
            propertyIsRequired = !childInput.isOptional;
          }
          //render MessageModal if there is an empty input
          if(!inputProperty[1] || inputProperty[1] === ""){
            //RENDER MODAL HERE
          }
          return propertyIsRequired && !inputProperty[1];
        },
      );
      isValid = !hasInvalidProperty;
    }
    return isValid;
  }; */

  /** Checks the provided fields for duplicate values */
  const checkForDuplicateField = (valuesToCheck, newItem, inputs) => {
    const valueKeysToCheck = [];
    inputs.forEach((input) => {
      if (input.noDuplicate) {
        valueKeysToCheck.push(input.dataKey);
      }
    });
    for (let i = 0; i < valuesToCheck.length - 1; i += 1) {
      for (let j = 0; j < valueKeysToCheck.length; j += 1) {
        if (valuesToCheck[i][valueKeysToCheck[j]] === newItem[valueKeysToCheck[j]]) return true;
      }
    }
    return false;
  };

  /**
   * Stores a value submitted for a repeatable input
   * @param {object} input Input object to use for validation
   * @param {Array} inputKey A list of object properties pointing to the value to save
   */
  const saveRepeatable = (input, inputKey, updateIndex = null, sectionName = null) => {
    const originalValues = { ...values };
    let id;
    let file;
    // edge case to assign id to procedure
    if (input.dataKey === 'procedures' && updateIndex === null) {
      const length = originalValues.recovery.procedures.length - 1;
      originalValues.recovery.procedures[length].id = uuidv4();
    } else if (input.dataKey === 'procedures' && updateIndex !== null) {
      const { procedures } = originalValues.recovery;
      const length = originalValues.recovery.procedures.length - 1;
      // edge case to remove id and file from procedure when editing
      if (procedures[length].id === procedures[updateIndex].id) {
        id = originalValues.recovery.procedures[length].id;
        file = originalValues.recovery.procedures[length].file;
        originalValues.recovery.procedures[length].id = null;
        originalValues.recovery.procedures[length].file = null;
      }
    }
    let valueLocation = originalValues;
    inputKey.slice(0, inputKey.length - 1).forEach((key) => {
      valueLocation = valueLocation[key];
    });
    const index = inputKey[inputKey.length - 1];
    // TODO: Look at more robust input validation
    // if (validateInput(input, valueLocation[index])) {
    const newItem = { ...valueLocation[index] };
    let hasMissingFields;
    let missingField;
    for (let i = 0; i < input.children.length; i += 1) {
      missingField = checkForMissingFields(
        newItem[input.children[i].dataKey],
        input.children[i],
        input,
      );
      if (missingField) hasMissingFields = true;
    }
    if (!hasMissingFields) {
      let hasDuplicateField = false;
      if (inputKey.length === 5 && inputKey[0] === 'businessImpactAnalysis') {
        const activities = originalValues.businessImpactAnalysis.businessActivities;
        let valuesToCheck = activities[currentBusinessActivity][inputKey[3]];
        if (updateIndex !== null) {
          valuesToCheck = valuesToCheck.filter((value, valueIndex) => (valueIndex !== updateIndex));
        }
        if (valuesToCheck.length > 1) {
          hasDuplicateField = checkForDuplicateField(valuesToCheck, newItem, input.children);
        }
      } else if (inputKey.length === 3 && inputKey[0] === 'recovery') {
        let valuesToCheck = originalValues.recovery[inputKey[1]];
        if (updateIndex !== null) {
          valuesToCheck = valuesToCheck.filter((value, valueIndex) => (valueIndex !== updateIndex));
        }
        if (valuesToCheck.length > 1) {
          hasDuplicateField = checkForDuplicateField(valuesToCheck, newItem, input.children);
        }
      }
      if (!hasDuplicateField) {
        if (updateIndex !== null) {
          Object.keys(valueLocation[updateIndex]).forEach((key) => {
            if (valueLocation[updateIndex][key] !== newItem[key]) {
              valueLocation[updateIndex][key] = newItem[key];
            }
          });
          valueLocation = valueLocation.filter((item, itemIndex) => itemIndex !== index);
        }
        Object.keys(newItem).forEach((key) => {
          newItem[key] = null;
        });
        valueLocation.push(newItem);
        // edge case to reassign id and file to procedure when editing
        if (id && input.dataKey === 'procedures' && updateIndex !== null) {
          originalValues.recovery.procedures[updateIndex].id = id;
          originalValues.recovery.procedures[updateIndex].file = file;
        }
        // Success so update values and reset button color
        updatePlanValues(originalValues);
        const sectionButton = document.querySelector(`[id = save-button-${sectionName}]`);
        if (sectionName && sectionButton && sectionButton.classList) {
          sectionButton.classList.remove('green');
        }
        // Set keyboard focus to the first child of the object input
        const firstInputId = inputKey.join('-');
        document.querySelector(`[id^=${firstInputId}`).focus();
      } else {
        setOpenDialog(true);
      }
      if (planSearchInputRef?.current?.switchOnEdit) {
        planSearchInputRef.current.switchOnEdit(false);
        planSearchInputRef.current.resetTextValue();
      }
    }
  };

  /**
   * Helper function for setting the value of a text input field in React
   * Found here: https://github.com/facebook/react/issues/10135#issuecomment-314441175
   * @param {*} element The HTML element to set the value of
   * @param {String} value The text value you want to set
   */
  function setNativeValue(element, value) {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
    const prototype = Object.getPrototypeOf(element);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

    if (valueSetter && valueSetter !== prototypeValueSetter) {
      prototypeValueSetter.call(element, value);
    } else {
      valueSetter.call(element, value);
    }
  }

  const locationSelectOnChange = (inputKey, newValue) => {
    const originalValues = { ...values };
    let valueLocation = originalValues;
    inputKey.slice(0, inputKey.length - 1).forEach((key) => {
      valueLocation = valueLocation[key];
    });
    if (valueLocation.location === null) valueLocation.location = [];
    if (valueLocation.location.indexOf(newValue) === -1) {
      valueLocation.location = valueLocation.location.concat(newValue);
      setLocationTableItems(valueLocation.location);
      updatePlanValues(originalValues);
      unsavedChanges();
    }
  };

  /**
   * Dictionary containing input elements for each input type
   */
  const inputTypes = {
    text: {
      getInputElement: function TextInput(inputData) {
        const { valueKey, inputProperties } = inputData;
        return (
          <PlanTextInput
            parent={valueKey}
            inputProperties={inputProperties}
            onChange={setValue}
            value={getValue(valueKey)}
          />
        );
      },
    },
    textarea: {
      getInputElement: function TextInput(inputData) {
        const { valueKey, inputProperties } = inputData;
        return (
          <PlanTextAreaInput
            parent={valueKey}
            inputProperties={inputProperties}
            onChange={setValue}
            value={getValue(valueKey)}
          />
        );
      },
    },
    select: {
      getInputElement: function SelectInput(inputData) {
        const { valueKey, inputProperties } = inputData;
        let onChangeFunction = setValue;
        if (inputProperties.isLocationDropDown) onChangeFunction = locationSelectOnChange;
        return (
          <PlanSelectInput
            parent={valueKey}
            inputProperties={inputProperties}
            onChange={onChangeFunction}
            selectedValue={getValue(valueKey)}
            disabledOptions={getValue(valueKey.slice(0, -2))}
          />
        );
      },
    },
    selectMultiple: {
      getInputElement: function SelectInput(inputData) {
        const { valueKey, inputProperties } = inputData;
        return (
          <PlanSelectMultipleInput
            parent={valueKey}
            inputProperties={inputProperties}
            onChange={setValue}
            selectedValue={getValue(valueKey)}
          />
        );
      },
    },
    dataSelect: {
      getInputElement: function DataSelectInput(inputData) {
        const { valueKey, inputProperties } = inputData;
        const { displayKey } = inputProperties.dataSource;
        const selectedIndex = inputProperties.data.findIndex(
          (item) => item[displayKey] && item[displayKey] === getValue([...valueKey, displayKey]),
        );
        return (
          <PlanDataSelectInput
            parent={valueKey}
            inputProperties={inputProperties}
            onChange={setDataSourceValue}
            selectedIndex={selectedIndex}
          />
        );
      },
    },
    search: {
      getInputElement: function SearchInput(inputData) {
        const { valueKey, inputProperties } = inputData;
        const { displayKey } = inputProperties.dataSource;
        const selectedIndex = inputProperties.data.findIndex(
          (item) => item[displayKey] === getValue([...valueKey, displayKey]),
        );

        return (
          <PlanSearchInput
            ref={planSearchInputRef}
            parent={valueKey}
            inputProperties={inputProperties}
            onChange={setDataSourceValue}
            saveRepeatable={saveRepeatable}
            selectedIndex={selectedIndex}
          />
        );
      },
    },
    radio: {
      getInputElement: function SearchInput(inputData) {
        const { valueKey, inputProperties } = inputData;
        return (
          <PlanRadioInput
            parent={valueKey}
            inputProperties={inputProperties}
            onChange={setIsApplicable}
            checked={Object.values(getValue(valueKey)).every((val) => val === 'N/A')}
            label={t('notApplicable')}
          />
        );
      },
    },
    object: {
      getInputElement: function BaseInput(inputData) {
        const { valueKey, inputProperties } = inputData;
        return (
          <>
            {
              !inputProperties.alwaysDisplayDesc
                && (inputProperties.isOptional || inputProperties.isOptionalDependent) ? (
                  <PlanRadioInput
                    parent={valueKey}
                    inputProperties={inputProperties}
                    onChange={setIsApplicable}
                    checked={Object.values(getValue(valueKey)).every((val) => val === 'N/A')}
                    label={t('notApplicable')}
                  />
                ) : (
                  <PlanBaseInput
                    uniqueKey="uniqueKey-"
                    parent={valueKey}
                    inputProperties={inputProperties}
                    onChange={setValue}
                    value={getValue(valueKey)}
                    activityName={`${plan.values.businessImpactAnalysis?.businessActivities[currentBusinessActivity]?.Activity}` || ''}
                    activitiesLength={
                      plan.values.businessImpactAnalysis?.businessActivities?.length || 0
                    }
                    deleteBA={deleteBA}
                    handleCloneBusinessActivityClick={handleCloneBusinessActivityClick}
                    handleNewBusinessActivityClick={handleNewBusinessActivityClick}
                  />
                )
            }
          </>
        );
      },
    },
  };

  /**
   * Retrieves inputs to display based on a user's answer to previous questions
   * @param {*} input Input containing references to dynamic inputs
   * @param {*} value The input's current selected value
   * @returns Dynamic input to display for the selected value if it exists, null otherwise
   */
  const getDynamicallyRenderedInput = (input, value) => {
    if (
      input.type === 'select'
      && input.values
      && input.values.length > 0
      && input.values.every((val) => val.input)
    ) {
      const selectedValue = input.values.find((val) => val.value === value);
      return selectedValue ? selectedValue.input : null;
    }
    return null;
  };

  /**
   * removes error messages if not applicable button is clicked
   * @param {*} input Input object to check
   * @returns {void}
   */
  const handleHideContent = (input) => {
    let id;
    for (let i = 0; i < input.children.length; i += 1) {
      if (input.children[i].label) id = `input-${input.dataKey}-${input.children[i].label.en}`;
      else if (input.children[i].itemLabel) id = `input-${input.dataKey}-${input.children[i].itemLabel.en}`;
      removeErrorMessage(id);
    }
  };

  /**
   * function to update staffing data on contacts page
   * @param {*} addOrReplace boolean to determine if values are being added or replaced
   */
  const parseStaffingInfoUpload = (add, replace) => {
    const expectedTemplateHeaders = [
      'Name *',
      'Role/Title *',
      'Work Model?  *',
      'Office Location *',
      'Home city, postal *',
      'Phone *',
      'Notes',
    ];
    const fileInput = document.getElementById('staffing-file-upload');
    const file = fileInput.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const readedData = XLSX.read(data, { type: 'binary' });
      const worksheetname = readedData.SheetNames[0];
      const worksheet = readedData.Sheets[worksheetname];
      const worksheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const staffingData = [];
      if (
        JSON.stringify(worksheetData[0].slice(0, 7)) === JSON.stringify(expectedTemplateHeaders)
      ) {
        for (let i = 1; i < worksheetData.length; i += 1) {
          if (
            worksheetData[i][0]
            && worksheetData[i][1]
            && worksheetData[i][2]
            && worksheetData[i][3]
            && worksheetData[i][4]
            && worksheetData[i][5]
          ) {
            staffingData.push({
              name: worksheetData[i][0],
              jobTitle: worksheetData[i][1],
              workModel: worksheetData[i][2],
              officeLocation: worksheetData[i][3],
              homeAndCode: worksheetData[i][4],
              phone: worksheetData[i][5],
              staffingNotes: worksheetData[i][6] || '',
            });
          }
        }
      }
      if (staffingData.length > 0) {
        // getting item in input fields
        const lastItem = values.contacts.staffing.splice(-1, 1);
        if (replace) {
          values.contacts.staffing = staffingData.concat(lastItem);
        } else if (add) {
          values.contacts.staffing = values.contacts.staffing.concat(staffingData).concat(lastItem);
        }
        updatePlanValues();
        unsavedChanges();
      }
    };
    reader.readAsBinaryString(file);
  };

  const downloadStaffingTemplate = async () => {
    const { data: buffer } = await axios.get('/staffing-template', { responseType: 'arraybuffer' });

    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const downloadUrl = window.URL.createObjectURL(blob);
    setStaffingTemplateLink(downloadUrl);
    document.getElementById('staffing-download-link').click();
  };

  /**
   * Renders the description depending on the format type
   * @param {String} descriptionText - Description text to render
   * @param {String | undefined} descriptionFormat - Format of description
   * @returns {JSX} Description JSX
   */
  const renderDescription = (descriptionText, descriptionFormat = undefined) => {
    switch (descriptionFormat) {
      case 'html':
        return (
          <>
            {/* eslint-disable-next-line react/no-danger */}
            <pre dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(descriptionText) }} />
          </>
        );
      case 'preformatted':
        return (<pre>{descriptionText}</pre>);
      default:
        return (descriptionText);
    }
  };

  /**
   * Renders an input, and recursively renders all its child inputs if it's an object type input
   * @param {*} input Input object to render
   * @param {Array} parent List of object properties pointing to the current input
   * @returns {JSX} input JSX
   */
  const renderInput = (input, parent) => {
    const valueKey = [...parent, input.dataKey];
    let valueLocation;
    if (input.isRepeatable || input.isOptionalDependent) {
      if (parent.length === 1 && parent[0] === 'businessImpactAnalysis') {
        valueKey.push(currentBusinessActivity);
      } else {
        valueLocation = getValue(valueKey);
        if (Array.isArray(valueLocation)) valueKey.push(valueLocation.length - 1);
      }
    }
    const inputData = {
      valueKey,
      inputProperties: input,
      onChange: setValue,
      value: getValue(valueKey),
    };
    const dynamicInput = getDynamicallyRenderedInput(input, inputData.value);
    const hideContent = input.type === 'object'
      && input.isOptional
      && getValue(valueKey)
      && Object.values(getValue(valueKey)).every((val) => val === 'N/A');

    if (hideContent) handleHideContent(input);

    return (
      <React.Fragment key={input.dataKey}>
        {
          // TODO: add StaffingTable into base-plan-template
          // if the section is BAU it will display staffing table
          input.dataKey === 'BAU' && (
            <>
              <div className="plan-input-row" id="staff-requirements-table">
                <span
                  className="plan-input-label input-header"
                  id="BAU"
                >
                  {t('staffingRequirements.title')}
                  <HelpTooltip id="help-staffingRequirements" content={t('staffingRequirements.help')} />
                </span>
              </div>
              <div className="plan-input-row">
                <span
                  className="plan-input-description"
                  id="description-BAU"
                >
                  {t('staffingRequirements.subtitle')}
                  <br />
                  <br />
                  <b>{t('staffingRequirements.staffing.title')}</b>
                  <li>{t('staffingRequirements.staffing.bulletOne')}</li>
                  <li>{t('staffingRequirements.staffing.bulletTwo')}</li>
                  <li>{t('staffingRequirements.staffing.bulletThree')}</li>
                  <br />
                  <b>{t('staffingRequirements.inOffice.title')}</b>
                  <li>{t('staffingRequirements.inOffice.bulletOne')}</li>
                </span>
              </div>

              {
                (priorityArrayState.length === 0) ? (
                  <strong>
                    <span className="plan-input-description" style={{ padding: '2rem' }}>{t('noSavedPriorityLevel')}</span>
                  </strong>
                ) : (
                  <>
                    {
                      priorityArrayState.map((priorityLevel) => (
                        // Checks if a valid staffing table can be found before trying to display
                        getTableVal(priorityLevel) && (
                          <StaffingTable
                            unsavedChanges={unsavedChanges}
                            priority={priorityLevel}
                            table={getTableVal(priorityLevel)}
                            key={`${priorityLevel}-input-table`}
                            setNextDisabled={setNextDisabled}
                          />
                        )
                      ))
                    }
                  </>
                )
              }
              <hr className="section-divider" />
            </>
          )
        }
        {
          input.isLocationDropDown
          && locationTableItems
          && Array.isArray(locationTableItems)
          && locationTableItems.length > 0
          && (
          <PlanTable
            input={input}
            updatePlanValues={updatePlanValues}
                // eslint-disable-next-line react/jsx-no-bind
            setNativeValue={setNativeValue}
            saveRepeatable={saveRepeatable}
            valueLocation={locationTableItems}
            valueKey={valueKey}
            section={section}
            values={values}
            itemEditId={itemEditId}
            setDataSourceValue={setDataSourceValue}
            setItemEditId={setItemEditId}
            unsavedChanges={unsavedChanges}
            switchPlanSearchInput={planSearchInputRef.current
              ? planSearchInputRef.current.switchOnEdit : null}
            setCustomTextPlanSearchInput={planSearchInputRef.current
              ? planSearchInputRef.current.setCustomText : null}
          />
          )
        }
        {input.isNewSection && <hr className="section-divider" />}
        {
          inputTypes[input.type]
          && inputTypes[input.type].getInputElement(inputData)
        }
        {
          (!hideContent || input.alwaysDisplayDesc) && input.description && (
            <div className="plan-input-row">
              <span
                className="plan-input-description"
                id={`description-${input.type === 'object' ? input.dataKey : valueKey.join('-')}`}
              >
                {renderDescription(input.description[language], input.descriptionFormat)}
              </span>
            </div>
          )
        }
        {
          input.alwaysDisplayDesc && inputTypes.radio.getInputElement(inputData)
        }
        {
          !hideContent && (
            <>
              {
                input.isRepeatable
                && !input.isSection
                && valueLocation.length > 1
                && (
                  <PlanTable
                    input={input}
                    updatePlanValues={updatePlanValues}
                    // eslint-disable-next-line react/jsx-no-bind
                    setNativeValue={setNativeValue}
                    saveRepeatable={saveRepeatable}
                    valueLocation={valueLocation}
                    valueKey={valueKey}
                    section={section}
                    values={values}
                    itemEditId={itemEditId}
                    setItemEditId={setItemEditId}
                    unsavedChanges={unsavedChanges}
                    setDataSourceValue={setDataSourceValue}
                    switchPlanSearchInput={planSearchInputRef.current
                      ? planSearchInputRef.current.switchOnEdit : null}
                    setCustomTextPlanSearchInput={planSearchInputRef.current
                      ? planSearchInputRef.current.setCustomText : null}
                  />
                )
              }
              {
                input.dataSource
                && input.isSearchable
                && inputTypes.search.getInputElement(inputData)
              }
              {/* Display all currently saved values for a repeatable input */}

              {
                input.isStaffingSection && (
                  <div className={i18n.language === 'en' ? 'staffing-buttons' : 'staffing-buttons-column'}>
                    <a
                      href={staffingTemplateLink}
                      hidden
                      download="StaffingTemplate.xlsx"
                      id="staffing-download-link"
                    >
                      {t('download')}
                    </a>

                    <div data-tip data-for="showHelp">
                      <button
                        type="submit"
                        className="repeat-input-button uploadDownload"
                        id="download-button"
                        onClick={downloadStaffingTemplate}
                      >
                        {t('downloadTemplate')}
                      </button>
                    </div>
                    <div data-tip data-for="showHelp">
                      <button
                        type="button"
                        className="repeat-input-button uploadDownload"
                        id="upload-staff-button"
                        onClick={() => { setOpenStaffingModal(true); }}
                      >
                        {t('uploadTemplate')}
                      </button>
                    </div>

                    <ReactTooltip className="custom" id="showHelp" place="bottom" type="light" effect="solid" border>

                      <div>{t('uploadDownloadDesc')}</div>
                    </ReactTooltip>

                  </div>
                )
              }
              {
                input.dataSource
                && !input.isSearchable
                && input.type !== 'select'
                && inputTypes.dataSelect.getInputElement(inputData)
              }
              {/* If an input's values are also inputs, dynamically render the selected input */}
              {
                dynamicInput
                && renderInput(dynamicInput, parent)
              }
              {/* If an input has child inputs, display them */}
              {
                input.children
                && input.children.length > 0
                && input.children.map((childInput) => renderInput(childInput, valueKey))
              }
              {/* If section need file upload button */}
              {
                input.uploadFile && (
                  <div id="upload-div" className="plan-input-row">
                    {/* Empty label for styling */}
                    <span className="plan-input-label" />
                    <input
                      id="file-upload"
                      type="file"
                      hidden
                      accept=".pdf, .doc, .docx, .xls, .xlsx"
                      onChange={onFileUpload}
                    />
                    <button
                      type="button"
                      className="repeat-input-button"
                      id="save-button-upload"
                      onClick={() => {
                        document.getElementById('file-upload').click();
                        // Second index has section name
                        unsavedChanges(true, valueKey[1]);
                      }}
                    >
                      {t('upload')}
                    </button>
                    <IncorrectFileModal
                      isOpen={openFileModal}
                      handleClose={() => {
                        setOpenFileModal(false);
                      }}
                    />
                  </div>
                )
              }
              {/* Button to locally save a completed value for a repeatable input */}
              {
                input.isRepeatable && !input.isSection && (
                  <div className="plan-input-row">
                    {/* Empty label for styling */}
                    <span className="plan-input-label" />
                    <button
                      type="button"
                      className="repeat-input-button"
                      id={`save-button-${input.dataKey}`}
                      disabled={itemEditId !== ''}
                      onClick={() => saveRepeatable(input, valueKey, null, input.dataKey)}
                    >
                      {(input.label && input.label[language] === 'Evacuation Team')
                        ? ('Save Evacuation Team Member')
                        : (t('saveItem', { item: input.itemLabel ? input.itemLabel[language] : input.label[language] }))}
                    </button>
                  </div>
                )
              }
            </>
          )
        }

      </React.Fragment>
    );
  };
  return (
    <>
      <SectionBanner title={section.label[language]} id={`section-${section.label[language]}`} />
      <div className="section-inputs">
        {
          section.content && section.content.map((input) => (
            <React.Fragment key={input.dataKey}>
              {renderInput(input, [section.dataKey])}
            </React.Fragment>
          ))
        }
      </div>
      <Dialog
        open={openDialog}
        onClose={() => { setOpenDialog(false); }}
        function={null}
        title="Error Duplicate Value"
        content="You cannot enter a duplicate value"
      />
      <UploadStaffingModal
        isOpen={openStaffingModal}
        handleClose={() => { setOpenStaffingModal(false); }}
        parseStaffingInfoUpload={parseStaffingInfoUpload}
      />
    </>
  );
};

PlanSection.defaultProps = {
  currentBusinessActivity: 0,
  checkForMissingFields: () => { },
  removeErrorMessage: () => { },
  deleteBA: () => { },
  handleCloneBusinessActivityClick: () => { },
  handleNewBusinessActivityClick: () => { },
  setNextDisabled: () => { },
};

PlanSection.propTypes = {
  unsavedChanges: PropTypes.func.isRequired,
  resetSaveButtons: PropTypes.func.isRequired,
  currentBusinessActivity: PropTypes.number,
  checkForMissingFields: PropTypes.func,
  removeErrorMessage: PropTypes.func,
  plan: PropTypes.shape({
    name: PropTypes.string,
    _id: PropTypes.string,
    urgentStaffTable: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.arrayOf(PropTypes.number),
    ),
    highStaffTable: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.arrayOf(PropTypes.number),
    ),
    medStaffTable: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.arrayOf(PropTypes.number),
    ),
    lowStaffTable: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.arrayOf(PropTypes.number),
    ),
    values: PropTypes.shape({
      businessImpactAnalysis: PropTypes.shape({
        businessActivities: PropTypes.arrayOf(
          PropTypes.shape({
            Activity: PropTypes.string,
          }),
        ),
      }),
    }),
  }).isRequired,
  section: PropTypes.shape({
    label: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    content: PropTypes.arrayOf(PropTypes.shape({
      dataKey: PropTypes.string,
    })),
    dataKey: PropTypes.string,
  }).isRequired,
  values: PropTypes.shape({
    businessImpactAnalysis: PropTypes.shape({
      businessActivities: PropTypes.arrayOf(PropTypes.shape()),
    }),
    recovery: PropTypes.shape({
      procedures: PropTypes.arrayOf(PropTypes.shape({
        fileName: PropTypes.string,
        file: PropTypes.shape({}),
      })),
    }),
    contacts: PropTypes.shape({
      staffing: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        jobTitle: PropTypes.string,
        wfh: PropTypes.string,
        officeLocation: PropTypes.string,
        homeAndCode: PropTypes.string,
        phone: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
      })),
    }),
  }).isRequired,
  tables: PropTypes.shape({
    urgentStaffTable: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    highStaffTable: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    medStaffTable: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    lowStaffTable: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  }).isRequired,
  updatePlanValues: PropTypes.func.isRequired,
  deleteBA: PropTypes.func,
  handleCloneBusinessActivityClick: PropTypes.func,
  handleNewBusinessActivityClick: PropTypes.func,
  setNextDisabled: PropTypes.func,
};

export default connect(
  null,
  { updatePlanValues: updatePlanValuesAction },
)(PlanSection);
