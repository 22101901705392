export default {
  auth: {
    authority: `https://login.microsoftonline.com/${window.AZURE_ISSUER_TENANT_ID}`,
    clientId: window.AZURE_CLIENT_ID,
    redirectUri: window.AZURE_REDIRECT_URL,
    navigateToLoginRequestUrl: true,
    endpoints: {
      api: window.AZURE_CLIENT_ID,
    },
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};
