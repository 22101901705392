import React from 'react';
import PropTypes from 'prop-types';
import '../styles/components/Tab.scss';

/** Tab component */
const Tab = ({ children, isActive }) => (
  <div className={isActive ? 'ca-content active-content' : 'ca-content'}>
    <div>
      { children }
    </div>
  </div>
);

Tab.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.array,
  ]).isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default Tab;
