import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import '../styles/components/pages/CriticalActivityReportPage.scss';
import Priorities from '../enums/Priorities';

/**
 * ReportLabel component
 * @returns a label pairing with a select or plain text
 */
const ReportLabel = ({
  extendedClass, label, content, isSelect, options, onChange, val,
}) => {
  const { t } = useTranslation('common');
  /**
   * Sets filter color based on priority
   * @param {string} label destructured option
   * @returns
   */
  const getColour = ({ value }) => {
    if (typeof value === 'string') {
      switch (value.toLowerCase()) {
        case Priorities.Urgent.toLowerCase():
          return '#B81414';
        case Priorities.High.toLowerCase():
          return '#B8A814';
        case Priorities.Medium.toLowerCase():
          return '#B8A814';
        case Priorities.Low.toLowerCase():
          return '#00A3E0';
        default:
          return 'rgb(68, 68, 68)';
      }
    }
    return 'rgb(68, 68, 68)';
  };
    // styles for the dropdown
  const dropDownStyles = {
    control: (styles) => ({
      ...styles,
      fontWeight: '650',
      marginTop: '1.2rem',
      marginBottom: '0.5rem',
      backgroundColor: 'rgb(230, 230, 230)',
      color: 'rgb(68, 68, 68)',
      border: 'solid 1px black',
      lineHeight: 'normal',
      height: '40px',
      minWidth: '304px',
      maxWidth: '400px',
      textAlign: 'center',
      textAlignLast: 'center',
      cursor: 'pointer',
      appearance: 'none',
      boxShadow: '0 !important',
      '&:hover': {
        border: 'solid 1px black !important',
      },
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      color: getColour(data),
    }),
  };

  return (
    <div className={extendedClass ? `select-or-label ${extendedClass}` : 'select-or-label'}>
      <p className="bold">{label}</p>
      { isSelect
        ? (
          <Select
            value={val}
            options={options}
            placeholder={content}
            styles={dropDownStyles}
            id={`${content}-dropdown`}
            onChange={(e) => (onChange(e))}
            isClearable
          />
        )
        : <p className="larger-font">{content || t('notApplicable')}</p>}
    </div>
  );
};

ReportLabel.defaultProps = {
  content: '',
  extendedClass: '',
  isSelect: false,
  options: [{}],
  onChange: () => {},
  val: {
    label: '',
    value: '',
  },
};

ReportLabel.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  extendedClass: PropTypes.string,
  isSelect: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
  val: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({}),
    ]),
  }),
};

export default ReportLabel;
