import axios from 'axios';
import { toast } from 'react-toastify';
import msalInstance from './AuthService';
import ApiConfig from '../config/ApiConfig';
import history from '../history';

/**
 *  Creates an axios instance with the baseURL set to the backend,
 *  and potentially more configuration.
 */
const instance = axios.create(ApiConfig);

/** ID of internal errors not caught by other handlers */
const toastInternalErrorId = 'internalError';

/** Ensures current authorization token is included with every request */
instance.interceptors.request.use(async (config) => {
  let authConfig = config;
  const account = msalInstance.getActiveAccount();
  if (account) {
    const token = account.idToken;
    authConfig = {
      ...config,
      headers: {
        ...config.headers,
        authorization: `Bearer ${token}`,
      },
    };
  }
  return authConfig;
});

/** 401 handler */
const errorHandler = async (error) => {
  if (error.message.includes('401')) {
    try {
      let retryConfig = error.config;
      const account = msalInstance.getActiveAccount();
      if (account) {
        const token = account.idToken;
        retryConfig = {
          ...retryConfig,
          headers: {
            ...retryConfig.headers,
            authorization: `Bearer ${token}`,
          },
        };
      }
      return await axios.request(retryConfig);
    } catch (err) {
      // If we fail twice just log user out
      if (err.message.includes('401')) {
        history.replace('/unauthorized');
      }

      // If it's a different type of error let the user know
      if (err.config.method === 'get' && err.message.includes('500')) {
        toast.error(`${err}`, {
          toastId: toastInternalErrorId,
        });
      }
    }
  }

  return Promise.reject(error);
};

instance.interceptors.response.use(
  (response) => response,
  (error) => errorHandler(error),
);

export default instance;
