/**
 * Priorities
 */
const Priorities = {
  Urgent: 'Urgent',
  High: 'High',
  Medium: 'Medium',
  Low: 'Low',
};

export default Priorities;
