import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dialog } from 'kdc-component-library';
import { useMsal } from '@azure/msal-react';
import useQuery from '../../hooks/QueryHook';
import axios from '../../services/Api';
import Sidebar from '../Sidebar';
import SectionBanner from '../SectionBanner';
import ReviewBusinessActivity from '../ReviewBusinessActivity';
import renderSidebarAction from '../../store/actions/renderSidebarAction';
import ReviewRecoveryContactsLocations from '../ReviewRecoveryContactsLocations';
import '../../styles/components/pages/ReviewPlanPage.scss';
import EditPlanNameModal from '../EditPlanNameModal';
import ConfirmDeleteModal from '../ConfirmPlanDeleteModal';
import FinalizeModal from '../FinalizeModal';
import RequestSubmitModal from '../RequestSubmitModal';
import ReviewStaffTable from '../ReviewStaffTable';
import { PlanType } from '../../enums/PlanType';
import Role from '../../enums/Role';
import SubmitPlanModal from '../SubmitPlanModal';
import { Status } from '../../enums/PlanStatus';
import validateId from '../../services/IdValidator';

/** ReviewPlanPage functional component */
export const ReviewPlanPage = ({
  renderSidebar,
  updateSidebarRender,
}) => {
  const ERRORS = {
    SubmitForApproval: 'SubmitForApproval',
    Finalized: 'Finalized',
    SendEmail: 'SendEmail',
  };
  const { t } = useTranslation('common');
  const [plan, setPlan] = useState(null);
  const planId = useQuery().get('planId');
  const [toEmail, setToEmail] = useState('');
  const [emails, setEmails] = useState({ avp: [] });
  const history = useHistory();
  const { i18n } = useTranslation();
  const { language } = i18n;
  const [emailTemplate, setEmailTemplate] = useState('');
  const [openSubmitApproval, setOpenSubmitApproval] = useState(false);
  const [showSubmittedBox, setShowSubmittedBox] = useState(false);
  const [openEditPlanName, setOpenEditPlanName] = useState(false);
  const [error, setError] = useState('');
  const [showDeletePlanModal, setShowDeletePlanModal] = useState(false);
  const [showFinalizeModal, setShowFinalizeModal] = useState(false);
  const [showRequestSubmitModal, setShowRequestSubmitModal] = useState(false);
  const [selectedContent, setSelectedContent] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [currentBusinessActivity, setCurrentBusinessActivity] = useState(0);
  const [labels, setLabels] = useState([]);
  const [priorityArrayState, setPriorityArrayState] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const { instance } = useMsal();

  let isMounted = true;

  // Send user back to home page if planId is not a valid ID
  if (!validateId(planId)) {
    return <Redirect to="/" />;
  }

  // helper function to set className of sidebar label
  const selectedContentHelper = (index, contentObj) => {
    let label;
    // for 'Business Activites Title'
    if (selectedContent === contentObj.dataKey) {
      label = 'item-edit-selected';
    } else {
      label = 'item-edit';
    }
    return label;
  };

  // function to loop through the plan and get the sidebar labels
  const getLabels = (tempPlan) => {
    const tempLabels = [];
    // getTitle
    tempLabels.push({
      id: tempPlan.name,
      onClick: () => undefined,
      label: tempPlan.name,
      className: 'title-edit',
    });
    tempPlan.layout.sections.forEach((section, index) => {
      if (section.dataKey !== 'businessAsUsualActivities') {
        tempLabels.push({
          id: section.label[i18n.language],
          label: section.label[i18n.language],
          path: `/review-plan/${planId}/${section.dataKey}`,
          /** On click display the section and scroll to the location */
          onClick: () => {
            history.push({
              pathname: '/review-plan',
              search: `?planId=${planId}&sectionKey=${section.dataKey}`,
            });
            /** Added timeout to wait for the document to change. The document always shows loaded,
           * so, timeout was the only way that worked for this.
           */
            setTimeout(() => {
              const anchor = document.getElementById(
                `${section.dataKey}-section-banner`,
              );
              if (anchor) {
                anchor.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'nearest',
                });
              }
            }, 10);
          },
          className:
          selectedSection === section.dataKey
            ? 'subtitle-edit-selected'
            : 'subtitle-edit',
        });
      }
      section.content.forEach((contentObj) => {
        if (contentObj.dataKey === 'businessActivities') {
          return; // removes the 'Business Activities subtitle'
        }
        tempLabels.push({
          id: contentObj.label[i18n.language],
          label: contentObj.label[i18n.language],
          onClick: () => {
            // if user doesn't click the content they're currently on call onClick
            if (selectedContent !== contentObj.dataKey) {
              /** On click display the content and scroll to the exact location of the content */
              history.push({
                pathname: '/review-plan',
                search: `?planId=${planId}&sectionKey=${section.dataKey}`,
                hash: `#${contentObj.dataKey}`,
              });
              /** Added timeout to wait for document to change. */
              setTimeout(() => {
                const anchor = document.getElementById(
                  `uniqueKey-section-${contentObj.label[language]}`,
                );
                if (anchor) {
                  anchor.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest',
                  });
                }
              }, 10);
            }
          },
          className: section.dataKey !== 'businessAsUsualActivities'
            ? selectedContentHelper(index, contentObj)
            : 'subtitle-edit',
        });
      });

      // adding businessActivites
      if (index === 0 && tempPlan.type === PlanType.BCP) {
        tempPlan.values.businessImpactAnalysis.businessActivities.forEach(
          (activity, businessActivityIndex) => {
            tempLabels.push({
              id: activity.Activity,
              onClick: () => {
                history.replace({
                  pathname: '/review-plan',
                  search: `?planId=${planId}&sectionKey=businessImpactAnalysis`,
                  hash: '#businessActivities',
                });
                setCurrentBusinessActivity(businessActivityIndex);
                /** Added timeout to wait for the document to change.
                   * The document always shows loaded,
                   * so, timeout was the only way that worked for this.
                  */
                setTimeout(() => {
                  const anchor = document.getElementsByClassName('subtitle')[businessActivityIndex];
                  if (anchor) {
                    anchor.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                      inline: 'nearest',
                    });
                  }
                }, 10);
              },
              label:
                  activity.Activity === null
                    ? `${businessActivityIndex + 1}. New...`
                    : `${businessActivityIndex + 1}. ${activity.Activity}`,
              className:
                  currentBusinessActivity === businessActivityIndex
                    && selectedSection === section.dataKey
                    ? 'activity-edit-selected'
                    : 'activity-edit',
            });
          },
        );
      }

      // adding staffing level requirements label
      if (index === 1 && tempPlan.type === PlanType.BCP) {
        tempLabels.push({
          id: 'Staffing Level Requirements',
          onClick: () => {
            history.replace({
              pathname: '/review-plan',
              search: `?planId=${planId}&sectionKey=businessAsUsualActivities`,
            });
            document.getElementById('staff-requirements-table').scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'nearest',
            });
          },
          label: t('staffingRequirements.title'),
          className: 'subtitle-edit no-border',
        });
      }
    });

    tempLabels.push(
      {
        id: 'Edit',
        label: t('editMyPlan'),
        onClick: () => {
          history.push({
            pathname: '/edit-plan',
            search: `?planId=${planId}`,
          });
        },
        className: 'title-edit',
      },
      {
        id: 'Home',
        label: t('home'),
        url: '/',
        className: 'top-item-edit',
      },
      {
        id: 'Logout',
        label: t('logout'),
        onClick: () => instance.logoutRedirect({
          postLogoutRedirectUri: window.AZURE_REDIRECT_URL,
        }),
        className: 'top-item-edit',
      },
    );
    return tempLabels;
  };

  /**
   * Returns array with priority levels set in all business activities
   * @returns an array without duplicate priority levels to be used for staffing table component
   */
  const priorityArray = (currentPlan) => {
    if (!currentPlan.values.businessImpactAnalysis) return [];
    const priorityLevelArray = [];
    const priorityLevels = {
      Urgent: false,
      High: false,
      Medium: false,
      Low: false,
    };
    currentPlan.values.businessImpactAnalysis.businessActivities.forEach((activity) => {
      activity.priorityLevel.forEach(((level) => {
        if (level.priorityLevel) {
          priorityLevels[level.priorityLevel] = true;
        }
      }));
    });
    Object.keys(priorityLevels).forEach((priority) => {
      if (priorityLevels[priority]) { priorityLevelArray.push(priority); }
    });
    return priorityLevelArray;
  };

  const onFinalize = async () => {
    setShowFinalizeModal(true);
    // only update if plan is not finalized yet
    if (plan.status !== Status.Finalized) {
      try {
        await axios.put(`/plan/${planId}/status`, {
          status: Status.Finalized,
        });
        plan.status = Status.Finalized;
      } catch (e) {
        setError(ERRORS.Finalized);
      }
    }
  };

  /** When the component renders, retrieve the plan, save it, and generate the sidebar labels */
  useEffect(() => {
    isMounted = true;
    const getPlanAndEmailTemplate = async () => {
      const [
        { data: formattedPlan },
        { data: template },
        { data: currentUser }] = await Promise.all([
        axios.get(`/plan/${planId}/formatted`),
        axios.get('/mail/template/Pending'),
        axios.get('user'),
      ]);
      if (currentUser.isAdmin) setIsAdmin(true);
      // Don't save plans if user left the page before data was retrieved
      if (isMounted) {
        setPlan(formattedPlan);
        setEmailTemplate(template);
        setLabels(getLabels(formattedPlan));
        setPriorityArrayState(priorityArray(formattedPlan));
      }
      if (formattedPlan.team) {
        // gets the email addresses
        const { data: VPandAVP } = await axios.get(`/user/${encodeURIComponent(formattedPlan.team)}/VPandAVP`);
        if (isMounted) {
          setEmails({
            avp: VPandAVP[Role.AssociateVicePresident]
              ? VPandAVP[Role.AssociateVicePresident].map((avp) => avp.email) : [],
          });
        }
      }
    };
    getPlanAndEmailTemplate();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (plan) {
      setLabels(getLabels(plan));
    }
  }, [selectedContent, selectedSection, language]);

  useEffect(() => {
    if (plan) {
      setLabels(getLabels(plan));
    }
  }, [currentBusinessActivity]);

  // dynamically change selectedContent and selectedSection whenever the url changes
  // to show highlight effect
  useEffect(() => {
    history.listen((location) => {
      const content = location.hash.substring(1);
      const sectionKeyMatch = location.search.match(/sectionKey=([A-Za-z]+)/);
      const section = sectionKeyMatch ? sectionKeyMatch[1] : '';
      if (typeof content !== 'undefined') {
        if (selectedContent !== content) {
          setSelectedContent(content);
        }
        if (selectedSection !== section) {
          setSelectedSection(section);
        }
      } else {
        setSelectedSection(section);
        setSelectedContent('');
      }
    });
  }, [history]);

  /**
   * Renders a set of buttons related to actions that can be taken for the plan
   * @param placement Ensures each set of buttons has unique ids
   * @returns {JSX} Button JSX
   */
  const renderActionButtons = (placement) => (
    <div className={i18n.language === 'en' ? 'review-plan-form-buttons' : 'review-plan-form-buttons increase-width'}>
      <button
        type="button"
        id="decrement-button-2"
        onClick={() => { history.goBack(); }}
      >
        {t('back')}
      </button>
      {isAdmin && <button type="button" onClick={() => setOpenEditPlanName(true)}>{t('editPlanName')}</button>}
      {isAdmin && <button type="button" onClick={() => setShowDeletePlanModal(true)}>{t('deletePlan')}</button>}
      <button
        type="button"
        id={`review-plan-${placement}`}
        onClick={() => {
          if (plan.status !== Status.PendingApproval && plan.criticalChanges) {
            setShowRequestSubmitModal(true);
          } else {
            onFinalize();
          }
        }}
      >
        {t('finalize')}
      </button>
      <button
        type="button"
        onClick={() => history.push('/')}
      >
        {t('saveAndExit')}
      </button>
    </div>
  );

  /**
   * Submits a plan for approval and sends an email to the requested upn
   * @param {*} toMail Who to send the email to
   * @param {*} comments Additional comments left to the reviewer by the user
   */
  const sendEmail = async (toMail, toCC, comments) => {
    setOpenSubmitApproval(false);
    try {
      await axios.put(`/plan/${plan._id}/status`, {
        status: Status.PendingApproval,
      });
      try {
        await axios.post(`/mail/Pending/${language}`, {
          toMail,
          toCC,
          comments,
          planName: plan.name,
          viewPlanLink:
          `${window.location.protocol}//${window.location.host}/view-plan/?planId=${plan._id}`,
        });
        history.push({
          pathname: '/view-plan',
          search: `?planId=${plan._id}`,
        });
      } catch (e) {
        setError(ERRORS.SendEmail);
        setToEmail(toMail);
      }
    } catch (e) {
      setError(ERRORS.SubmitForApproval);
    }
  };

  /**
   * Changes plan name
   */
  const editPlanName = async () => {
    const input = document.getElementById('edit-plan-name-input');
    if (input.value) {
      const updatedPlan = { ...plan };
      updatedPlan.name = `${input.value} Plan`;
      if (updatedPlan.type === PlanType.BCP) {
        updatedPlan.team = input.value;
      }
      setPlan(updatedPlan);
      await axios.put(`/admin/plan/${planId}/name`, {
        name: input.value,
      });
      setLabels(getLabels(updatedPlan));
    }
    setOpenEditPlanName(false);
  };

  /**
   * Deletes business activity
   * @param {*} index
   */
  const deleteBA = async (index) => {
    const updatedPlan = { ...plan };
    updatedPlan.values.businessImpactAnalysis.businessActivities.splice(index, 1);
    setPlan(updatedPlan);
    setLabels(getLabels(updatedPlan));
    await axios.put(`/plan/${planId}`, { values: updatedPlan.values });
  };

  /*
    Delete plan route
   */
  const deletePlan = async (planIdToDelete) => {
    await axios.delete(`/admin/plan/delete/${planIdToDelete}`);
    setShowDeletePlanModal(false);
    // redirects user to homepage
    history.replace('/');
    if (renderSidebar) {
      // Hide sidebar if shown
      updateSidebarRender();
    }
  };

  return (
    <>
      {plan && labels.length > 0 && renderSidebar && (
        <Sidebar
          closeSidebar={() => {
            updateSidebarRender();
          }}
          items={labels}
        />
      )}
      <div className="review-my-plan">
        <div className="review-my-plan-content">
          {
            plan && (
              <>
                <div id="review-plan-title">
                  {showSubmittedBox && !error && (
                    <div className="submit-approval-div" id="submit-approval-div">
                      <p className="submit-approval-text">
                        {t('successSubmitForApproval')}
                      </p>
                      <button
                        type="button"
                        className="submit-approval-button"
                        onClick={() => {
                          setShowSubmittedBox(false);
                        }}
                      >
                        X
                      </button>
                    </div>
                  )}
                  <span id="plan-name">{plan.name}</span>
                </div>
                <div className="review-plan-header">
                  <p className="subtitleHeading" id="reviewMyPlanTitle">{t('reviewMyPlan')}</p>
                  <div className={i18n.language === 'en' ? 'review-plan-form-buttons' : 'review-plan-form-buttons increase-width'}>
                    {renderActionButtons('top')}
                  </div>
                </div>
                {!!plan.layout.sections.find((sec) => sec.dataKey === 'businessImpactAnalysis')
                  && (
                    <>
                      <SectionBanner title={t('businessImpactAnalysis')} id="bia-banner" />
                      {plan.values.businessImpactAnalysis.businessActivities.map(
                        (activity, index) => (
                          <ReviewBusinessActivity
                            key={activity.Activity}
                            planId={planId}
                            activity={activity}
                            index={index + 1}
                            labels={plan.layout.sections.find(
                              (sec) => sec.dataKey === 'businessImpactAnalysis',
                            ).content[0]}
                            deleteBA={deleteBA}
                            lengthEqualOne={
                              plan.values.businessImpactAnalysis.businessActivities.length === 1
                            }
                          />
                        ),
                      )}
                      {
                  plan.type === PlanType.BCP
                  && (
                  <>
                    <div className="review-section-title-row">
                      <p className="subtitle" id="staff-requirements-table">
                        {t('staffingRequirements.title')}
                      </p>
                      <button
                        type="button"
                        className="edit-button"
                        onClick={() => {
                          history.replace({
                            pathname: '/edit-plan',
                            search: `?planId=${planId}&sectionKey=businessAsUsualActivities`,
                          });
                        }}
                      >
                        {t('editStaffingTable')}
                      </button>
                    </div>
                    {
                      (priorityArrayState.length === 0) ? (
                        <span className="plan-input-description" style={{ padding: '2rem' }}>{t('noGeneratedTables')}</span>
                      ) : (
                        priorityArrayState.map((priority) => (
                          <ReviewStaffTable
                            priorityLevel={priority}
                            plan={plan}
                            key={`${priority}-review`}
                          />
                        ))
                      )
                }
                  </>
                  )
                }
                      {!!plan.layout.sections.find(
                        (sec) => sec.dataKey === 'businessAsUsualActivities',
                      )
                      && (
                        <ReviewRecoveryContactsLocations
                          planId={planId}
                          data={plan.values.businessAsUsualActivities}
                          labels={plan.layout.sections.find(
                            (sec) => sec.dataKey === 'businessAsUsualActivities',
                          )}
                        />
                      )}
                    </>
                  )}

                {['recovery', 'contacts', 'recoveryLocations'].map((section) => (
                  !!plan.layout.sections.find((sec) => sec.dataKey === section) && (
                  <React.Fragment key={`${section}-key`}>
                    <SectionBanner
                      title={t(section)}
                      id={`${section}-section-banner`}
                      className={`${section}-section-banner`}
                    />
                    <ReviewRecoveryContactsLocations
                      planId={planId}
                      data={plan.values[section]}
                      labels={plan.layout.sections.find((sec) => sec.dataKey === section)}
                    />
                  </React.Fragment>
                  )
                ))}
                <div className="review-plan-buttons-div-bottom">
                  <div className="review-plan-buttons-div">
                    {renderActionButtons('bottom')}
                  </div>
                </div>
                <ConfirmDeleteModal
                  isOpen={showDeletePlanModal}
                  deletePlan={deletePlan}
                  handleClose={() => { setShowDeletePlanModal(false); }}
                  plan={plan}
                />
              </>
            )
          }
        </div>
      </div>
      {openEditPlanName && (
        <EditPlanNameModal
          isOpen={openEditPlanName}
          editPlanName={editPlanName}
          oldName={plan.name.match(/(.+?)(?=\sPlan$)/)[0]}
          handleClose={() => setOpenEditPlanName(false)}
        />
      )}
      {showFinalizeModal && (
        <FinalizeModal
          isOpen={showFinalizeModal}
          handleClose={() => setShowFinalizeModal(false)}
          onExit={() => history.push('/')}
          planName={plan.name}
          planStatus={plan.status}
        />
      )}
      {showRequestSubmitModal && (
        <RequestSubmitModal
          isOpen={showRequestSubmitModal}
          handleClose={() => setShowRequestSubmitModal(false)}
          action={() => {
            setOpenSubmitApproval(true);
            setShowRequestSubmitModal(false);
          }}
        />
      )}
      {plan && emailTemplate && emails && (
      <SubmitPlanModal
        isOpen={openSubmitApproval}
        handleClose={() => setOpenSubmitApproval(false)}
        status={Status.PendingApproval}
        onSubmitApproval={sendEmail}
        template={emailTemplate[language].body}
        plan={plan}
        emails={emails}
        confirmButtonText={t('submit')}
      />
      )}
      {
        error === ERRORS.SubmitForApproval && (
          <Dialog
            open
            warning
            type="error"
            title={t('error')}
            content={t('errorSubmitForApproval')}
            onClose={() => setError('')}
          />
        )
      }
      {
        error === ERRORS.SendEmail && (
          <Dialog
            open
            warning
            type="error"
            title={t('error')}
            content={t('errorSendEmail', { email: toEmail })}
            onClose={() => setError('')}
          />
        )
      }
    </>
  );
};

ReviewPlanPage.defaultProps = {
  renderSidebar: false,
  updateSidebarRender: null,
};

ReviewPlanPage.propTypes = {
  renderSidebar: PropTypes.bool,
  updateSidebarRender: PropTypes.func,
};

const mapStateToProps = (state) => ({
  renderSidebar: state.sidebar.renderSidebar,
});

export default connect(mapStateToProps, {
  updateSidebarRender: renderSidebarAction,
})(ReviewPlanPage);
