import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Logo } from 'kdc-component-library';
import { connect } from 'react-redux';
import PDFObject from 'pdfobject';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Redirect, useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import useQuery from '../../hooks/QueryHook';
import ApproveModal from '../ApproveModal';
import Sidebar from '../Sidebar';
import renderSidebarAction from '../../store/actions/renderSidebarAction';
import axios from '../../services/Api';
import updatePlanValuesAction from '../../store/actions/updatePlanValuesAction';
import { StatusTranslation, Status } from '../../enums/PlanStatus';
import { PlanType } from '../../enums/PlanType';
import Role from '../../enums/Role';
import validateId from '../../services/IdValidator';
import CreateDeclineModal from '../CreateDeclineModal';
import '../../styles/components/pages/ViewPlanPage.scss';

/**
 * ViewPlanPage functional component
 * @returns {JSX} component
 */
export const ViewPlanPage = ({
  renderSidebar,
  updateSidebarRender,
}) => {
  const [plan, setPlan] = useState(null);
  const [user, setUser] = useState({});
  const [userRoles, setUserRoles] = useState([]);
  const [pdfBlobURL, setPDFBlobURL] = useState('');
  const planId = useQuery().get('planId');
  const [viewSidebar, setViewSidebar] = useState(false);
  const { t } = useTranslation('common');
  const { i18n } = useTranslation();
  const { language } = i18n;
  const [emailTemplate, setEmailTemplate] = useState([]);
  const [emails, setEmails] = useState([]);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [approveDeproveAuthority, setAd] = useState(null);
  const [isTeamAvp, setTeamAvp] = useState(false);
  const [isTeamVp, setTeamVp] = useState(false);
  const [DeclineModalOpen, setDeclineModalOpen] = useState(false);
  const history = useHistory();
  const { instance } = useMsal();

  // Send user back to home page if planId is not a valid ID
  if (!validateId(planId)) {
    return <Redirect to="/" />;
  }

  // Default landingPage items
  const items = [
    {
      id: t('selectMyPlan'),
      onClick: () => {
        history.push('/');
        updateSidebarRender();
      },
      label: t('selectMyPlan'),
      className: 'top-item',
    },
    {
      id: t('runAReport'),
      onClick: () => {
        history.push('/report');
        updateSidebarRender();
      },
      label: t('runAReport'),
      className: 'top-item',
    },
    {
      id: t('support'),
      onClick: () => {
        history.push('/support');
        updateSidebarRender();
      },
      label: t('support'),
      className: 'landing-item',
    },
    {
      id: 'Home',
      label: t('home'),
      right: true,
      url: '/',
      className: 'landing-item',
    },
    {
      id: 'Logout',
      label: t('logout'),
      right: true,
      onClick: () => instance.logoutRedirect({ postLogoutRedirectUri: window.AZURE_REDIRECT_URL }),
      className: 'landing-item',
    },
  ];

  /**
   *  When the component renders, retrieve the plan, get email addresses,
   * save it, and generate the sidebar labels
   */
  useEffect(() => {
    let isMounted = true;
    const getFormattedPlan = async () => {
      const [{ data: currentUser }, { data: formattedPlan }] = await Promise.all([
        axios.get('/user'),
        axios.get(`/plan/${planId}/formatted`),
      ]);
      const { data: approveDeproveAuth } = await axios.get(`/auth/AD/${currentUser._id}`);
      // Don't save plans if user left the page before data was retrieved
      if (isMounted) {
        setUser(currentUser);
        setUserRoles(currentUser.role);
        setPlan(formattedPlan);
        setAd(approveDeproveAuth);
      }
    };

    /**
     * Gets emails for auto-fill
     */
    const getEmails = async () => {
      const { data: formattedPlan } = await axios.get(`/plan/${planId}/formatted`);
      let isAvp = false;
      let isVp = false;

      if (formattedPlan.type === PlanType.BCP) {
        const [{ data: currentUser }, { data: teamAvpVp }] = await Promise.all([
          axios.get('/user'),
          formattedPlan.team ? axios.get(`/user/${encodeURIComponent(formattedPlan.team)}/VPandAVP`) : {},
        ]);
        isAvp = teamAvpVp
          && teamAvpVp[Role.AssociateVicePresident].find((avp) => avp._id === currentUser._id);
        isVp = teamAvpVp
        && teamAvpVp[Role.VicePresident].find((vp) => vp._id === currentUser._id);

        if (isVp || isAvp || currentUser.isAdmin) {
          const { data: teamLeads } = await axios.get(`/roll-call/team-leads-for-avp/${formattedPlan.team}`);
          const businessTeamLeadEmails = [...new Set(teamLeads.map(
            (item) => item.lead.map((l) => l.email),
          ).flat())];
          if (isMounted) {
            setEmails(businessTeamLeadEmails);
            setTeamAvp(true);
            setTeamVp(isVp);
          }
        }
      }
    };

    /**
     * Gets all email templates and each static template can be passed
     * as prop with language to a specific modal
     */
    const getEmailTemplate = async () => {
      const { data: template } = await
      axios.get('/mail/templates');
      // Don't save plans if user left the page before data was retrieved
      if (isMounted) {
        setEmailTemplate(template);
      }
    };
    const getPdf = async () => {
      /** New param to account for PDF language */
      const { data } = await axios.get(`/plan/${planId}/pdf/${i18n.language}`, {
        headers: {
          Accept: 'application/pdf',
        },
        responseType: 'blob',
      });
      const pdfBlob = new Blob([data], { type: 'application/pdf' });
      const objectURL = URL.createObjectURL(pdfBlob);
      if (isMounted) {
        const options = {
          width: '100%',
          height: '80vh',
        };
        PDFObject.embed(objectURL, '#pdf-container', options);
        setPDFBlobURL(objectURL);
      }
    };

    getEmails();
    getFormattedPlan();
    getEmailTemplate();
    getPdf();
    setViewSidebar(true);
    return () => {
      isMounted = false;
      // Revoke URL on unmount to prevent memory leaks
      URL.revokeObjectURL(pdfBlobURL);
      setViewSidebar(false);
    };
  }, []);

  /**
   * Gets the date from the object id
   * @param {date} incomingDate incoming date to convert
   * @param {boolean} nextReview indication of review date
   * @returns string
   */
  const getDate = (incomingDate, nextReview = false) => {
    if (!incomingDate) {
      return 'N/A';
    }
    const date = nextReview ? moment(incomingDate).add(6, 'months') : new Date(incomingDate);
    const formattedDate = date.toString().split(' ');
    // month day, year
    return `${formattedDate[1]} ${formattedDate[2]}, ${formattedDate[3]}`;
  };

  /** Updates plan status
   * Displays the messeng sent notification
   * Scrolls user to top of the page
   * Sending Email implemented after MVP
  */
  const sendMailAndStatus = async (toMail, toCC, comments, status) => {
    if (toMail.length === 0) return;
    await axios.put(`/plan/${planId}/status`, {
      status,
    });

    plan.status = status;

    if (status === Status.Declined) {
      document.getElementById('declineStatusMessage').style.display = '';
      setDeclineModalOpen(false);
    } else if (status === Status.Approved) {
      document.getElementById('approveStatusMessage').style.display = '';
      plan.approveDate = getDate();
      setApproveModalOpen(false);
    }

    await axios.post(`/mail/${status === Status.Approved ? 'Approve' : 'Decline'}/${language}`, {
      toMail,
      toCC,
      comments,
      planName: plan.name,
      planDate: moment().add(6, 'months').format('Do MMMM y'),
      viewPlanLink:
        `${window.location.protocol}//${window.location.host}/view-plan/?planId=${plan._id}`,
      downloadPlanLink:
        `${window.location.protocol}//${window.location.host}/view-plan/?planId=${plan._id}`,
    });

    window.scrollTo(0, 0);
  };

  const sidebarClose = () => {
    updateSidebarRender();
  };

  /** Finds specific email template
   * @param {string} status
   * @return {object} the email template
   */
  const templateFinder = (status) => emailTemplate.find((element) => element.key === status);

  /**
   * Plan status must be in pending stage in order to decline or approve
   * @returns Boolean
   */
  const isDisabled = () => plan.status !== Status.PendingApproval;

  /**
   * checks if buttons should appear based on plan type
   * @returns Boolean
   */
  // es
  const showButtons = (cPlan) => cPlan.type !== PlanType.BCC && cPlan.type !== PlanType.Logistics;

  return (
    <>
      {plan && renderSidebar && viewSidebar && (
        <Sidebar
          closeSidebar={() => {
            sidebarClose();
          }}
          items={items}
        />
      )}
      <div className="view-my-plan">
        {
          plan && (
            <>
              <div className="view-my-plan-content">
                <h2>{plan.name}</h2>
                <div className="statusPlanAndMessage">
                  <div className="date-grid">
                    <h2>{t('planStatus', { status: t(StatusTranslation[plan.status]) })}</h2>
                    <p className="last-approved-date" id="last-approved-date">{`${t('lastApprovedDate')} ${getDate(plan.lastApprovedAt)}`}</p>
                    <p className="last-modified-date" id="last-modified-date">{`${t('updatedDate')} ${getDate(plan.updatedAt)}`}</p>
                    <p className="next-review-date" id="next-review-date">{`${t('nextReviewDate')} ${getDate(plan.lastApprovedAt, true)}`}</p>
                  </div>
                  <h2 id="declineStatusMessage" className="statusMessage" style={{ display: 'none' }}>
                    {t('declineMessage')}
                    <span className="circle" />
                    <button
                      type="button"
                      id="statusButton"
                      onClick={() => { document.getElementById('declineStatusMessage').style.display = 'none'; }}
                      className="statusButton"
                    >
                      X
                    </button>
                  </h2>

                  <h2 id="approveStatusMessage" className="statusMessage" style={{ display: 'none' }}>
                    {t('planApproved')}
                    <span className="circle" />
                    <button
                      type="button"
                      id="statusButton"
                      onClick={() => { document.getElementById('approveStatusMessage').style.display = 'none'; }}
                      className="statusButton"
                    >
                      X
                    </button>
                  </h2>

                </div>

                <div id="pdf-container" style={{ height: '100%' }} />
                {
                  userRoles && pdfBlobURL ? (
                    <div className="pdf-buttons">
                      <a id="download-plan-link" href={pdfBlobURL} download={`Business Continuity Plan ${moment().format('DDMMYY')}.pdf`}>
                        <button className=" pdf-action-buttons button-Download" id="download-plan-button" type="button">
                          {t('download')}
                        </button>
                      </a>
                      {
                        approveDeproveAuthority
                        && (plan.type === PlanType.BCP && (isTeamAvp || isTeamVp))
                        && showButtons(plan)
                        && (
                          <>
                            <button
                              className=" pdf-action-buttons button-Approve"
                              type="button"
                              id="approve-plan-button"
                              onClick={() => setApproveModalOpen(true)}
                              disabled={isDisabled()}
                            >
                              {t('approve')}
                            </button>

                            <button
                              className="pdf-action-buttons button-Decline"
                              id="decline-plan-button"
                              type="button"
                              onClick={() => setDeclineModalOpen(true)}
                              disabled={isDisabled()}
                            >
                              {t('decline')}
                            </button>
                          </>
                        )

                      }
                    </div>
                  ) : (
                    <div className="loading-pdf">
                      <Logo type="KDCSpinningLogo" />
                    </div>
                  )
                }
                <CreateDeclineModal
                  isOpen={DeclineModalOpen}
                  handleClose={() => setDeclineModalOpen(false)}
                  template={templateFinder('Decline') ? templateFinder('Decline')[language].body : ''}
                  plan={plan}
                  emails={emails}
                  mailAndStatus={sendMailAndStatus}
                />
                <ApproveModal
                  isOpen={approveModalOpen}
                  handleClose={() => setApproveModalOpen(false)}
                  handleApprove={sendMailAndStatus}
                  template={templateFinder('Approve') ? templateFinder('Approve')[language].body : ''}
                  plan={plan}
                  emails={emails}
                  userName={`${user.firstName} ${user.lastName}`}
                />
              </div>
            </>
          )
        }
      </div>
    </>
  );
};

ViewPlanPage.defaultProps = {
  renderSidebar: null,
  updateSidebarRender: null,
};

ViewPlanPage.propTypes = {
  planValues: PropTypes.shape({}).isRequired,
  updateSidebarRender: PropTypes.func,
  renderSidebar: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  planValues: state.values,
  renderSidebar: state.sidebar.renderSidebar,
});

export default connect(mapStateToProps, {
  updatePlanValues: updatePlanValuesAction,
  updateSidebarRender: renderSidebarAction,
})(ViewPlanPage);
