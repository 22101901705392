/**
 * Role enum
 */
const Role = {
  BusinessContinuityCoordinator: 'Business Continuity Coordinator',
  CommunicationsTeam: 'Communications Team',
  LogisticsTeam: 'Logistics Team',
  InfrastructureTeam: 'Infrastructure Team',
  OperationsTeam: 'Operations Team',
  BusinessTeam: 'Business Team',
  VicePresident: 'Vice President',
  Executive: 'Executive',
  Scribe: 'Scribe',
  IncidentManagementTeamSupport: 'Incident Management Team Support',
  OfficeLead: 'Office Lead',
  AssociateVicePresident: 'Associate Vice President',
  CGLBusinessContinuityCoordinator: 'CGL Business Continuity Coordinator',
};

export default Role;
