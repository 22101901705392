import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SelectSearch from 'react-select-search';
import PlanBaseInput from './PlanBaseInput';

import '../styles/components/PlanSearchInput.scss';
import '../styles/components/ReportsHintModal.scss';
import '../styles/components/PlanInput.scss';

/**
 * PlanSearchInput functional component
 * Renders a text input and a dropdown list of items the user can select from
 * Users can search the list by typing in the text input to filter results
 * @param {*} props component props
 * @returns {JSX} component JSX
 */
const PlanSearchInput = forwardRef((props, ref) => {
  const {
    inputProperties,
    parent,
    onChange,
    saveRepeatable,
    selectedIndex,
  } = props;

  const { t } = useTranslation('common');
  const { i18n } = useTranslation();
  const { language } = i18n;
  const { data } = inputProperties;
  const { displayKey } = inputProperties.dataSource;
  const id = `${parent.join('-')}`;
  const [otherSelected, setOtherSelected] = useState(false);
  const [customTextValue, setCustomTextValue] = useState('');
  // Formats data items to be compatible with SelectSearch component + orders items alphabetically
  const searchOptions = data.map((value, index) => ({
    name: value[displayKey],
    value: index + 1,
  })).sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }).filter((val) => val.name);
  searchOptions.unshift({ name: 'None', value: 0 });

  if (inputProperties.dataKey === 'essentialBusinessPartners'
    && searchOptions.length > 0
    && searchOptions[searchOptions.length - 1].name !== '*Other...') {
    searchOptions.push({ name: '*Other...', value: searchOptions.length });
  }

  const filterOptions = (options) => (value) => options.filter(
    (opt) => opt.name && opt.name.toLowerCase().includes(value.toLowerCase()),
  );

  useImperativeHandle(ref, () => ({
    switchOnEdit: (selectOther) => {
      setOtherSelected(selectOther);
    },
    resetTextValue: () => {
      setCustomTextValue('');
    },
    setCustomText: (val) => {
      setCustomTextValue(val);
    },
  }));

  const customOnChange = (inputKey, index) => {
    // Set switch for editing in parent component
    if (index === 0) {
      onChange(inputKey, { name: '' });
    } else if (searchOptions[index]?.name === '*Other...') {
      onChange(inputKey, null);
      setOtherSelected(true);
    } else {
      onChange(parent, data[index - 1]);
    }

    if (data[index - 1] && !inputProperties.noAutoPopulate) {
      saveRepeatable(inputProperties, parent);
    }
  };

  const handleInputChange = (event) => {
    setCustomTextValue(event.target.value);
    onChange(parent, { name: event.target.value });
  };

  const handleOtherClose = () => {
    onChange(parent, null);
    setCustomTextValue('');
    setOtherSelected(false);
  };

  const inputElement = (
    <>
      {otherSelected ? (
        <div className="plan-select-input" id={id}>
          <input
            className="plan-text-input"
            type="text"
            value={customTextValue || ''}
            placeholder={t('enterCustomEBP')}
            onChange={handleInputChange}
          />
          <button className="modal-close-button" type="button" id="cancelOtherSelectButton" onClick={handleOtherClose}>
            x
          </button>

        </div>
      ) : (
        <SelectSearch
          id={id}
          value={selectedIndex === -1 ? -1 : selectedIndex + 1}
          placeholder={inputProperties.placeholder ? inputProperties.placeholder[language] : t('search')}
          options={searchOptions}
          filterOptions={filterOptions}
          search
          onChange={(index) => customOnChange(parent, index)}
        />
      ) }
    </>

  );

  return (
    <PlanBaseInput
      displayLabel={false}
      parent={parent}
      inputProperties={inputProperties}
      inputElement={inputElement}
      onChange={onChange}
    />
  );
});

PlanSearchInput.propTypes = {
  inputProperties: PropTypes.shape({
    allowUserDefined: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.shape({})),
    dataKey: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({})]),
    ),
    dataSource: PropTypes.shape({
      displayKey: PropTypes.string,
    }),
    description: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    placeholder: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    isDisabled: PropTypes.bool,
    isOptional: PropTypes.bool,
    isRepeatable: PropTypes.bool,
    noAutoPopulate: PropTypes.bool,
    label: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    itemLabel: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    type: PropTypes.string,
    values: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
    ),
  }).isRequired,
  selectedIndex: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  saveRepeatable: PropTypes.func.isRequired,
  parent: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
};

PlanSearchInput.defaultProps = {
  selectedIndex: null,
};

PlanSearchInput.displayName = 'PlanSearchInput';

export default PlanSearchInput;
