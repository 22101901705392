import React, {
  useState, useEffect, useRef,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Logo } from 'kdc-component-library';
import DOMPurify from 'dompurify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import * as XLSX from 'xlsx';
import { useMsal } from '@azure/msal-react';
import { useReactToPrint } from 'react-to-print';
import Sidebar from '../Sidebar';
import Tab from '../Tab';
import axios from '../../services/Api';
import '../../styles/components/pages/CriticalActivityReportPage.scss';
import 'react-toastify/dist/ReactToastify.css';
import ReportLabel from '../ReportLabel';
import Role from '../../enums/Role';

/** CriticalActivityReportPage component class */
export const CriticalActivityReportPage = (props) => {
  const { t } = useTranslation('common');
  const [supportSidebar, setSupportSidebar] = useState(false);
  const [toggleTab, setToggleTab] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [reportBuffer, setReportBuffer] = useState();
  const [workbook, setWorkbook] = useState();
  const [vp, setVP] = useState('');
  const [evp, setEVP] = useState('');
  const [avp, setAVP] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [businessTeam, setBusinessTeam] = useState('');
  const [vps, setVPS] = useState([]);
  const [evps, setEVPS] = useState([]);
  const [avps, setAVPS] = useState([]);
  const [locations, setLocations] = useState([]);
  const [businessTeams, setBusinessTeams] = useState([]);
  const [priorityLevel, setPrioirtyLevel] = useState('');
  const [border, setBorder] = useState('');
  const [filteredCriticalActivities, setFilteredCriticalActivities] = useState([]);
  const [staffingTable, setStaffingTable] = useState([]);
  const [isDownloading, setIsDownloading] = useState();
  const [chosenCriticalActivity, setChosenCriticalActivity] = useState({});
  const [users, setUsers] = useState([]);
  const [businessTeamsForVpEvp, setBusinessTeamsForVpEvp] = useState([]);
  const [validateEvps, setValidateEvps] = useState([]);
  const [validateVps, setValidateVps] = useState([]);
  const [showFileButton, setShowFileButton] = useState(true);
  const { renderSidebar, dispatch } = props; // redux state
  const history = useHistory();
  const today = new Date();
  const tablesToAdd = [];
  const { instance } = useMsal();
  const reportTemplateRefMain = useRef(null);
  const reportTemplateRefSummary = useRef(null);
  const reportTemplateRefActivation = useRef(null);
  const promiseResolveRef = useRef(null);
  const [anyFilterSelected, setAnyFilterSelected] = useState(false);

  useEffect(() => {
    if (!showFileButton && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [showFileButton]);

  /**
   * Gets today's formatted date
   * @returns formatted date for files
   */
  const formateFileDate = () => {
    const offset = today.getTimezoneOffset();
    const offsetToday = new Date(today.getTime() - (offset * 60 * 1000));
    const formattedDate = offsetToday.toISOString().split('T')[0].replaceAll('-', '');

    return formattedDate;
  };

  const handlePrint = useReactToPrint({
    documentTitle: `CAR-${toggleTab}-${formateFileDate()}.pdf`,
    onBeforeGetContent: () => new Promise((resolve) => {
      promiseResolveRef.current = resolve;
      setShowFileButton(false);
    }),
    onAfterPrint: () => {
      // Reset the Promise resolve so we can print again
      promiseResolveRef.current = null;
      setShowFileButton(true);
    },
    removeAfterPrint: true,
  });

  /**
   * Sorts options lists alphabetically
   * @param {*} a item in list
   * @param {*} b item in list
   * @returns
   */
  const sortAlphabetically = (a, b, bccOrAvps = false) => {
    const nameA = bccOrAvps ? `${a?.firstName} ${a?.lastName}`?.toUpperCase() : a.label.toUpperCase();
    const nameB = bccOrAvps ? `${b?.firstName} ${b?.lastName}`?.toUpperCase() : b.label.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  /**
   * Takes in a mgmt array and converts it into select options
   * @param {*} mgmt vps to be convverted into select input
   * @returns options for select input
   */
  const mgmtToSelectOptions = (mgmt) => mgmt.filter((vicePres) => vicePres.length >= 1)
    .flat()
    .filter((vicePres, index, self) => index === self.findIndex((duplicate) => (
      duplicate._id === vicePres._id && duplicate._id === vicePres._id
    )))
    .map((vicePresident) => ({
      label: `${vicePresident.firstName} ${vicePresident.lastName}`,
      value: vicePresident._id,
    }))
    .sort(sortAlphabetically);

  /** Gets initial data */
  useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();

    const getCriticalActivityData = async () => {
      try {
        const [{ data: buffer }, { data }] = await Promise.all([
          /** Returns arrayBuffer */
          axios.post('/excel-template/criticalActivities', {
            filters: {},
          }, {
            signal: abortController.signal,
            responseType: 'arraybuffer',
          }),
          /** Returns data */
          axios.post('/report/criticalActivities', {
            filters: {
              priorityLevel: '',
              evp: '',
              vp: '',
            },
          }, {
            signal: abortController.signal,
          }),
        ]);

        /** Load and convert buffer to an html table */
        const readData = XLSX.read(buffer, { type: 'buffer' });
        const worksheetname = readData.SheetNames[0];
        const worksheet = readData.Sheets[worksheetname];
        const worksheetData = XLSX.utils.sheet_to_html(worksheet);

        if (isMounted) {
          setWorkbook(worksheetData);
          setReportBuffer(buffer);
          setSupportSidebar(true);
          setToggleTab(t('howTo'));
          setStaffingTable(data.teamStaffTables);
          /** options for avps select */
          const avpsMap = data.criticalActivities.map((activity) => activity.avps);
          setAVPS(mgmtToSelectOptions(avpsMap));

          /** options for vps select */
          const vpsMap = data.criticalActivities.map((activity) => activity.vps);
          setVPS(mgmtToSelectOptions(vpsMap));

          /** options for evps select */
          const evpsMap = data.criticalActivities.map((activity) => activity.evps);
          setEVPS(mgmtToSelectOptions(evpsMap));

          /** options for locations */
          const locationsMap = [...new Set(data.criticalActivities
            .map((activity) => activity.location)
            .flat().filter((loc) => loc))]
            .map((loc) => ({ label: loc, value: loc }))
            .sort(sortAlphabetically);
          setLocations(locationsMap);

          const businessTeamsMap = [...new Set(data.criticalActivities
            .map((activity) => activity.team)
            .filter((team) => team))]
            .sort()
            .map((team) => ({ label: team, value: team }))
            .sort(sortAlphabetically);
          /** options for business teams */
          setBusinessTeams(businessTeamsMap);

          setFilteredCriticalActivities(data.criticalActivities);
          setLoading(false);
        }
      } catch (e) {
        toast(t('reportError'), {
          type: 'error',
        });
      }
    };

    const getTeamsAndUsers = async () => {
      try {
        // Gets business teams given VP, EVP
        const teams = await axios.post('/report/businessTeams', { vp, evp }, {
          signal: abortController.signal,
        });
        // Extract locations
        const locationsFromTeams = teams.data
          .flatMap((team) => team.locations.map((loc) => loc.location));
        // Axios post request with filter as body
        const bccAndAvps = await axios.post('/report/bccAndAvps', {
          filters: {
            locations: locationsFromTeams,
          },
        }, {
          signal: abortController.signal,
        });

        if (isMounted) {
          setUsers(bccAndAvps.data);
          setBusinessTeamsForVpEvp(teams.data
            .filter((team) => team.locations.some((loc) => loc.location)));
        }
      } catch (e) {
        toast(t('reportError'), {
          type: 'error',
        });
      }
    };

    getCriticalActivityData();

    // Run only on this tab
    if (toggleTab === t('activationContacts')) {
      getTeamsAndUsers();
    }

    return () => {
      abortController.abort();
      isMounted = false;
    };
  }, []);

  /** Resets states when tab changes */
  useEffect(() => {
    setEVP('');
    setVP('');
    setAVP('');
    setPrioirtyLevel('');
    setBusinessTeam('');
    setSelectedLocation('');
    setChosenCriticalActivity({});
    setBusinessTeamsForVpEvp([]);
    setUsers([]);
    setAnyFilterSelected(false);
  }, [toggleTab]);

  /**
   * Retrieves filtered data
   * and sets border colour based on
   * priority level
   */
  useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();

    if (isMounted) {
      if (priorityLevel === t('urgent')) {
        setBorder('urgent');
      } else if (priorityLevel === t('high')) {
        setBorder('high');
      } else if (priorityLevel === t('medium')) {
        setBorder('medium');
      } else if (priorityLevel === t('low')) {
        setBorder('low');
      } else {
        setBorder('');
      }
    }

    const updateCriticalActivityData = async () => {
      try {
        const { data } = await axios.post('/report/criticalActivities', {
          filters: {
            priorityLevel,
            evp,
            vp,
            avp,
            location: selectedLocation,
            team: businessTeam,
          },
        }, {
          signal: abortController.signal,
        });
        if (data.criticalActivities && isMounted) {
          setFilteredCriticalActivities(data.criticalActivities.filter((ca) => (
            priorityLevel
              ? ca.priorityLevel.some((level) => level.priorityLevel === priorityLevel)
              : true
          )));
          setChosenCriticalActivity(data.criticalActivities[0]);
          setStaffingTable(data.teamStaffTables);
          setLoading(false);
        }
      } catch (e) {
        toast(t('reportError'), {
          type: 'error',
        });
      }
    };

    const updateTeamsAndUsers = async () => {
      try {
        // Gets business teams given VP, EVP
        const teams = await axios.post('/report/businessTeams/', { vp, evp }, {
          signal: abortController.signal,
        });
        // Extract locations
        const locationsFromTeams = teams.data
          .flatMap((team) => team.locations.map((loc) => loc.location));

        // Axios post request with filter as body
        const bccAndAvps = await axios.post('/report/bccAndAvps', {
          filters: {
            locations: locationsFromTeams,
          },
        }, {
          signal: abortController.signal,
        });

        if (teams.data.length > 0 && isMounted) {
          setUsers(bccAndAvps.data);
          setBusinessTeamsForVpEvp(teams.data
            .filter((team) => team.locations.some((loc) => loc.location)));
        }
      } catch (e) {
        toast(t('reportError'), {
          type: 'error',
        });
      }
    };

    if (toggleTab !== t('howTo')) {
      updateCriticalActivityData();
    }

    // Run only on this tab
    if (toggleTab === t('activationContacts') && (vp || evp)) {
      updateTeamsAndUsers();
    }

    if (vp || evp || priorityLevel || selectedLocation || businessTeam) {
      setAnyFilterSelected(true);
    } else {
      setAnyFilterSelected(false);
    }
    return () => {
      abortController.abort();
      isMounted = false;
    };
  }, [vp, evp, avp, priorityLevel, selectedLocation, businessTeam, toggleTab]);

  useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();

    const getValidatedVpsEvps = async () => {
      try {
        // Gets vps and evps the user has access to
        const { data } = await axios.post('/report/activationContacts', { vps, evps }, {
          signal: abortController.signal,
        });
        if (isMounted) {
          setValidateEvps(data.evps);
          setValidateVps(data.vps);
        }
      } catch (e) {
        toast(t('reportError'), {
          type: 'error',
        });
      }
    };

    if (toggleTab === t('activationContacts')) {
      getValidatedVpsEvps();
    }

    return () => {
      abortController.abort();
      isMounted = false;
    };
  }, [evps, vps]);

  /**
   * Updates filter given filtered activites
   * @param {*} activities filtered activities
   * @param {*} selectedFilter state not to update
   */
  const setFilters = (activities, selectedFilter) => {
    if (selectedFilter !== 'evp') setEVPS(mgmtToSelectOptions(activities.map((activity) => activity.evps)));
    if (selectedFilter !== 'vp') setVPS(mgmtToSelectOptions(activities.map((activity) => activity.vps)));

    if (toggleTab === t('main')) {
      if (selectedFilter !== 'avp') setAVPS(mgmtToSelectOptions(activities.map((activity) => activity.avps)));
      if (selectedFilter !== 'businessTeam') {
        setBusinessTeams([...new Set(activities
          .map((activity) => activity.team)
          .filter((team) => team))]
          .map((team) => ({ label: team, value: team }))
          .sort(sortAlphabetically));
      }

      if (selectedFilter !== 'location') {
        setLocations([...new Set(activities
          .map((activity) => activity.location)
          .flat().filter((loc) => loc))]
          .map((loc) => ({ label: loc, value: loc }))
          .sort(sortAlphabetically));
      }
    }
  };

  /** update all filters once vp changes */
  useEffect(() => {
    if (vp && filteredCriticalActivities && filteredCriticalActivities.length >= 1) {
      /** Get all plans with that VP */
      const filteredByVP = filteredCriticalActivities
        .filter((activity) => activity.vps
          .some((vicePres) => vicePres._id.toString() === vp.toString()));

      setFilters(filteredByVP, 'vp');
    } else {
      setFilters(filteredCriticalActivities, 'vp');
    }
  }, [vp, filteredCriticalActivities]);

  /** update all filters once evp changes */
  useEffect(() => {
    if (evp && filteredCriticalActivities && filteredCriticalActivities.length >= 1) {
      const filteredByEVP = filteredCriticalActivities
        .filter((activity) => activity.evps.some((vicePres) => vicePres._id.toString() === evp));

      setFilters(filteredByEVP, 'evp');
    } else {
      setFilters(filteredCriticalActivities, 'evp');
    }
  }, [evp, filteredCriticalActivities]);

  /** update all filters once avp changes */
  useEffect(() => {
    if (avp && filteredCriticalActivities && filteredCriticalActivities.length >= 1) {
      const filteredByAVP = filteredCriticalActivities
        .filter((activity) => activity.evps.some((vicePres) => vicePres._id.toString() === avp));

      setFilters(filteredByAVP, 'avp');
    } else {
      setFilters(filteredCriticalActivities, 'avp');
    }
  }, [avp, filteredCriticalActivities]);

  /** update all filters once businessTeam changes */
  useEffect(() => {
    if (businessTeam && filteredCriticalActivities && filteredCriticalActivities.length >= 1) {
      const filteredByBT = filteredCriticalActivities
        .filter((activity) => activity.team === businessTeam);

      setFilters(filteredByBT, 'businessTeam');
    } else {
      setFilters(filteredCriticalActivities, 'businessTeam');
    }
  }, [businessTeam, filteredCriticalActivities]);

  /** update all filters once location changes */
  useEffect(() => {
    if (selectedLocation && filteredCriticalActivities && filteredCriticalActivities.length >= 1) {
      const filteredByLocation = filteredCriticalActivities
        .filter((activity) => activity.location?.some((loc) => loc === selectedLocation));

      setFilters(filteredByLocation, 'location');
    } else {
      setFilters(filteredCriticalActivities, 'location');
    }
  }, [selectedLocation, filteredCriticalActivities]);

  /**
   * Downloads Excel
   */
  const downloadExcel = () => {
    setIsDownloading(true);
    toast(t('reportGenerating'), {
      type: 'info',
    });
    try {
      const blob = new Blob([reportBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const tag = document.createElement('a');
      tag.href = window.URL.createObjectURL(blob);
      tag.download = `CAR-${t('rawData')}-${formateFileDate()}.xlsx`;
      tag.click();
      window.URL.revokeObjectURL(tag.href);

      toast(t('reportDownloaded'), {
        type: 'success',
      });
    } catch (e) {
      toast(t('reportError'), {
        type: 'error',
      });
    } finally {
      setIsDownloading(false);
    }
  };

  /**
   * Sums the arrays
   * @param {*} arrays list of arrays
   * @returns an array of added elements
   */
  const sumArrays = (arrays) => {
    const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
    const result = Array.from({ length: n });
    return result.map((_, i) => arrays.map((xs) => xs[i] || 0).reduce((sum, x) => sum + x, 0));
  };

  /**
   * Gets a combined list of priority timing for the activity
   * @param {*} priorityTiming
   * @returns priority times as string
   */
  const displayPriorityTiming = (priorityTiming) => [...new Set(priorityTiming.map((priority) => priority.priorityLevelTiming).flat().filter((timing) => timing))].join(', ');

  /**
   * Gets a sum of all staffing
   * @param {*} priorityTiming array
   * @returns sum of staff
   */
  const displayPriorityStaffing = (priorityTiming) => priorityTiming
    .filter((pt) => pt.totalStaffRequired !== null && parseInt(pt.totalStaffRequired, 10))
    .map((pt) => parseInt(pt.totalStaffRequired, 10))
    .reduce((sum, totalStaffRequired) => sum + totalStaffRequired, 0);

  /**
   * Formats phone number
   * @param {*} contact number
   * @param {*} type type of number
   * @param {*} ext extension if given
   * @param {*} key key for map
   * @returns formatted number in ReportLabel
   */
  const formatPhoneNumber = (contact, type, ext, key) => {
    let number = (`${contact}`).replace(/\D/g, '');
    const format = number.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (format) {
      number = `(${format[1]}) ${format[2]}-${format[3]}`;
    }

    if (type === 'workPhone') {
      return (
        <ReportLabel key={key || undefined} label={t('workPhone')} content={ext ? `${number} x${ext}` : number} />
      );
    } if (type === 'mainPhone') {
      return (
        <ReportLabel key={key || undefined} label={t('mainPhone')} content={number} />
      );
    }
    return (
      <p key={key || undefined}>{number}</p>
    );
  };

  /** defining static content */
  const tabTitles = [t('howTo'), t('summary'), t('activationContacts'), t('main'), t('rawData')];
  const emptyStaffArray = new Array(12).fill(0);
  const months = [
    t('jan'),
    t('feb'),
    t('mar'),
    t('apr'),
    t('may'),
    t('jun'),
    t('jul'),
    t('aug'),
    t('sep'),
    t('oct'),
    t('nov'),
    t('dec'),
  ];
  /** options for priority level select */
  const priorityLevels = ['urgent', 'high', 'medium', 'low'].map((level) => ({
    label: t(level),
    value: level.charAt(0).toUpperCase() + level.slice(1),
  }));

  const sidebarClose = () => dispatch({ type: 'SIDEBAR' });

  const items = [
    {
      id: t('selectMyPlan'),
      onClick: () => {
        history.push('/');
        sidebarClose();
      },
      label: t('selectMyPlan'),
      className: 'top-item',
    },
    {
      id: t('runAReport'),
      onClick: () => {
        history.push('/report');
        sidebarClose();
      },
      label: t('runAReport'),
      className: 'top-item',
    },
    {
      id: t('support'),
      onClick: () => {
        history.push('/support');
        sidebarClose();
      },
      label: t('support'),
      className: 'landing-item',
    },
    {
      id: 'Home',
      label: t('home'),
      right: true,
      url: '/',
      className: 'landing-item',
    },
    {
      id: 'Logout',
      label: t('logout'),
      right: true,
      onClick: () => instance.logoutRedirect({ postLogoutRedirectUri: window.AZURE_REDIRECT_URL }),
      className: 'landing-item',
    },
  ];

  /** unique teams */
  const uniqueBusinessTeams = [...new Set(filteredCriticalActivities
    .map((activity) => activity.team))];

  /** List of activities extracted */
  const activityOptions = filteredCriticalActivities.map((activity) => ({
    /** To account for empty activity names */
    label: activity.Activity ? activity.Activity : t('notApplicable'),
    value: activity,
  }));

  const uniqueBusinessTeamsForVpEvp = [...new Set(businessTeamsForVpEvp
    .flatMap((team) => team.locations.map((loc) => loc.location)))];

  /**
   * Gets placeholder for filter
   * @returns the placeholder to display based on state
   */
  const getInitialPlaceholder = () => {
    if (anyFilterSelected) {
      return { label: t('reports.allSelected'), value: '' };
    }
    return { label: t('reports.noneSelected'), value: '' };
  };

  return (
    <>
      {renderSidebar && supportSidebar && (
        <Sidebar
          closeSidebar={() => {
            sidebarClose();
          }}
          items={items}
        />
      )}
      <div className="ca-container">
        {(isLoading || !filteredCriticalActivities) ? (
          <Logo type="KDCSpinningLogo" />
        ) : (
          <>
            <p className="ca-title">{t('criticalActivitiesReport')}</p>
            <div className="tab-view">
              <div className="tabs">
                {
                  /** Map all tab buttons */
                  tabTitles.map((title) => (
                    <button id={title} key={`${title}-ca-title`} type="button" className={toggleTab === title ? 'tab active-tab' : 'tab'} onClick={() => setToggleTab(title)}>
                      {title}
                    </button>
                  ))
                }
              </div>

              <div className="contents">
                {/** How To Tab - default */}
                <Tab isActive={toggleTab === t('howTo')}>
                  <Trans i18nKey="howToContent">
                    To navigate and see different sections of this report with different detail,
                    click on the tabs above.
                    <br />
                    <br />
                    Summary: What the VPs need to know now
                    <br />
                    <br />
                    <li>Activities</li>
                    {' '}
                    <li>Priority</li>
                    {' '}
                    <li>Staffing</li>
                    <br />
                    <br />
                    Activation Contacts: Individual who are BCM members for their team and location.
                    <br />
                    <br />
                    Main: Contains the main report.
                    Inside the Main report you’ll be able to Group and Filter
                    by AVP, Location or Team.
                    <br />
                    <br />
                    Raw Data: Contains all the data of this report,
                    for further analysis, on an exportable to Excel layout.
                  </Trans>
                </Tab>

                {/** Summary Tab */}
                <Tab isActive={toggleTab === t('summary')}>
                  <div ref={reportTemplateRefSummary}>
                    <div className="content-overview date-and-time-section">
                      <ReportLabel label={`${t('overview')}:`} content={t('overviewContent')} />

                      {showFileButton && (
                      <>
                        <button
                          type="button"
                          aria-label={t('exportPDF')}
                          className="no-style"
                          disabled={isDownloading}
                          onClick={() => {
                            handlePrint(null, () => reportTemplateRefSummary.current);
                          }}
                        >
                          <FontAwesomeIcon data-tip data-for="downloadTip" icon={faFilePdf} className="pdf-icon" />
                        </button>
                        <ReactTooltip id="downloadTip" place="left" type="light" effect="solid" border>
                          <div>{t('exportPDF')}</div>
                        </ReactTooltip>
                      </>
                      )}
                    </div>

                    <div className="content-filters">
                      { evps
                    && (
                    <ReportLabel
                      val={evps.find((exec) => exec?.value?.toString() === evp)
                        || getInitialPlaceholder()}
                      options={evps}
                      label={`${t('evp')}:`}
                      content={evps.length === 1 ? evps[0].label : t('selectEVP')}
                      isSelect
                      onChange={(e) => (e && e.value ? setEVP(e.value) : setEVP(''))}
                    />
                    )}

                      { vps
                    && (
                    <ReportLabel
                      val={vps.find((vicePres) => vicePres?.value?.toString() === vp)
                        || getInitialPlaceholder()}
                      options={vps}
                      label={`${t('vp')}:`}
                      content={vps.length === 1 ? vps[0].label : t('selectVP')}
                      isSelect
                      onChange={(e) => (e && e.value ? setVP(e.value) : setVP(''))}
                    />
                    )}
                    </div>

                    <div className="date-and-time-section">
                      <ReportLabel
                        label={`${t('date')}`}
                        content={today.toLocaleDateString()}
                      />
                      <ReportLabel
                        label={`${t('time')}`}
                        content={today.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                      />
                    </div>

                    <div className={border ? `summary-content ${border}-content` : 'summary-content'}>
                      <ReportLabel
                        val={priorityLevel
                          ? { label: priorityLevel, value: priorityLevel }
                          : getInitialPlaceholder()}
                        options={priorityLevels}
                        label={`${t('priorityLevel')}:`}
                        content={priorityLevels.length === 1 ? priorityLevels[0].label : t('selectPriorityLevel')}
                        isSelect
                        onChange={(e) => (e && e.value ? setPrioirtyLevel(e.value) : setPrioirtyLevel(''))}
                      />
                      { evp || vp || priorityLevel ? (
                        <div>
                          <p className="summary-header">{t('activities')}</p>
                          {(filteredCriticalActivities.length > 0)
                            ? filteredCriticalActivities.map((act, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                              <div key={`${act.team}-${act.Activity}-${index}`}>
                                <ReportLabel label={`${index + 1}`} content={act.Activity} />
                                <ReportLabel extendedClass="left" label={t('team')} content={act.team} />
                                <ReportLabel
                                  extendedClass="left"
                                  label={`${t('timing')}`}
                                  content={
                              displayPriorityTiming(act.priorityLevel) ? displayPriorityTiming(act.priorityLevel) : t('notApplicable')
                            }
                                />
                              </div>
                            )) : <p className="larger-font" name="activityName">{t('noCaToShow')}</p>}

                          <p className="summary-header">{t('staffing')}</p>
                          {priorityLevel ? (
                            <div className="ca-staff-table">
                              <table>
                                <thead>
                                  <tr>
                                    <th colSpan="1" className="staff-table-header">
                                      <strong>{t('tableHead')}</strong>
                                    </th>
                                    <th colSpan="12" className="staff-table-header">
                                      <em>{t('tableNote')}</em>
                                    </th>
                                  </tr>
                                  <tr className="month-row">
                                    {['', ...months].map((month) => <th className="month" key={`${month}-`}>{month}</th>)}
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                            /**
                             * Get unique teams
                             * for each team find the
                             * staff table and display it
                             * */
                            uniqueBusinessTeams.map((team) => {
                              let table = staffingTable
                                .find((staffTable) => staffTable.team === team);
                              /** teams with empty tables because they are empty / dne in db */
                              table = table ? table.staffTable : emptyStaffArray;
                              tablesToAdd.push(table);
                              return (
                                <tr key={`${team}-table-header`}>
                                  <td>{team}</td>
                                  {
                                    // eslint-disable-next-line react/no-array-index-key
                                    table.map((staff, index) => <td key={`${team}-${index}-table-staff`}>{staff}</td>)
                                  }
                                </tr>
                              );
                            })
                          }
                                  <tr>
                                    <td className="bold">{t('total')}</td>
                                    {
                              // eslint-disable-next-line react/no-array-index-key
                              sumArrays(tablesToAdd).map((staff, index) => <td key={`staffing-row-${index}`}>{staff}</td>)
                            }
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          ) : <p className="larger-font">{t('noCaTableToShow')}</p>}
                        </div>
                      ) : <p className="larger-font">{t('selectOneFilter')}</p>}
                    </div>
                  </div>
                </Tab>

                {/** Activation contacts tab */}
                <Tab isActive={toggleTab === t('activationContacts')}>
                  { validateEvps?.length >= 1 && validateVps?.length >= 1
                    ? (
                      <div ref={reportTemplateRefActivation}>
                        <div className="content-overview">
                          {showFileButton && (
                          <>
                            <button
                              type="button"
                              aria-label={t('exportPDF')}
                              className="no-style"
                              disabled={isDownloading}
                              onClick={() => {
                                handlePrint(null, () => reportTemplateRefActivation.current);
                              }}
                            >
                              <FontAwesomeIcon data-tip data-for="downloadTip" icon={faFilePdf} className="pdf-icon" />
                            </button>
                            <ReactTooltip id="downloadTip" place="left" type="light" effect="solid" border>
                              <div>{t('exportPDF')}</div>
                            </ReactTooltip>
                          </>
                          )}
                        </div>
                        <div className="content-filters">
                          { validateEvps
                          && (
                            <ReportLabel
                              val={validateEvps.find((exec) => exec?.value?.toString() === evp)
                                || getInitialPlaceholder()}
                              options={validateEvps}
                              label={`${t('evp')}:`}
                              content={validateEvps.length === 1 ? validateEvps[0].label : t('selectEVP')}
                              isSelect
                              onChange={(e) => (e && e.value ? setEVP(e.value) : setEVP(''))}
                            />
                          )}

                          { validateVps
                            && (
                            <ReportLabel
                              val={validateVps
                                .find((vicePres) => vicePres?.value?.toString() === vp)
                                || getInitialPlaceholder()}
                              options={validateVps}
                              label={`${t('vp')}:`}
                              content={validateVps.length === 1 ? validateVps[0].label : t('selectVP')}
                              isSelect
                              onChange={(e) => (e && e.value ? setVP(e.value) : setVP(''))}
                            />
                            )}
                        </div>
                        <div className="date-and-time-section">
                          <ReportLabel
                            label={`${t('date')}`}
                            content={today.toLocaleDateString()}
                          />
                          <ReportLabel
                            label={`${t('time')}`}
                            content={today.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                          />
                        </div>

                        {/** AVP */}
                        {vp || evp ? (
                          <>
                            <div className="summary-content margin-bottom">
                              {users.filter((user) => user.role
                                .some((role) => role.name === Role.AssociateVicePresident))
                                .sort((a, b) => sortAlphabetically(a, b, true))
                                .map((associate) => {
                                  const filteredTeamsForVpEvp = businessTeamsForVpEvp
                                    .filter((team) => team.avps
                                      .some((associateVP) => associateVP._id === associate._id));

                                  return (
                                    filteredTeamsForVpEvp.length >= 1 && (
                                    <div key={associate._id} className="avp-section">
                                      <ReportLabel
                                        label={`${t('avp')} :`}
                                        content={`${associate.firstName} ${associate.lastName}`}
                                      />
                                      {associate.workPhone && formatPhoneNumber(associate.workPhone, 'workPhone', associate.extension, `${associate._id}-avp-workPhone`)}
                                      {associate.mainPhone.number
                                && formatPhoneNumber(associate.mainPhone.number, 'mainPhone', null, `${associate._id}-avp-mainPhone`)}
                                      <table className="avp-table">
                                        <thead>
                                          <tr className="table-header">
                                            <th>{t('businessTeam')}</th>
                                            <th>{t('officeLocation')}</th>
                                            <th>{t('bcpLead')}</th>
                                            <th>{t('contactNumber')}</th>
                                            <th>{t('bcpAlternate')}</th>
                                            <th>{t('contactNumber')}</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {filteredTeamsForVpEvp.map((team) => (
                                            <tr key={`${associate._id}-${team.team}`}>
                                              <td>{team.team}</td>
                                              <td>
                                                {team.locations.map((loc) => <p key={`btLoc=${loc.location}-${loc.lead._id}`}>{loc.location}</p>)}
                                              </td>
                                              <td>
                                                {team.locations.map((loc, index) => (
                                                  /* eslint-disable-next-line max-len */
                                                  /* eslint-disable-next-line react/no-array-index-key */
                                                  <p key={`lead-${loc.location}-${loc.lead_id}-${index}`}>
                                                    {loc.lead._id
                                                      ? `${loc.lead.firstName} ${loc.lead.lastName}` : loc.lead }
                                                  </p>
                                                ))}
                                              </td>
                                              <td>
                                                {team.locations.map((loc) => {
                                                  if (loc.lead.workPhone) {
                                                    const key = `lead-${loc.lead._id}-workPhone-${team.team}-${loc.location}`;
                                                    return formatPhoneNumber(loc.lead.workPhone,
                                                      null, loc.lead.extension, key);
                                                  } if (loc.lead.mainPhone) {
                                                    const key = `lead-${loc.lead._id}-mainPhone-${team.team}-${loc.location}`;
                                                    return formatPhoneNumber(loc.lead.mainPhone,
                                                      null, null, key);
                                                  }
                                                  return <p key={`lead-${loc.lead._id}-noPhone-${team.team}-${loc.location}`}>{t('noPhoneRegistered')}</p>;
                                                })}
                                              </td>
                                              <td>
                                                {team.locations.map((loc, index) => (
                                                  /* eslint-disable-next-line max-len */
                                                  /* eslint-disable-next-line react/no-array-index-key */
                                                  <p key={`alt-${loc.location}-${loc.lead_id}-${index}`}>
                                                    {loc.alt._id
                                                      ? `${loc.alt.firstName} ${loc.alt.lastName}` : loc.alt }
                                                  </p>
                                                ))}
                                              </td>
                                              <td>
                                                {team.locations.map((loc) => {
                                                  if (loc.alt.workPhone) {
                                                    const key = `alt-${loc.lead._id}-workPhone-${team.team}-${loc.location}`;
                                                    return formatPhoneNumber(loc.alt.workPhone,
                                                      null, loc.alt.extension, key);
                                                  } if (loc.alt.mainPhone) {
                                                    const key = `alt-${loc.lead._id}-mainPhone-${team.team}-${loc.location}`;
                                                    return formatPhoneNumber(loc.alt.mainPhone,
                                                      null, null, key);
                                                  }
                                                  return <p key={`alt-${loc.alt._id}-noPhone-${team.team}-${loc.location}`}>{t('noPhoneRegistered')}</p>;
                                                })}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                    )
                                  );
                                })}

                            </div>
                            {/** BCC */}
                            <div className="summary-content margin-bottom">
                              <p className="bold">{t('bcm')}</p>
                              <div className="seperator">
                                <p>{t('bcc')}</p>
                              </div>
                              <table className="bcc-table">
                                <thead>
                                  <tr className="table-header">
                                    <th>{t('name')}</th>
                                    <th>{t('officeLocation')}</th>
                                    <th>{t('contactInfo')}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {toggleTab === t('activationContacts')
                            && users.filter((user) => user.role
                              .some((role) => role.name === Role.BusinessContinuityCoordinator))
                              .sort((a, b) => sortAlphabetically(a, b, true))
                              .map((user) => {
                                const userHasLocation = uniqueBusinessTeamsForVpEvp
                                  .some((btLoc) => user.role.some((r) => r.locations
                                    .some((loc) => loc.location === btLoc)));
                                if (userHasLocation) {
                                  return (
                                    <tr key={`bcc-table-${user._id}`}>
                                      <td>{`${user.firstName} ${user.lastName}`}</td>
                                      <td>
                                        {uniqueBusinessTeamsForVpEvp.filter((loc) => user.role
                                          .some((role) => role.locations
                                            .some((roleLoc) => roleLoc.location === loc)))
                                          /* eslint-disable-next-line react/no-array-index-key */
                                          .map((loc, index) => <p key={`bcc-table-${loc.location}-${user._id}-${index}`}>{loc}</p>)}
                                      </td>
                                      <td>
                                        {
                                        /* eslint-disable-next-line no-nested-ternary */
                                        user.workPhone ? formatPhoneNumber(user.workPhone,
                                          null, user.extension)
                                          : (
                                            user.mainPhone.number
                                              ? formatPhoneNumber(user.mainPhone.number, null)
                                              : t('noPhoneRegistered')
                                          )
                                      }
                                      </td>
                                    </tr>
                                  );
                                }
                                return null;
                              })}
                                </tbody>
                              </table>

                              {/** ERM */}
                              <div className="seperator">
                                <p>{t('CGL')}</p>
                              </div>
                              <table className="erm-table">
                                <thead>
                                  <tr className="table-header">
                                    <th>{t('name')}</th>
                                    <th>{t('contactInfo')}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {toggleTab === t('activationContacts') && users.filter((user) => user?.role?.some((role) => role.name === Role.CGLBusinessContinuityCoordinator))
                                    .sort((a, b) => sortAlphabetically(a, b, true)).map((user) => (
                                      <tr key={`erm-${user._id}`}>
                                        <td>{`${user.firstName} ${user.lastName}`}</td>

                                        <td>
                                          {
                                      /* eslint-disable-next-line no-nested-ternary */
                                      user.workPhone ? formatPhoneNumber(user.workPhone,
                                        null, user.extension) : (
                                        user.mainPhone.number ? formatPhoneNumber(user.mainPhone.number, null) : t('noPhoneRegistered')
                                      )
                                    }
                                        </td>
                                      </tr>
                                    ))}

                                </tbody>
                              </table>
                            </div>
                          </>
                        ) : <p className="larger-font">{t('selectOneFilter')}</p>}
                      </div>
                    ) : <p className="larger-font">{t('noAccessForEvpVp')}</p> }
                </Tab>

                {/** Main Tab */}
                <Tab isActive={toggleTab === t('main')}>
                  <div ref={reportTemplateRefMain}>
                    <div className="content-overview">
                      {showFileButton && (
                      <>
                        <button
                          type="button"
                          aria-label={t('exportPDF')}
                          className="no-style"
                          disabled={isDownloading}
                          onClick={() => {
                            handlePrint(null, () => reportTemplateRefMain.current);
                          }}
                        >
                          <FontAwesomeIcon data-tip data-for="downloadTip" icon={faFilePdf} className="pdf-icon" />
                        </button>
                        <ReactTooltip id="downloadTip" place="left" type="light" effect="solid" border>
                          <div>{t('exportPDF')}</div>
                        </ReactTooltip>
                      </>
                      )}
                    </div>

                    <div className="three-column-grid">
                      {/** EVP Select */}
                      { evps && (
                      <ReportLabel
                        val={evps.find((exec) => exec?.value?.toString() === evp)
                          || getInitialPlaceholder()}
                        extendedClass="top-level-filter"
                        options={evps}
                        label={`${t('evp')}:`}
                        content={evps.length === 1 ? evps[0].label : t('selectEVP')}
                        isSelect
                        onChange={(e) => (e && e.value ? setEVP(e.value) : setEVP(''))}
                      />
                      )}

                      {/** VP Select */}
                      { vps && (
                      <ReportLabel
                        val={vps.find((vicePres) => vicePres?.value?.toString() === vp)
                        || getInitialPlaceholder()}
                        extendedClass="top-level-filter"
                        options={vps}
                        label={`${t('vp')}:`}
                        content={vps.length === 1 ? vps[0].label : t('selectVP')}
                        isSelect
                        onChange={(e) => (e && e.value ? setVP(e.value) : setVP(''))}
                      />
                      )}

                      {/** AVP Select */}
                      {
                      avps
                      && (
                      <ReportLabel
                        val={avps.find((assoc) => assoc?.value?.toString() === avp)
                          || getInitialPlaceholder()}
                        extendedClass="top-level-filter"
                        options={avps}
                        label={`${t('avp')}:`}
                        content={avps.length === 1 ? avps[0].label : t('selectAVP')}
                        isSelect
                        onChange={(e) => (e && e.value ? setAVP(e.value) : setAVP(''))}
                      />
                      )
                    }

                      {/** Location Select */}
                      { locations && (
                      <ReportLabel
                        val={locations.find((loc) => loc.value === selectedLocation)
                        || getInitialPlaceholder()}
                        extendedClass="top-level-filter"
                        options={locations}
                        label={`${t('location')}:`}
                        content={locations.length === 1 ? locations[0].label : t('selectLocation')}
                        isSelect
                        onChange={(e) => (e && e.value ? setSelectedLocation(e.value) : setSelectedLocation(''))}
                      />
                      )}
                    </div>
                    <div className="date-and-time-section">
                      <ReportLabel
                        label={`${t('date')}`}
                        content={today.toLocaleDateString()}
                      />
                      <ReportLabel
                        label={`${t('time')}`}
                        content={today.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                      />
                    </div>
                    <div className={border ? `summary-content ${border}-content` : 'summary-content'}>

                      {/** Prioirty level Select */}
                      <ReportLabel
                        val={priorityLevel
                          ? { label: priorityLevel, value: priorityLevel }
                          : getInitialPlaceholder()}
                        extendedClass="second-level-filter"
                        options={priorityLevels}
                        label={`${t('priorityLevel')}:`}
                        content={priorityLevels.length === 1 ? priorityLevels[0].label : t('selectPriorityLevel')}
                        isSelect
                        onChange={(e) => (e && e.value ? setPrioirtyLevel(e.value) : setPrioirtyLevel(''))}
                      />

                      {/** Business Teams Select */}
                      { businessTeams && (
                      <ReportLabel
                        val={businessTeams.find((team) => team?.value === businessTeam)
                        || getInitialPlaceholder()}
                        extendedClass="second-level-filter"
                        options={businessTeams}
                        label={`${t('businessTeam')}:`}
                        content={businessTeams.length === 1 ? businessTeams[0].label : t('selectBusinessTeam')}
                        isSelect
                        onChange={(e) => (e && e.value ? setBusinessTeam(e.value) : setBusinessTeam(''))}
                      />
                      )}
                      { chosenCriticalActivity
                      && (evp || avp || vp || businessTeam || priorityLevel || selectedLocation)
                        ? (
                          <div>
                            <div className="two-column-grid">
                              {/** Activity Select */}
                              <ReportLabel
                                val={{
                                  label: chosenCriticalActivity.Activity ? chosenCriticalActivity.Activity : t('notApplicable'),
                                  value: chosenCriticalActivity,
                                }}
                                extendedClass={activityOptions.length === 1 ? '' : 'second-level-filter'}
                                options={activityOptions}
                                label={`${t('activity')}:`}
                                content={activityOptions.length === 1 ? activityOptions[0].label : t('selectActivity')}
                                isSelect={activityOptions.length !== 1}
                                onChange={(e) => (e && e.value
                                  ? setChosenCriticalActivity(e.value)
                                  : setChosenCriticalActivity({}))}
                              />

                              <ReportLabel
                                label={t('activityType')}
                                content={chosenCriticalActivity['activity type']}
                              />
                            </div>

                            {/** Display activity data */}
                            <div>
                              <ReportLabel
                                extendedClass="multiline-content"
                                label={t('generalComments')}
                                content={chosenCriticalActivity.generalComments}
                              />

                              <div className="two-column-grid">
                                <ReportLabel
                                  label={t('timing')}
                                  content={chosenCriticalActivity.priorityLevel ? displayPriorityTiming(chosenCriticalActivity.priorityLevel) : t('notApplicable')}
                                />
                                <ReportLabel
                                  label={t('maxTolDownTime')}
                                  content={chosenCriticalActivity.maxTolerableDowntime}
                                />
                                <div className={chosenCriticalActivity?.location?.length >= 1
                                  ? 'select-or-label align-start'
                                  : 'select-or-label'}
                                >
                                  <p className="bold">{t('locations')}</p>
                                  <div className="flex-div">
                                    {chosenCriticalActivity.location?.length >= 1
                                      ? chosenCriticalActivity.location?.map((loc) => <p key={loc} className="larger-font">{loc}</p>)
                                      : t('notApplicable')}
                                  </div>
                                </div>
                                <div className="nested-two-column-grid">
                                  <ReportLabel
                                    label={t('canBeDoneFromHome')}
                                    content={chosenCriticalActivity.canThisActivityBeDoneFromHome}
                                  />
                                  <ReportLabel
                                    extendedClass="constraint-width"
                                    label={t('ifNotWhy')}
                                    content={chosenCriticalActivity["WhyCan'tThisActivityBeDoneFromHome"]}
                                  />
                                </div>
                                <ReportLabel
                                  label={`${t('staffing')}:`}
                                  content={
                                  chosenCriticalActivity?.priorityLevel ? displayPriorityStaffing(chosenCriticalActivity.priorityLevel) : t('notApplicable')
                                }
                                />
                                <ReportLabel
                                  label={t('roles')}
                                  content={chosenCriticalActivity.roles}
                                />
                              </div>
                              {
                              chosenCriticalActivity?.location?.length >= 2 && (
                              <>
                                <div className="seperator">
                                  <p>{t('otherLocations')}</p>
                                </div>
                                <div className={chosenCriticalActivity?.location?.length <= 2
                                  ? 'select-or-label'
                                  : 'select-or-label align-start'}
                                >
                                  <p className="bold">{t('locations')}</p>
                                  <div className="flex-div">
                                    {
                                    chosenCriticalActivity.location
                                      ?.filter((loc) => loc !== selectedLocation)
                                      ?.map((loc) => <p key={loc} className="larger-font">{loc}</p>)
                                  }
                                  </div>
                                </div>
                              </>
                              )
                            }

                              <div className="seperator">
                                <p>{t('ITRequirements')}</p>
                              </div>
                              <div className="two-column-grid">
                                <ReportLabel
                                  label={`${t('internal')}`}
                                  content={chosenCriticalActivity.internalRequirement
                                    ?.filter((req) => req.nameAndVersion)
                                    ?.map((req) => req.nameAndVersion).join(', ')}
                                />
                                <ReportLabel
                                  label={`${t('external')}`}
                                  content={chosenCriticalActivity.externalRequirement
                                    ?.filter((req) => req.systemSoftware)
                                    ?.map((req) => req.systemSoftware).join(', ')}
                                />
                                <ReportLabel
                                  label={`${t('actionsToMitigateLoss')}`}
                                  content={chosenCriticalActivity.possibleActionsITSystemLoss}
                                />
                              </div>

                              <div className="seperator">
                                <p>{t('internalDependencies')}</p>
                              </div>

                              <table className="interdependencies-table">
                                <thead>
                                  <tr className="table-header">
                                    <th>{t('teamName')}</th>
                                    <th>{t('supportingTask')}</th>
                                    <th>{t('workaround')}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {chosenCriticalActivity.interdependencies?.map((dependency) => (
                                    dependency.name && (
                                    <tr key={dependency.name}>
                                      <td>{dependency.name}</td>
                                      <td>{dependency.supportingActivity}</td>
                                      <td>{dependency.interdependenceWorkAround}</td>
                                    </tr>
                                    )

                                  ))}
                                </tbody>
                              </table>

                              <div className="seperator">
                                <p>{t('essentialBusinessPartners')}</p>
                              </div>

                              <table className="ebp-table">
                                <thead>
                                  <tr className="table-header">
                                    <th colSpan={3}>{t('ebpName')}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {chosenCriticalActivity.essentialBusinessPartners?.map((ebp) => (
                                    ebp.name && (
                                    <tr key={ebp.name}>
                                      <td>{ebp.name}</td>
                                      <td>
                                        <p className="bold-only">{`${t('supportingTask')}:`}</p>
                                        <p className="bold-only">{t('timeWithoutEbp')}</p>
                                        <p className="bold-only">{t('ebpWorkaround')}</p>
                                      </td>
                                      <td>
                                        <p>{ebp.whatTaskDoesThisEbpPerform}</p>
                                        <p>{ebp.howLongCanYouBeWithoutThisEbp}</p>
                                        <p>{ebp.areThereWorkAroundProcedures}</p>
                                      </td>

                                    </tr>
                                    )
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        ) : <p className="larger-font">{t('selectOneFilter')}</p>}
                    </div>
                  </div>
                </Tab>

                {/** Raw Data Tab */}
                <Tab isActive={toggleTab === t('rawData')}>
                  <div className="tab-container">
                    <button
                      type="button"
                      aria-label={t('downloadExcelFile')}
                      className="no-style"
                      disabled={isDownloading}
                      onClick={() => downloadExcel()}
                    >
                      <FontAwesomeIcon data-tip data-for="downloadTip" icon={faFileExcel} className="excel-icon" />
                    </button>
                    <ReactTooltip id="downloadTip" place="left" type="light" effect="solid" border>
                      <div>{t('downloadExcelFile')}</div>
                    </ReactTooltip>
                    <div
                      className="ca-table"
                        /* eslint-disable-next-line react/no-danger */
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(workbook),
                      }}
                    />
                  </div>
                </Tab>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

CriticalActivityReportPage.defaultProps = {
  renderSidebar: false,
  dispatch: null,
};

CriticalActivityReportPage.propTypes = {
  renderSidebar: PropTypes.bool,
  dispatch: PropTypes.func,
};

const mapStateToProps = (state) => ({
  renderSidebar: state.sidebar.renderSidebar,
});

export default connect(mapStateToProps)(CriticalActivityReportPage);
