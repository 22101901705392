import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import '../styles/components/ReviewBusinessActivity.scss';

const ReviewBusinessActivity = ({
  planId, lengthEqualOne, activity, labels, index, deleteBA,
}) => {
  const { t, i18n } = useTranslation('common');
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  function parseLabel(label = { en: '' }, locale = i18n.language) {
    return label[locale];
  }

  /**
   * Searches for correct label and language for a select value
   * @param {*} values List of values to check
   * @param {*} key Key to find in values
   * @param {*} locale The language
   * @returns Translated label for select value
   */
  function parseSelectLabel(values, key, locale = i18n.language) {
    let value;
    if (values) {
      value = values.find((item) => item.value === key);
    }
    return value ? value.label[locale] : '';
  }

  return (
    <>
      <div className="business-activity-heading-container">
        <div className="business-activity-title-container">
          <p className="business-activity-subtitle">
            {parseLabel(labels.label)}
          </p>
        </div>
        <div className="business-activity-buttons-container">
          <button
            type="button"
            id={`delete-button-${index}`}
            className="delete-button"
            onClick={() => { setShowModal(true); }}
          >
            {t('deleteBA')}
            {' '}
            {index}
          </button>
          <button
            type="button"
            id={`edit-button-${index}`}
            className="edit-ba"
            onClick={() => {
              history.replace({
                pathname: '/edit-plan',
                search: `?planId=${planId}&sectionKey=businessImpactAnalysis&startActivity=${index - 1}`,
              });
            }}
          >
            {t('editBA')}
            {' '}
            {index}
          </button>
        </div>
      </div>
      <div className="activity-container">
        <div className="item0" />
        <div className="item1">
          {index}
          .
        </div>
        <div className="item2">
          {parseLabel(labels.children[0].label)}
          :
        </div>
        <div className="item3">{activity.Activity}</div>

        <div className="item5">{parseLabel(labels.children[2].label)}</div>
        <div className="item8-type">{activity['activity type'] || ''}</div>

        <div className="item2-subtasks">
          {parseLabel(labels.children[1].label)}
          :
        </div>
        <div className="item3-subtasks">{activity.subtasks}</div>

        <div className="item10">{parseLabel(labels.children[5].PDFLabel)}</div>
        <div className="itemDT">
          {parseLabel(labels.children[6].label)}
          {' '}
          <p className="subheader">{t('activityTimeFrame')}</p>
        </div>

        <div className="item11">{parseLabel(labels.children[5].children[0].PDFLabel)}</div>

        <div className="item11-StaffAndTime">{parseLabel(labels.children[5].children[1].PDFLabel)}</div>
        <div className="item11-StaffAndTime">{parseLabel(labels.children[5].children[2].label)}</div>

        {activity.priorityLevel.map((item, num) => {
          if (item.priorityLevel) {
            return (
              <React.Fragment key={item.priorityLevel}>
                <div className="item8-type">{item.priorityLevel}</div>
                <div className="item15-data">
                  {
                    Array.isArray(item.priorityLevelTiming)
                    && item.priorityLevelTiming.map((timing) => (
                      `${parseSelectLabel(labels.children[5].children[1].values, timing)}`
                    )).join('; ')
                  }
                </div>
                <div className="item15-data">{item.totalStaffRequired}</div>
                {num === 0
                   && <div className="item8-type" style={{ gridRow: `span ${activity.priorityLevel.length - 1}` }}>{activity.maxTolerableDowntime}</div> }
              </React.Fragment>
            );
          }
          return (null);
        })}

        <div className="item5-role">{parseLabel(labels.children[3].label)}</div>
        <div className="item8">
          {activity.roles && activity.roles.split(';').filter((role) => role).map((item) => (
            item.trim()
          )).join(', ')}
        </div>

        <div className="item5-location">{parseLabel(labels.children[4].label)}</div>
        {
          activity.location && (
          <div className="location">
            {activity.location.join('; ')}
          </div>
          )
        }

        <div className="item6">{parseLabel(labels.children[7].label)}</div>

        <div className="item7">{`${parseLabel(labels.children[7].values[0].label)}/${parseLabel(labels.children[7].values[1].label)}`}</div>
        <div className="item7-if">{t('toolsRequired')}</div>

        <div className="item9">{activity.canThisActivityBeDoneFromHome || ''}</div>
        {
          activity.canThisActivityBeDoneFromHome === 'Yes'
            ? (<div className="item9-if">{activity.ListSpecialEquipAndTools.ListSpecialEquipAndTools}</div>)
            : (<div className="item9-if">{activity['WhyCan\'tThisActivityBeDoneFromHome']}</div>)
        }

        <div className="item6">{parseLabel(labels.children[8].label)}</div>

        <div className="item12">{parseLabel(labels.children[9].PDFLabel)}</div>
        <div className="item13">
          {
            activity.internalRequirement
              .map((iR) => iR.nameAndVersion)
              .filter((iR) => iR)
              .join(', ')
          }
        </div>

        <div className="item12">{parseLabel(labels.children[10].PDFLabel)}</div>
        <div className="item13">
          {
            activity.externalRequirement
              .map((eR) => eR.systemSoftware)
              .filter((eR) => eR)
              .join(', ')
          }
        </div>

        <div className="item12">{parseLabel(labels.children[11].PDFLabel)}</div>
        <div className="item13">{activity.possibleActionsITSystemLoss}</div>

        <div className="item6">{parseLabel(labels.children[12].label)}</div>

        <div className="item11">{t('supportProvided')}</div>
        <div className="item15">{t('businessTeamName')}</div>
        <div className="item15">{t('teamLeadAndAlt')}</div>
        <div className="item15">{t('contactName')}</div>
        <div className="item15">{t('workAroundsInternalDeps')}</div>

        {activity.interdependencies.map((item) => {
          if (item.name) {
            return (
              <React.Fragment key={item.name}>
                <div className="item11-data">{item.supportingActivity}</div>
                <div className="item15-data">{item.name}</div>
                <div className="item15-data">{(item.teamLead && item.teamAlternate) ? (`${item.teamLead}, ${item.teamAlternate}`) : (item.teamLead || item.teamAlternate || '')}</div>
                <div className="item15-data">{item.contactName}</div>
                <div className="item15-data">{item.interdependenceWorkAround}</div>
              </React.Fragment>
            );
          }
          return (null);
        })}

        <div className="item6">{parseLabel(labels.children[13].label)}</div>

        <div className="item11">{t('companyName')}</div>
        <div className="item15">{t('supportProvided')}</div>
        <div className="item15">{t('daysWithoutEBP')}</div>
        <div className="item11">{t('workAroundsEBP')}</div>

        {activity.essentialBusinessPartners.map((item) => {
          if (item.name) {
            return (
              <React.Fragment key={item.name}>
                <div className="item11-data">{item.name}</div>
                <div className="item15-data">{item.whatTaskDoesThisEbpPerform}</div>
                <div className="item15-data">{item.howLongCanYouBeWithoutThisEbp}</div>
                <div className="item11-data">{item.areThereWorkAroundProcedures}</div>
              </React.Fragment>
            );
          }
          return (null);
        })}

        <div className="item6">
          {parseLabel(labels.children[14].label)}
          :
        </div>

        <div className="item14">{activity.generalComments}</div>

        <div className="item6">{t('possibleIssues')}</div>

        <div className="item14">{activity.possibleIssues}</div>

      </div>
      <ConfirmDeleteModal
        index={index - 1}
        deleteBA={deleteBA}
        activityName={activity.Activity}
        isOpen={showModal}
        handleClose={() => { setShowModal(false); }}
        lengthEqualOne={lengthEqualOne}
      />
    </>
  );
};

ReviewBusinessActivity.propTypes = {
  planId: PropTypes.string.isRequired,
  lengthEqualOne: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  deleteBA: PropTypes.func.isRequired,
  labels: PropTypes.shape({
    label: PropTypes.shape({
      en: PropTypes.string,
    }),
    children: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.shape({
        en: PropTypes.string,
      }),
      PDFLabel: PropTypes.shape({
        en: PropTypes.string,
      }),
      itemLabel: PropTypes.shape({
        en: PropTypes.string,
      }),
      values: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.shape({
          en: PropTypes.string,
        }),
      })),
      children: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.shape({
          en: PropTypes.string,
        }),
        PDFLabel: PropTypes.shape({
          en: PropTypes.string,
        }),
        values: PropTypes.arrayOf(PropTypes.shape({
          label: PropTypes.shape({
            en: PropTypes.string,
          }),
        })),
      })),
    })),
  }).isRequired,
  activity: PropTypes.shape({
    Activity: PropTypes.string,
    'activity type': PropTypes.string,
    location: PropTypes.arrayOf(PropTypes.string),
    canThisActivityBeDoneFromHome: PropTypes.string,
    maxTolerableDowntime: PropTypes.string,
    essentialBusinessPartners: PropTypes.arrayOf(PropTypes.shape({
      areThereWorkAroundProcedures: PropTypes.string,
      howLongCanYouBeWithoutThisEbp: PropTypes.string,
      name: PropTypes.string,
      whatTaskDoesThisEbpPerform: PropTypes.string,
    })),
    externalRequirement: PropTypes.arrayOf(PropTypes.shape({
      briefDescriptionOfUse: PropTypes.string,
      systemSoftware: PropTypes.string,
    })),
    generalComments: PropTypes.string,
    interdependencies: PropTypes.arrayOf(PropTypes.shape({
      interdependenceWorkAround: PropTypes.string,
      name: PropTypes.string,
      contactName: PropTypes.string,
      supportingActivity: PropTypes.string,
      teamAlternate: PropTypes.string,
      teamLead: PropTypes.string,
    })),
    internalRequirement: PropTypes.arrayOf(PropTypes.shape({
      briefDescriptionOfUse: PropTypes.string,
      manufacturer: PropTypes.string,
      nameAndVersion: PropTypes.string,
    })),
    possibleActionsITSystemLoss: PropTypes.string,
    possibleIssues: PropTypes.string,
    priorityLevel: PropTypes.arrayOf(PropTypes.shape({
      priorityLevel: PropTypes.string,
      priorityLevelTiming: PropTypes.arrayOf(PropTypes.string),
      totalStaffRequired: PropTypes.string,
    })),
    subtasks: PropTypes.string,
    'WhyCan\'tThisActivityBeDoneFromHome': PropTypes.string,
    roles: PropTypes.string,
    textInputRoles: PropTypes.string,
    ListSpecialEquipAndTools: PropTypes.shape({
      ListSpecialEquipAndTools: PropTypes.string,
    }),
  }).isRequired,
};

export default ReviewBusinessActivity;
