import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axios from '../services/Api';
import PlanContext from '../context/PlanContext';
import ErrorModal from './ErrorModal';
import SaveErrorModal from './SaveErrorModal';

/**
 * A component comprising of a save button that is used to save data from the redux store
 * to the database.
 * @param {*} props The props going into the component
 * @param {string[]} props.reduxSelectors An array of selectors used to indicate which fields
 * from redux should be sent to the database.
 * @param {string} props.plandId The planId used to indicate where the data will be saved.
 * Used to determine the route to which the data will be sent.
 * @param {func} onConfirm The function passed by react-navigation-prompt to be called when
 * the user saves via modal.
*/
const SaveButton = ({
  resetSaveButtons,
  setOpenWarningModal,
  currentBusinessActivity,
  checkForMissingFields,
  section,
  reduxSelectors,
  planId,
  onConfirm,
  onCancel,
  newOrClonedBA,
  setNewOrClonedBA,
  action,
  saveDisabled,
  setSaveDisabled,
  reviewPlan,
  buttonLabel,
}) => {
  const { t } = useTranslation('common');
  const body = {};
  reduxSelectors.forEach((selector) => {
    const data = useSelector((state) => state[selector] || state.planTables[selector]);
    body[selector] = data;
  });
  const updatePlan = useContext(PlanContext);
  const [openFileModal, setOpenFileModal] = useState(false);
  const [openSaveErrorModal, setOpenSaveErrorModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [saved, setSaved] = useState(false);

  /**
   * Checks for missing fields then saves the redux data
   */
  const save = async () => {
    setSaveDisabled(true);
    let value;
    if (body.values && section) {
      let values;
      let sectionChildren;
      let parentSection;
      if (section.dataKey === 'businessImpactAnalysis') {
        values = body.values.businessImpactAnalysis.businessActivities[currentBusinessActivity];
        sectionChildren = section.content[0].children;
        parentSection = { ...section.content[0] };
      } else if (section.dataKey === 'businessAsUsualActivities') {
        values = body.values.businessAsUsualActivities;
        sectionChildren = section.content;
        parentSection = section;
      } else if (section.dataKey === 'recovery') {
        values = body.values.recovery;
        sectionChildren = section.content;
        parentSection = section;
      } else if (section.dataKey === 'recoveryLocations') {
        values = body.values.recoveryLocations;
        sectionChildren = section.content;
        parentSection = section;
      } else if (section.dataKey === 'contacts') {
        values = body.values.contacts;
        sectionChildren = section.content;
        parentSection = section;
      }
      for (let i = 0; i < sectionChildren.length; i += 1) {
        if (sectionChildren[i].isRequired) {
          value = values[sectionChildren[i].dataKey];
          checkForMissingFields(value, sectionChildren[i], parentSection);
        }
      }
    }

    // Handles Staffing Table save
    if (section.dataKey === 'businessAsUsualActivities') {
      let failure = false;
      ['Urgent', 'High', 'Medium', 'Low'].forEach(async (priority) => {
        const table = body[`${priority.charAt(0).toLowerCase() + priority.slice(1)}StaffTable`];
        if (!table) return;
        const tableData = [[], []];
        let sum = 0;
        if (table) {
          for (let i = 0; i < table.length; i += 1) {
            for (let j = 0; j < table[i].length; j += 1) {
              const numVal = parseInt(table[i][j], 10);
              sum += numVal;
              if (i === 0 && numVal < 0) { // staffing validation
                setOpenWarningModal(true);
                setSaveDisabled(false);
                return;
              }

              /**
               * Commented out for future implementation
               * if (i === 1 && numVal < 0) { return null; } // in office validation
               */

              tableData[i][j] = numVal || 0;
            }
            if (action !== 'back' && i === 0 && sum <= 0) { // staffing validation
              setOpenWarningModal(true);
              setSaveDisabled(false);
              failure = true;
              return;
            }
          }
        }
      });
      // This is added in case the first row is all zeros, to not save.
      if (failure) {
        setSaveDisabled(false);
        return;
      }
    }

    const hasMissingFields = document.querySelectorAll('[id ^= "missing-"]').length > 0;
    // Using a new variable to track modal changes since state wasnt updating in time
    let isFileErrorModalOpen = false;
    if (!hasMissingFields) {
      try {
        await axios.put(`/plan/${planId}`, body);
        if (section.dataKey === 'recovery') {
          const fileSizeLimitBytes = 5000000; // 5 Million bytes = 5MB (filesize is stored as bytes)
          const uploadBatches = [];
          const batchData = {
            data: new FormData(),
            size: 0,
          };
          const fileIndexes = [];
          let containsFiles = false;
          for (let i = 0; i < body.values.recovery.procedures.length; i += 1) {
            if (body.values.recovery.procedures[i].file
              && body.values.recovery.procedures[i].file.name) {
              containsFiles = true;
              // Create batches for file upload so multiple files can be uploaded
              // without errors when the collective payload size exceeds 5 MB
              const fileSize = body.values.recovery.procedures[i].file.size;
              if (batchData.size + fileSize > fileSizeLimitBytes) {
                // Push the full batch and reset the batch data
                uploadBatches.push({ ...batchData });
                batchData.data = new FormData();
                batchData.size = 0;
              }
              batchData.data.append('names', body.values.recovery.procedures[i].procedureName);
              batchData.data.append('myFiles', body.values.recovery.procedures[i].file);
              batchData.size += fileSize;
              fileIndexes.push(i);
            }
          }
          // No more files, push the remaining batch data
          uploadBatches.push({ ...batchData });

          if (containsFiles) {
            try {
              await Promise.all(uploadBatches.map((batch) => (
                axios.put(`/plan/upload/${planId}`, batch.data, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                })
              )));
              // Avoid uploading multiple times by removing the file object after successful upload
              fileIndexes.forEach((fileIndex) => (
                delete body.values.recovery.procedures[fileIndex].file
              ));
            } catch (error) {
              isFileErrorModalOpen = true;
              setOpenFileModal(true);
            }
          }
        }
        updatePlan();
        resetSaveButtons();
        if (typeof onConfirm !== 'undefined') {
          onConfirm(); // close the modal
        }
        if (!isFileErrorModalOpen) {
          setOpenSaveErrorModal(true);
          setModalMessage(t('saveModalMessage'));
          setSaved(true);
        }
      } catch (error) {
        setOpenFileModal(true);
      }
    } else {
      onCancel();
      setOpenSaveErrorModal(true);
      setModalMessage(t('saveErrorModalMessage'));
      setSaved(false);
    }
    // Re-enable the save button after saving completes
    setSaveDisabled(false);
  };

  /**
   * Closes the File error modal and opens the success modal
   * afterwards to avoid overlap
   */
  const handleFileErrorModalClose = () => {
    setOpenFileModal(false);
    setOpenSaveErrorModal(true);
    setModalMessage(t('saveModalMessage'));
    setSaved(true);
  };

  return (
    <>
      <button
        id={buttonLabel === t('review') ? 'review-button' : 'save-button'}
        className="saveButton"
        type="button"
        onClick={save}
        disabled={saveDisabled}
      >
        {buttonLabel || t('save')}
      </button>
      <ErrorModal
        isOpen={openFileModal}
        handleClose={() => handleFileErrorModalClose()}
      />
      <SaveErrorModal
        isOpen={openSaveErrorModal}
        handleClose={() => {
          setOpenSaveErrorModal(false);
          const missing = document.querySelectorAll('[id ^= "missing-"]');
          if (missing.length > 0) {
            setTimeout(() => document.getElementById(`${missing[0].id}`)
              .scrollIntoView({ behavior: 'smooth', block: 'start' }), 200);
          } else if (newOrClonedBA) {
            setTimeout(() => window.scrollTo({
              top: 0,
              behavior: 'smooth',
            }));
            setNewOrClonedBA(false);
          }
          if (missing.length === 0 && reviewPlan) {
            reviewPlan();
          }
        }}
        message={modalMessage}
        saved={saved}
      />
    </>
  );
};

SaveButton.defaultProps = {
  setOpenWarningModal: () => {},
  currentBusinessActivity: 0,
  onConfirm: () => {},
  onCancel: () => {},
  newOrClonedBA: false,
  setNewOrClonedBA: () => {},
  action: null,
  saveDisabled: false,
  setSaveDisabled: () => { },
  reviewPlan: () => { },
  buttonLabel: '',
};

SaveButton.propTypes = {
  resetSaveButtons: PropTypes.func.isRequired,
  setOpenWarningModal: PropTypes.func,
  currentBusinessActivity: PropTypes.number,
  checkForMissingFields: PropTypes.func.isRequired,
  section: PropTypes.shape({
    label: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    content: PropTypes.arrayOf(PropTypes.shape({
      dataKey: PropTypes.string,
      children: PropTypes.arrayOf(PropTypes.shape({
        isRequired: PropTypes.bool,
        dataKey: PropTypes.string,
      })),
    })),
    dataKey: PropTypes.string,
  }).isRequired,
  reduxSelectors: PropTypes.arrayOf(PropTypes.string).isRequired,
  planId: PropTypes.string.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  newOrClonedBA: PropTypes.bool,
  setNewOrClonedBA: PropTypes.func,
  action: PropTypes.string,
  saveDisabled: PropTypes.bool,
  setSaveDisabled: PropTypes.func,
  buttonLabel: PropTypes.string,
  reviewPlan: PropTypes.func,
};

export default SaveButton;
