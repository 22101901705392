import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Footer } from 'kdc-component-library';
import { useTranslation } from 'react-i18next';
import Navbar from './Navbar';
import BcpLogo from '../assets/images/COR1010_BCM_Logo.png';
import BcpLogoFN from '../assets/images/COR1010F_BCM_Logo-01.png';
import renderSidebarAction from '../store/actions/renderSidebarAction';
import '../styles/components/PageContainer.scss';

/**
 * PageContainer component class
 * Includes Header, Footer, and anything else that may be consistent across the app
 */
export const PageContainer = (props) => {
  const {
    children,
    navItems,
    updateSidebarRender,
  } = props;

  const { i18n } = useTranslation();

  const renderSidebar = () => {
    updateSidebarRender();
  };

  return (
    <div className="page-container">
      {/* showSidebar ? <Sidebar closeSidebar={renderSideBar} items={sidebarItems} /> : <></> */}
      <div className="navbar">
        <Navbar
          items={navItems}
          logo={i18n.language.includes('fr') ? BcpLogoFN : BcpLogo}
          onMenuClick={() => renderSidebar()}
        />
      </div>
      <div className="page-content">{children}</div>
      <div className="footer">
        <Footer namespace="common" />
      </div>
    </div>
  );
};

PageContainer.defaultProps = {
  children: null,
  updateSidebarRender: null,
};

PageContainer.propTypes = {
  children: PropTypes.node,
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  updateSidebarRender: PropTypes.func,
};

export default connect(null, { updateSidebarRender: renderSidebarAction })(PageContainer);
