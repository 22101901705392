/**
 * Reducer for getting plans
 * @param {object} state The previous state value
 * @param {object} action The redux action
 * @return {string} The new state
 */
export default (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_PLAN_VALUES':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
