import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Modal from 'react-modal';
import { useMsal } from '@azure/msal-react';

/**
 * Creates the Utility page
 * @returns {*} below JSX
 */
const UnauthorizedPage = () => {
  const { t } = useTranslation('common');
  const { instance } = useMsal();

  const customStyles = {
    overlay: {
      zIndex: '9999',
    },
    content: {
      margin: 'auto',
      inset: 'unset',
      width: '40%',
      height: '25%',
      padding: '4rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      zIndex: '99',
      overflow: 'visible',
    },
  };

  return (
    <Modal isOpen style={customStyles} ariaHideApp={false}>
      <Trans i18nKey="unauthorizedError">
        Text
        <a style={{ color: 'blue' }} href="https://cooperators.atlassian.net/servicedesk/customer/portal/12">{t('kdcSupport')}</a>
      </Trans>
      <button
        type="button"
        onClick={() => instance.logoutRedirect({
          postLogoutRedirectUri: window.AZURE_REDIRECT_URL,
        })}
        id="logoutButton"
      >
        {t('logout')}
      </button>
    </Modal>
  );
};

export default UnauthorizedPage;
