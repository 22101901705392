import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PlanBaseInput from './PlanBaseInput';

import '../styles/components/PlanTextAreaInput.scss';

/**
 * PlanTextAreaInput functional component
 * Renders a textarea input element
 * @param {*} props component props
 * @returns {JSX} component JSX
 */
export const PlanTextAreaInput = (props) => {
  const {
    inputProperties,
    parent,
    value,
    onChange,
  } = props;

  const { i18n } = useTranslation();
  const { language } = i18n;

  // Determine placeholder text to display before user selects item
  const placeholder = (inputProperties.placeholder ? inputProperties.placeholder[language] : '');

  const inputElement = (
    <textarea
      id={`${parent.join('-')}`}
      className="plan-textarea-input"
      type="textarea"
      disabled={inputProperties.isDisabled}
      value={value || ''}
      placeholder={placeholder}
      onChange={(e) => onChange(parent, e.target.value, inputProperties)}
    />
  );

  return (
    <PlanBaseInput
      parent={parent}
      inputProperties={inputProperties}
      inputElement={inputElement}
      onChange={onChange}
      value={value}
    />
  );
};

PlanTextAreaInput.defaultProps = {
  value: null,
};

PlanTextAreaInput.propTypes = {
  inputProperties: PropTypes.shape({
    placeholder: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    allowUserDefined: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.shape({})),
    dataKey: PropTypes.string,
    dataSource: PropTypes.shape({
      displayKey: PropTypes.string,
    }),
    description: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    isDisabled: PropTypes.bool,
    isOptional: PropTypes.bool,
    isRepeatable: PropTypes.bool,
    label: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    type: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
    ),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  parent: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({})]),
};

export default PlanTextAreaInput;
