import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Logo } from 'kdc-component-library';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useMsal } from '@azure/msal-react';
import Sidebar from '../Sidebar';
import ReportsHintModal from '../ReportsHintModal';
import ReportsSelect from '../ReportsSelect';
import axios from '../../services/Api';
import Role from '../../enums/Role';
import '../../styles/components/pages/ReportPage.scss';

export const ReportPage = (props) => {
  const [isLoading, setLoading] = useState(true);
  const [reportSidebar, setReportSidebar] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(null);
  const [pointerOnHint, setPointerOnHint] = useState(false);
  const [openHint, setOpenHint] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [userRoles, setUserRoles] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [management, setManagement] = useState({
    evps: [],
    vps: [],
    avps: [],
    teams: [],
    teamData: [],
  });
  const [evpOptions, setEvpOptions] = useState([]);
  const [vpOptions, setVpOptions] = useState([]);
  const [avpOptions, setAvpOptions] = useState([]);
  const [businessTeamOptions, setBusinessTeamOptions] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedEvps, setSelectedEvps] = useState([]);
  const [selectedVps, setSelectedVps] = useState([]);
  const [selectedAvps, setSelectedAvps] = useState([]);
  const [selectedBusinessTeam, setSelectedBusinessTeam] = useState([]);
  const [staffLocations, setStaffLocations] = useState([]);
  const [downloadingReport, setDownloadingReport] = useState(false);
  const { renderSidebar, dispatch } = props; // redux state
  const { t } = useTranslation('common');
  const history = useHistory();
  const { instance } = useMsal();

  const buttonLabels = [
    'staffContactInfo',
    'criticalActivities',
    'essentialBusinessPartners',
    'internalDependencies',
    'ITRequirements',
    'planMaintenance',
    'staffingRequirementsReports',
  ];

  const dropdownLabels = ['location', 'evp', 'vp', 'avp', 'businessTeam'];

  const rolesWithAccess = [
    Role.AssociateVicePresident,
    Role.BusinessContinuityCoordinator,
    Role.BusinessTeam,
    Role.Executive,
    Role.OfficeLead,
    Role.VicePresident,
    Role.CGLBusinessContinuityCoordinator,
  ];

  const sidebarClose = () => {
    dispatch({ type: 'SIDEBAR' });
  };

  const items = [
    {
      id: t('selectMyPlan'),
      onClick: () => {
        history.push('/');
        sidebarClose();
      },
      label: t('selectMyPlan'),
      className: 'top-item',
    },
    {
      id: t('runAReport'),
      onClick: () => {
        history.push('/report');
        sidebarClose();
      },
      label: t('runAReport'),
      className: 'top-item',
    },
    {
      id: t('support'),
      onClick: () => {
        history.push('/support');
        sidebarClose();
      },
      label: t('support'),
      className: 'landing-item',
    },
    {
      id: 'Home',
      label: t('home'),
      right: true,
      url: '/',
      className: 'landing-item',
    },
    {
      id: 'Logout',
      label: t('logout'),
      right: true,
      onClick: () => instance.logoutRedirect({ postLogoutRedirectUri: window.AZURE_REDIRECT_URL }),
      className: 'landing-item',
    },
  ];

  // Runs on first render
  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        const { data: userData } = await axios.get('/user');

        const { data: authData } = await axios.get(`/auth/report-teams/${userData._id}`);

        // Load all report filter data
        const [
          { data: locationsList },
          { data: staffLocationList },
          { data: filters },
        ] = authData
          ? await Promise.all([
            axios.get('/report/locations'),
            axios.get('/plan/staffLocations'),
            axios.get('/report/filters'),
          ])
          : [
            { data: [] },
            { data: [] },
            {
              data: {
                evps: [],
                avps: [],
                vps: [],
                teams: [],
                teamData: [],
              },
            },
            { data: [] },
          ];
        const allLocationOptions = [];
        locationsList.forEach((item) => {
          allLocationOptions.push({
            value: item,
            label: item,
          });
        });
        const allStaffLocationOptions = [];
        staffLocationList.forEach((item) => {
          allStaffLocationOptions.push({
            value: item,
            label: item,
          });
        });
        if (isMounted) {
          setUserRoles(userData.role);
          // Store base set of filter data
          setManagement({
            evps: filters.evps,
            vps: filters.vps,
            avps: filters.avps,
            teams: filters.teams,
            teamData: filters.teamData,
          });
          setLocationOptions(allLocationOptions);
          setStaffLocations(allStaffLocationOptions);
          setSelectedLocations(allLocationOptions);
          setBusinessTeamOptions(filters.teams);
          setSelectedBusinessTeam(filters.teams);
          setEvpOptions(filters.evps);
          setAvpOptions(filters.avps);
          setVpOptions(filters.vps);
        }
      } catch (err) {
      /** Blank on purpose */
      } finally {
        setLoading(false);
      }
    };
    setReportSidebar(true);
    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  /**
   * Checks if a user shares a business team with another user that has been selected for reporting
   * If the user is an AVP, checks if they share a team with a VP selected for reporting
   * If the user is a VP, checks if they share a team with an EVP selected for reporting
   * @param {string} userId User id to check
   * @param {string} userType Whether the user is an AVP or VP
   * @returns {boolean} true if the user shares any team
   */
  const checkForSharedTeam = (userId, userType) => {
    let userTeams = [];
    if (userType.toLowerCase() === 'avp') {
      userTeams = management.teamData.filter(
        (team) => team.management.avps?.some((avp) => avp === userId),
      );
      return userTeams.some(
        (team) => selectedVps.filter((vp) => team.management.vps?.includes(vp._id)).length > 0,
      );
    }
    if (userType.toLowerCase() === 'vp') {
      userTeams = management.teamData.filter(
        (team) => team.management.vps?.some((vp) => vp === userId),
      );
      return userTeams.some(
        (team) => selectedEvps.filter((evp) => team.management.evps?.includes(evp._id)).length > 0,
      );
    }
    return false;
  };

  /**
   * Checks if an EVP user is on any team at a location that has been selected for reporting
   * @param {string} userId User id to check
   * @returns {boolean} true if the user is at any selected location
   */
  const checkForLocation = (userId) => {
    const userTeams = management.teamData.filter(
      (team) => team.management.evps?.some((evp) => evp === userId),
    );
    if (buttonClicked === 'staffContactInfo') {
      return userTeams.some(
        (team) => selectedLocations.filter(
          (location) => team.officeLocations.includes(location.value),
        ).length > 0,
      );
    }
    return userTeams.some(
      (team) => selectedLocations.filter(
        (location) => team.locations.includes(location.value),
      ).length > 0,
    );
  };

  /**
   * Checks if there is any selected location or user associated with a business team
   * @param {string} team Team to check
   * @returns {boolean} true if any selected EVP, VP, AVP or location is associated with the team
   */
  const checkForManagementAndLocation = (team) => {
    const teamData = management.teamData.find((data) => data.team === team);
    if (teamData) {
      const locationsToCheck = buttonClicked === 'staffContactInfo' ? teamData.officeLocations : teamData.locations;
      return (
        selectedLocations.some((location) => locationsToCheck?.includes(location.value))
        && (
          selectedEvps.some((evp) => teamData.management.evps?.includes(evp._id))
          || selectedVps.some((vp) => teamData.management.vps?.includes(vp._id))
          || selectedAvps.some((avp) => teamData.management.avps?.includes(avp._id))
        )
      );
    }
    return false;
  };

  // Runs whens selectedLocations changes or on reload
  useEffect(
    () => {
      const filteredEvps = management.evps.filter(
        (evp) => checkForLocation(evp._id),
      );
      setEvpOptions(filteredEvps);
      setSelectedEvps(filteredEvps);
    },
    // useEffect to run on buttonClicked as well to reset filters upon clicking a new report type
    [selectedLocations, buttonClicked],
  );

  // Runs whens selectedEVPs changes
  useEffect(
    () => {
      const filteredVps = management.vps.filter(
        (vp) => checkForSharedTeam(vp._id, 'vp'),
      );
      setVpOptions(filteredVps);
      setSelectedVps(filteredVps);
    },
    [selectedEvps],
  );

  // Runs whens selectedVPs changes
  useEffect(
    () => {
      const filteredAvps = management.avps.filter(
        (avp) => checkForSharedTeam(avp._id, 'avp'),
      );
      setAvpOptions(filteredAvps);
      setSelectedAvps(filteredAvps);
    },
    [selectedVps],
  );

  // Runs whens selectedAVPs changes
  useEffect(
    () => {
      const filteredTeams = management.teams.filter(
        (team) => checkForManagementAndLocation(team),
      );
      setBusinessTeamOptions(filteredTeams);
      setSelectedBusinessTeam(filteredTeams);
    },
    [selectedLocations, selectedEvps, selectedVps, selectedAvps],
  );

  // Runs when any filter has changed or when the user changes the report type to run
  // Re-enables the download button
  useEffect(
    () => {
      setDownloadingReport(false);
    },
    [selectedLocations, selectedEvps, selectedVps, selectedAvps, buttonClicked],
  );

  const buttonOnClick = (label) => {
    if (pointerOnHint) {
      setModalTitle(label);
      setModalText(`${label}Desc`);
      setOpenHint(true);
    } else if (buttonClicked !== label) {
      if (label === 'staffContactInfo') setSelectedLocations(staffLocations);
      else setSelectedLocations(locationOptions);

      setButtonClicked(label);
    } else {
      setButtonClicked(null);
    }
  };

  const filtersHintClick = () => {
    setModalTitle('reportFilters');
    setModalText('reportFiltersDesc');
    setOpenHint(true);
  };

  const checkDisabled = () => (
    (selectedLocations.length === 0)
    || (selectedBusinessTeam.length === 0)
  );

  const formatDate = () => {
    const today = new Date();
    const month = {
      0: 'JAN',
      1: 'FEB',
      2: 'MAR',
      3: 'APR',
      4: 'MAY',
      5: 'JUN',
      6: 'JUL',
      7: 'AUG',
      8: 'SEP',
      9: 'OCT',
      10: 'NOV',
      11: 'DEC',
    };
    return `${month[today.getMonth()]} ${String(today.getDate()).padStart(2, '0')}, ${String(today.getFullYear())}`;
  };

  const downloadExcel = async () => {
    setDownloadingReport(true);
    toast(t('reportGenerating'), {
      type: 'info',
    });

    /**
     * Adds empty select all option if user selects all
     * @param {*} array
     * @returns array with empty option
     */
    const addEmptyOption = (array) => {
      array.push(null);
      return array;
    };

    /**
     * Adds empty option to the selected options based on type of report
     * @param {*} options all options to choose from
     * @param {*} selected list of selected options
     * @param {*} filter type of filter
     * @returns array
     */
    const shouldAddEmptyOption = (options, selected, filter = '') => {
      let optionsLength = options.length;
      if (filter === 'location' && buttonClicked.toLowerCase() === 'staffcontactinfo') {
        optionsLength = staffLocations.length;
      }
      const mapOptions = filter === 'businessTeam' ? selected.map((option) => option.value || option) : selected.map((option) => option.value);

      return optionsLength === selected.length
        ? addEmptyOption(mapOptions)
        : mapOptions;
    };

    try {
      const { data: buffer } = await axios.post(`/excel-template/${buttonClicked}`, {
        filters: {
          locations: shouldAddEmptyOption(locationOptions, selectedLocations, 'location'),
          teams: shouldAddEmptyOption(businessTeamOptions, selectedBusinessTeam, 'businessTeam'),
          evps: shouldAddEmptyOption(evpOptions, selectedEvps),
          vps: shouldAddEmptyOption(vpOptions, selectedVps),
          avps: shouldAddEmptyOption(avpOptions, selectedAvps),
        },
      }, {
        responseType: 'arraybuffer',
      });
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const tag = document.createElement('a');
      tag.href = window.URL.createObjectURL(blob);
      tag.download = `BCM Report - ${t(buttonClicked)} ${formatDate()}.xlsx`;
      tag.click();
      window.URL.revokeObjectURL(tag.href);
      toast(t('reportDownloaded'), {
        type: 'success',
      });
    } catch (e) {
      toast(t('reportError'), {
        type: 'error',
      });
    } finally {
      setDownloadingReport(false);
    }
  };

  const checkAccess = (label) => {
    if (userRoles.some((item) => rolesWithAccess.includes(item.name))) {
      return true;
    } if (userRoles.find((item) => item.name === Role.IncidentManagementTeamSupport)) {
      // IMT has no access to Staff Contact Information
      if (label === buttonLabels[0]) return false;
      return true;
    } if (userRoles.find((item) => item.name === Role.InfrastructureTeam)) {
      if (
        label === buttonLabels[4]
        || label === buttonLabels[6]
      ) return true;
    } else if (userRoles.find((item) => item.name === Role.LogisticsTeam)) {
      if (label === buttonLabels[0] || label === buttonLabels[6]) return true;
    }
    return false;
  };

  const renderSelect = (label, clicked) => {
    switch (label) {
      case 'location':
        return (
          clicked === 'staffContactInfo' ? (
            <ReportsSelect
              options={staffLocations}
              setSelected={setSelectedLocations}
            />
          ) : (
            <ReportsSelect
              options={locationOptions}
              setSelected={setSelectedLocations}
            />
          )

        );
      case 'evp':
        return (
          <ReportsSelect
            options={evpOptions}
            setSelected={setSelectedEvps}
          />
        );
      case 'vp':
        return <ReportsSelect options={vpOptions} setSelected={setSelectedVps} />;
      case 'avp':
        return <ReportsSelect options={avpOptions} setSelected={setSelectedAvps} />;
      case 'businessTeam':
        return (
          <ReportsSelect
            options={businessTeamOptions}
            setSelected={setSelectedBusinessTeam}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {renderSidebar
        && reportSidebar && (
          <Sidebar
            closeSidebar={() => {
              sidebarClose();
            }}
            items={items}
          />
      )}
      <div className="container-div">
        <div className="button-div">
          <p>{t('selectReportType')}</p>
          {buttonLabels.map((label) => (
            <button
              id={`${label}Button`}
              type="button"
              key={label}
              onClick={() => (buttonLabels[1] === label ? history.push('/critical-activity') : buttonOnClick(label))}
              className={buttonClicked === label ? 'button-clicked' : 'button-not-clicked'}
            >
              {t(label)}
              <div
                className="question-mark"
                onPointerEnter={() => setPointerOnHint(true)}
                onPointerLeave={() => setPointerOnHint(false)}
              />
            </button>
          ))}
        </div>

        <div className="filters-div">
          <ToastContainer limit={4} pauseOnFocusLoss={false} />
          <div className="filters-title">
            {t('reportFilters')}
            <div
              role="button"
              tabIndex={0}
              aria-label={t('reportsFilter')}
              className="question-mark"
              onClick={filtersHintClick}
              onKeyDown={filtersHintClick}
            />
          </div>
          <div className="filters-internal-div">
            {
              isLoading
                ? (
                  <div className="loading-reports-filters">
                    <Logo type="KDCSpinningLogo" />
                  </div>
                )
                : (buttonClicked && checkAccess(buttonClicked) && (
                  dropdownLabels.map((label) => (
                    <div id="dropdown-div" className="dropdown-div" key={label}>
                      <p>{t(label)}</p>
                      {renderSelect(label, buttonClicked)}
                    </div>
                  ))
                )) || (buttonClicked && (
                  <p id="no-access-text" className="no-access-text">{t('noPermissionContact')}</p>
                ))
            }
          </div>
          <button
            type="button"
            className="filter-button"
            id="download-report-button"
            disabled={
              checkDisabled()
              || !buttonClicked
              || !checkAccess(buttonClicked)
              || downloadingReport
            }
            onClick={downloadExcel}
          >
            {checkDisabled() || !buttonClicked || !checkAccess(buttonClicked) ? t('selectFilters') : t('downloadExcelFile')}
          </button>
        </div>
      </div>
      <ReportsHintModal
        title={t(modalTitle)}
        text={t(modalText)}
        isOpen={openHint}
        handleClose={() => setOpenHint(false)}
      />
    </>
  );
};

ReportPage.propTypes = {
  renderSidebar: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  renderSidebar: state.sidebar.renderSidebar,
});

export default connect(mapStateToProps)(ReportPage);
