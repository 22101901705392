import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import '../styles/components/OldSessionModal.scss';

/**
 * OldSessionModal functional component
 * */
const OldSessionModal = ({
  isOpen,
  handleClose,
  tempPlan,
  setPlan,
  discard,
}) => {
  const customStyles = {
    overlay: {
      zIndex: '99',
      background: 'rgba(192,192,192,0.75)',
    },
    content: {
      margin: 'auto',
      marginTop: '5rem',
      height: '250px',
      width: '750px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflow: 'visible',
      zIndex: '99',
    },
  };

  const { t } = useTranslation('common');

  return (
    <Modal
      className="modal"
      isOpen={isOpen}
      handleClose={handleClose}
      style={customStyles}
      ariaHideApp={false}
    >
      <p className="recover-modal-text">{t('unsavedChanges')}</p>
      <div className="old-modal-buttons">
        <button type="button" className="recover-button" onClick={() => { setPlan(tempPlan); handleClose(); }}>
          {t('recover')}
        </button>
        <button type="button" className="cancel-recover-button" onClick={() => { discard(); handleClose(); }}>
          {t('discard')}
        </button>
      </div>
    </Modal>
  );
};

OldSessionModal.defaultProps = {
  isOpen: false,
  tempPlan: null,
};

OldSessionModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  tempPlan: PropTypes.shape({
    name: PropTypes.string,
    _id: PropTypes.string,
  }),
  setPlan: PropTypes.func.isRequired,
  discard: PropTypes.func.isRequired,
};

export default OldSessionModal;
