/** Regex expression to match against valid plan IDs */
const validId = /^[a-fA-F0-9]{24}$/;

/**
 * Verifies that an ID is in a valid format
 * @param {string} id ID to test
 * @returns {boolean} true if ID is valid
 */
const validateId = (id) => typeof id === 'string' && id.match(validId);

export default validateId;
