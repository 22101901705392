import React from 'react';
import PropTypes from 'prop-types';

import '../styles/components/SectionBanner.scss';

/**
 * A component that displays a blue banner across a page
 * @param {*} props Component props
 * @param {string} props.title Title to display on the banner
 * @param {string} props.id Element id for the banner
 * @returns component JSX
 */
const SectionBanner = ({ title, id, className }) => (
  <div className={className} id={id}>
    <div id="section-name">
      {title}
    </div>
  </div>
);

SectionBanner.defaultProps = {
  className: 'section-name-banner',
};

SectionBanner.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default SectionBanner;
