/** Email types enum */
export const EmailTypes = {
  To: 'to',
  CC: 'cc',
};

/**
 * Formats and verifies that a list of emails are valid
 * @param {string} emails Comma delimitted string of emails
 * @param {string} type Whether validations are running on a list of "to" or "cc" emails
 * @returns {Array[string]} The emails string formatted and converted to an Array
 * @returns {boolean} true if the emails are valid
 */
export const EmailValidator = (emails, type) => {
  /**
   * Performs basic email validation and returns true if a valid list of emails has been entered
   * @param {Array[string]} emailList List of emails to validate
   * @returns true if every email in the list is valid
   */
  const validateEmailList = (emailList) => emailList.every((email) => email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/));

  const formattedEmails = emails.split(',')
    .map((email) => email.trim())
    .filter((email) => email !== '');
  // To field is required, CC is optional
  if (type === EmailTypes.To) {
    return [formattedEmails, formattedEmails.length > 0 && validateEmailList(formattedEmails)];
  } if (type === EmailTypes.CC) {
    return [formattedEmails, formattedEmails.length === 0 || validateEmailList(formattedEmails)];
  }
  return [formattedEmails, false];
};
