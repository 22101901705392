import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableRow from './DraggableRow';

/**
 * PlanTable functional component
 * */
const PlanTable = (props) => {
  const {
    input,
    updatePlanValues,
    setNativeValue,
    saveRepeatable,
    valueLocation,
    valueKey,
    values,
    section,
    itemEditId,
    setItemEditId,
    unsavedChanges,
    setDataSourceValue,
    switchPlanSearchInput,
    setCustomTextPlanSearchInput,
  } = props;

  const { i18n } = useTranslation();
  const { t } = useTranslation('common');
  const { language } = i18n;

  /**
 * Removes an instance of a repeatable value from the plan
 * @param {*} inputKey A list of object properties pointing to the value to remove
 * @param {*} index The list position of the repeatable value
 */
  const removeItem = (inputKey, index) => {
    const originalValues = { ...values };
    let searchLocation = originalValues;
    inputKey.forEach((key, i) => {
      if (i !== inputKey.length - 1 || key === 'location') {
        searchLocation = searchLocation[key];
      }
    });
    searchLocation = searchLocation.splice(index, 1);
    updatePlanValues(originalValues);
    unsavedChanges();
  };

  /**
   * Clones an item from table and puts values in edit fields (For BAU page only)
   * @param {*} inputKey  list of object properties pointing to the value to clone
   * @param {*} index The list position of the repeatable value
   */
  const cloneItem = (inputKey, index) => {
    const originalValues = { ...values };
    let searchLocation = originalValues;
    inputKey.slice(0, inputKey.length - 1).forEach((key) => {
      searchLocation = searchLocation[key];
    });
    searchLocation[searchLocation.length - 1] = { ...searchLocation[index] };
    updatePlanValues(originalValues);
    unsavedChanges(false, 'BAU');
  };

  /**
 * Begins the editing flow and sets the input fields to their previous values
 * so they can be edited
 * @param {*} inputKey A list of object properties pointing to the value to remove
 * @param {*} item The current item object
 * @param {*} index The list position of the repeatable value
 */
  const editItem = (inputKey, items, index) => {
    const originalValues = { ...values };
    let searchLocation = originalValues;
    const baseId = inputKey.join('-');
    inputKey.slice(0, inputKey.length - 1).forEach((key) => {
      searchLocation = searchLocation[key];
    });
    // edge case to assign id anf file to procedure
    if (items.dataKey === 'procedures') {
      searchLocation[searchLocation.length - 1].file = searchLocation[index].file;
      searchLocation[searchLocation.length - 1].id = searchLocation[index].id;
    }
    const editRow = `${input.dataKey}-table-row-${index + 1}`;
    setItemEditId(editRow);
    items.children.forEach((child) => {
      const id = child.type ? `${baseId}-${child.dataKey}` : `${baseId}`;
      const inputElement = document.getElementById(id);
      if (inputElement) {
        const valueToSet = searchLocation[index][child.dataKey];
        if (child.type && child.type.includes('text')) {
          setNativeValue(inputElement, valueToSet);
        } else if (child.type && !child.type.includes('text')) {
          inputElement.value = `${valueToSet != null ? valueToSet : ''}`;
        } else {
          const dataIndexToSelect = items.data.findIndex(
            (selection) => selection.name === valueToSet,
          );
          // Add case for if inputElement is a PlanSearchInput
          if (inputElement.classList.value === 'select-search') {
            if (dataIndexToSelect === -1) {
              if (switchPlanSearchInput) switchPlanSearchInput(true);
              if (setCustomTextPlanSearchInput) setCustomTextPlanSearchInput(valueToSet);
            }
            setDataSourceValue(inputKey, { name: valueToSet });
          } else if (inputElement.classList.value === 'plan-select-input') {
            if (switchPlanSearchInput) switchPlanSearchInput(false);
            setDataSourceValue(inputKey, { name: valueToSet });
          } else {
            inputElement.value = dataIndexToSelect;
          }
        }
        const event = new Event('change', { bubbles: true });
        inputElement.dispatchEvent(event);
      }
    });
  };

  /**
   * Finalizes the editing flow and sets the input fields to their previous values
   * so they can be edited
   * @param {*} inputKey A list of object properties pointing to the value to remove
   * @param {*} input The current input object
   * @param {*} index The list position of the repeatable value
   */
  const saveEditedItem = (inputKey, items, index) => {
    const originalValues = { ...values };
    let searchLocation = originalValues;
    const baseId = inputKey.join('-');
    inputKey.slice(0, inputKey.length - 1).forEach((key) => {
      searchLocation = searchLocation[key];
    });
    setItemEditId('');
    // Save the new values
    // Section name saved in second last argument
    saveRepeatable(input, inputKey, index, inputKey[inputKey.length - 2]);
    // Reset the input fields after editing is done
    items.children.forEach((child) => {
      const id = child.type ? `${baseId}-${child.dataKey}` : `${baseId}`;
      const inputElement = document.getElementById(id);
      if (inputElement) {
        if (child.type && child.type.includes('text')) {
          setNativeValue(inputElement, '');
        } else if (child.type && !child.type.includes('text')) {
          inputElement.value = '';
        } else
        if (inputElement.classList.value === 'select-search' || inputElement.classList.value === 'plan-select-input') {
          setDataSourceValue(inputKey, { name: '' });
        } else {
          inputElement.value = -1;
        }
        const event = new Event('change', { bubbles: true });
        inputElement.dispatchEvent(event);
      }
    });
    unsavedChanges(true);
  };

  /**
   * Updates plan values after a drag and drop event
   * @param {*} dragIndex The index of item being dragged
   * @param {*} hoverIndex The index of item the dragged item is hovering over
   */
  const moveRow = (dragIndex, hoverIndex) => {
    const originalValues = { ...values };
    let searchLocation = originalValues;
    valueKey.forEach((key, i) => {
      if (i !== valueKey.length - 1 || key === 'location') {
        searchLocation = searchLocation[key];
      }
    });
    const dragRecord = searchLocation[dragIndex];
    searchLocation[dragIndex] = searchLocation[hoverIndex];
    searchLocation[hoverIndex] = dragRecord;
    updatePlanValues(originalValues);
  };

  /**
   * Renders the component
   */
  return (
    <>
      <table className="plan-input-table" id={`${input.dataKey}-table`}>
        <thead>
          <tr className="table-heading" id={`${input.dataKey}-table-headings`}>
            {section.dataKey === 'recoveryLocations' && <th key="order" id="order" style={{ width: '50px' }}>{t('order')}</th>}
            {
            input.children.map((val, index) => (
              <th key={`${input.dataKey}-table-heading-${index + 1}`} id={`${input.dataKey}-table-heading-${index + 1}`}>
                {
                  // eslint-disable-next-line no-nested-ternary
                  val.label ? val.label[language] : (val.itemLabel ? val.itemLabel[language] : '')
                }
              </th>
            ))
          }
            {valueKey[valueKey.length - 1] === 'location' ? (
              <>
                <th key="location" id="location" style={{ width: '50px' }}>{t('location')}</th>
                <th key="delete" id="delete" style={{ width: '50px' }}>{t('delete')}</th>
              </>
            )
              : <th>{t('edit')}</th>}
          </tr>
        </thead>
        <tbody>
          { valueLocation
            .filter((val, i) => typeof val === 'string' || i !== valueLocation.length - 1)
            .map((val, index) => (
              <DndProvider key={`table-row-${index + 1}`} backend={HTML5Backend}>
                <DraggableRow
                  input={input}
                  itemEditId={itemEditId}
                  index={index}
                  val={val}
                  section={section}
                  valueKey={valueKey}
                  moveRow={moveRow}
                  editItem={editItem}
                  removeItem={removeItem}
                  saveEditedItem={saveEditedItem}
                  cloneItem={cloneItem}
                />
              </DndProvider>
            ))}
        </tbody>
      </table>
      <p style={{ fontWeight: 'bold' }}>{t('dragItems')}</p>
    </>
  );
};

PlanTable.defaultProps = {
  valueLocation: [],
  valueKey: [],
  itemEditId: '',
  switchPlanSearchInput: null,
  setCustomTextPlanSearchInput: null,
};

PlanTable.propTypes = {
  input: PropTypes.shape({
    dataKey: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({
      dataKey: PropTypes.string,
      isRequired: PropTypes.bool,
    })),
  }).isRequired,
  values: PropTypes.shape({
    recovery: PropTypes.shape({
      procedures: PropTypes.arrayOf(PropTypes.shape({
        fileName: PropTypes.string,
        file: PropTypes.shape({}),
      })),
    }),
  }).isRequired,
  valueLocation: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({
    address: PropTypes.string,
    locationName: PropTypes.string,
    notes: PropTypes.string,
    phoneNumber: PropTypes.string,
  }), PropTypes.string])),
  valueKey: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  updatePlanValues: PropTypes.func.isRequired,
  setNativeValue: PropTypes.func.isRequired,
  saveRepeatable: PropTypes.func.isRequired,
  section: PropTypes.shape({
    label: PropTypes.shape({
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    content: PropTypes.arrayOf(PropTypes.shape({
      dataKey: PropTypes.string,
    })),
    dataKey: PropTypes.string,
  }).isRequired,
  itemEditId: PropTypes.string,
  setItemEditId: PropTypes.func.isRequired,
  unsavedChanges: PropTypes.func.isRequired,
  setDataSourceValue: PropTypes.func.isRequired,
  switchPlanSearchInput: PropTypes.func,
  setCustomTextPlanSearchInput: PropTypes.func,
};

export default PlanTable;
