import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import '../styles/components/CreatePlanModal.scss';

/** RequestSubmitModal functional component */
const RequestSubmitModal = ({
  isOpen, handleClose, action,
}) => {
  const customStyles = {
    overlay: {
      zIndex: '99',
    },
    content: {
      margin: 'auto',
      height: '200px',
      width: '443px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      zIndex: '99',
    },
  };

  const { t } = useTranslation('common');

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} style={customStyles} ariaHideApp={false}>
      <>
        <p className="confirm-text">{t('requestSubmitText')}</p>
        <div className="modal-buttons">
          {/* change to delete function */}
          <button type="button" id="submitButton" onClick={action}>
            {t('submit')}
          </button>
          <button type="button" id="cancelButton" onClick={handleClose}>
            {t('cancel')}
          </button>
        </div>
      </>

    </Modal>
  );
};

RequestSubmitModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
};

export default RequestSubmitModal;
