import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import axios from '../services/Api';

import '../styles/components/CreatePlanModal.scss';

/** CreatePlanModal functional component */
const CreatePlanModal = ({
  createPlan, isOpen, handleClose, location, teamNames,
}) => {
  const customStyles = {
    overlay: {
      zIndex: '99',
      background: 'rgba(192,192,192,0.75)',
    },
    content: {
      margin: 'auto',
      height: '630px',
      width: '550px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflow: 'visible',
      zIndex: '99',
    },
  };

  const { t, i18n } = useTranslation('common');

  // styles for the dropdown
  const dropDownStyles = {
    control: (styles) => ({
      ...styles,
      fontWeight: '650',
      marginTop: '1.2rem',
      marginBottom: '0.5rem',
      backgroundColor: 'rgb(230, 230, 230)',
      color: 'rgb(68, 68, 68)',
      border: 'solid 1px black',
      lineHeight: 'normal',
      height: i18n.language === 'en' ? '40px' : '53px',
      minWidth: '304px',
      maxWidth: '400px',
      textAlign: 'center',
      textAlignLast: 'center',
      cursor: 'pointer',
      appearance: 'none',
      boxShadow: '0 !important',
      '&:hover': {
        border: 'solid 1px black !important',
      },
    }),
  };

  const mounted = useRef(true);
  const templates = [t('bccPlan'), t('btPlan'), t('logisticsPlan')];
  const [user, setUser] = useState({});
  const [TeamSelector, setTeamSelector] = useState(false);
  const [LocationSelector, setLocationSelector] = useState(false);
  const [dropdownTeam, setDropdownTeam] = useState('');
  const [dropdownLocation, setDropdownLocation] = useState('');
  const [planName, setPlanName] = useState('');

  /** Retrieves user's plans */
  useEffect(() => {
    mounted.current = true;
    async function getPlanUser() {
      const { data: planUser } = await axios.get('/user');
      if (!planUser.role) {
        return;
      }
      if (mounted.current) {
        setUser(planUser);
      }
    }
    getPlanUser();
    return () => {
      mounted.current = false;
    };
  }, []);

  /**
   * sets the team options for React-Select dropdown
   * @returns the teams array to be used for the dropdown
   *
   * */
  const teamOptions = () => {
    const teamsArray = [];
    if (teamNames) {
      teamNames.forEach((team) => {
        teamsArray.push({ value: team, label: team });
      });
    }
    return teamsArray;
  };

  /**
   * sets the location options for the React-select dropdown
   * @returns the locations array to be used for the dropdown
   *
  */
  const locationOptions = () => {
    const locationArray = [];

    if (location) {
      location.forEach((loc) => {
        locationArray.push({ value: loc.name, label: loc.name });
      });
    }
    return locationArray;
  };

  /**
   * Verifies all fields on the modal have been filled in
   * @returns {boolean} true if the plan has a name and either a team or location
   */
  const validateInput = () => (
    planName
    && (
      (LocationSelector && dropdownLocation) || (TeamSelector && dropdownTeam)
    )
  );

  return (
    <Modal className="modal" isOpen={isOpen} handleClose={handleClose} style={customStyles} ariaHideApp={false}>
      <p className="modal-text">{t('enterNewPlanName')}</p>
      <div className="inputDiv">
        <input
          className="modal-input"
          placeholder={t('enterPlanName')}
          id="create-plan-input"
          type="text"
          value={planName}
          onChange={(e) => setPlanName(e.target.value)}
        />
        <p className="inputText">{t('plan')}</p>
      </div>
      <p className="modal-text">{t('selectPlanType')}</p>
      {user.role && templates.map((temp) => (
        <div className="plan-template-select" key={temp}>
          <input
            className="bubble"
            type="radio"
            id={`template-select-${temp}`}
            value={temp}
            name="template"
            onClick={(e) => {
              // eslint-disable-next-line no-unused-expressions
              (e.target.value === t('btPlan'))
                ? (setTeamSelector(true), setLocationSelector(false))
                : (setTeamSelector(false), setLocationSelector(true));
            }}
          />
          <label className="bubbleLabel" htmlFor={`template-select-${temp}`}>{temp}</label>
          <br />
        </div>
      ))}

      <div className="create-plan-select-input">

        {TeamSelector
      && (
      <>
        <Select
          options={teamOptions()}
          placeholder={t('selectTeamForBusinessTeam')}
          styles={dropDownStyles}
          id="select-team-dropdown"
          onChange={(e) => { setDropdownTeam(e.value); }}
        />
      </>
      )}

        {LocationSelector
        && (
        <>
          <Select
            options={locationOptions()}
            placeholder={t('selectLocForLogisticsTeam')}
            styles={dropDownStyles}
            id="select-location-dropdown"
            onChange={(e) => { setDropdownLocation(e.value); }}
          />
        </>
        )}

      </div>
      <div className="createButtons">
        <button
          type="button"
          id="okButton"
          className="createOkButton"
          onClick={() => { createPlan(dropdownTeam, dropdownLocation, planName); handleClose(); }}
          disabled={!validateInput()}
        >
          {t('ok')}
        </button>
        <button
          type="button"
          id="cancelButton"
          className="createCancelButton"
          onClick={() => {
            setTeamSelector(false);
            setLocationSelector(false);
            setDropdownLocation('');
            setDropdownTeam('');
            handleClose();
          }}
        >
          {t('cancel')}
        </button>
      </div>
    </Modal>
  );
};

CreatePlanModal.defaultProps = {
  createPlan: null,
  isOpen: false,
  handleClose: null,
};

CreatePlanModal.propTypes = {
  createPlan: PropTypes.func,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  location: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  teamNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CreatePlanModal;
