import {
  createStore, combineReducers, compose, applyMiddleware,
} from 'redux';
import reduxThunk from 'redux-thunk';
import authorizationReducer from './reducers/authorizationReducer';
import updatePlanValuesReducer from './reducers/updatePlanValuesReducer';
import updatePlanTablesReducer from './reducers/updatePlanTablesReducer';
import sidebarReducer from './reducers/sidebarReducer';

/**
 * Helps in combining reducers while creating a redux store
 * @param {*} accessToken the access token
 * @returns {Object} redux store
 */
export default (accessToken) => {
  const initialState = {
    accessToken,
  };
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    combineReducers({
      accessToken: authorizationReducer,
      values: updatePlanValuesReducer,
      planTables: updatePlanTablesReducer,
      sidebar: sidebarReducer,
    }),
    initialState,
    composeEnhancers(applyMiddleware(reduxThunk)),
  );

  return store;
};
