import React, { useState, useEffect, useRef } from 'react';
import '../styles/components/StaffTable.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import updatePlanTablesAction from '../store/actions/updatePlanTablesAction';

/** CreateStaffingTable functional component */
export const StaffingTable = (props) => {
  const {
    unsavedChanges,
    priority,
    table,
    setNextDisabled,
    updatePlanTables,
  } = props;

  const { i18n } = useTranslation();
  const { language } = i18n;
  const { t } = useTranslation('common');

  const [staffingSum, setStaffingSum] = useState();

  // Deep copy initial table
  const copy = useRef(JSON.parse(JSON.stringify(table)));

  // once StaffingTable is added to base-plan-template,
  // refactor inputProperties to take values from base-plan-template
  const inputProperties = {
    help: {
      en: JSON.stringify({
        Urgent: "Urgent activities are to be recovered IMMEDIATELY. These activities directly support clients and if they cannot be recovered, The Co-operators would face extreme financial losses and irrecoverable damage to the company's reputation.",
        High: 'Activities of a high criticality should be recovered as soon as possible. The inability to perform these activities would result in a loss of competitive edge and would pose a major impact on long term financial stability.',
        Medium: 'Medium priority activities are critical and need to be recovered but can be delayed as they do not directly or immediately impact clients, reputation, or financial stability.',
        Low: 'Low priority activities are important but can be delayed.',
      }),
      fr: JSON.stringify({
        Urgent: 'La reprise des activités urgentes doit être IMMÉDIATE. Ces activités touchent directement la clientèle. Si elles ne peuvent être reprises, Co-operators subira de très graves pertes financières et un tort irréparable à sa réputation.',
        High: 'Les activités de priorité élevée doivent être reprises le plus rapidement possible. L’incapacité de réaliser ces activités entraînerait la perte d’un avantage concurrentiel et aurait de graves répercussions sur la stabilité financière à long terme.',
        Medium: 'Les activités de priorité moyenne sont essentielles et doivent être reprises, mais cette reprise peut être retardée, car ces activités n’ont pas une incidence directe ou immédiate sur la clientèle, la réputation ou la stabilité financière.',
        Low: 'Les activités de priorité faible sont importantes, mais leur reprise peut être retardée.',
      }),
    },
  };

  /**
   * sums up table data for the initial value of staffingSum
   */
  useEffect(() => {
    let sum = 0;
    table[0]?.forEach((val) => {
      sum += val;
    });
    setStaffingSum(sum);
    if (sum === 0) {
      setNextDisabled(true);
    }
  }, []);

  /**
   * Changes the save button to green if there are unsaved changes
   */
  const onUnsaved = () => {
    if (JSON.stringify(table) !== JSON.stringify(copy.current)) {
      unsavedChanges(true);
    } else {
      unsavedChanges(false);
    }
  };

  useEffect(() => {
    onUnsaved();
  }, [table]);

  /**
   * updates the table state
   * @param {*} row row of the table
   * @param {*} column column of the table
   * @param {*} value value to be set
   */
  const setTable = (row, column, value) => {
    if (table.length === 0) table.push(Array(12).fill(0), Array(12).fill(0));

    const numValue = parseInt(value, 10);
    if (row === 1 || (row === 0 && numValue >= 0)) {
      const difference = numValue - table[row][column];
      table[row][column] = numValue;
      updatePlanTables(JSON.parse(`{"${priority.charAt(0).toLowerCase() + priority.slice(1)}StaffTable" : ${JSON.stringify(table)}}`));
      setStaffingSum(staffingSum + difference);
      if (staffingSum === 0) {
        setNextDisabled(true);
      }
    }
  };

  return (
    <table id={`staffTable${priority}`} className="staff-table">
      <thead>
        <tr>
          <th className="priority-heading" width="100px">
            {t(`${priority}`)}
            <div className="help">
              {inputProperties && inputProperties.help && (
                <>
                  <FontAwesomeIcon icon={faQuestionCircle} data-tip data-for={`help-${priority}`} tabIndex="-1" />
                  <ReactTooltip
                    id={`help-${priority}`}
                    class="hint-staffing-table"
                    place="right"
                    type="light"
                    effect="solid"
                    border
                  >
                    {/* eslint-disable react/no-danger */}
                    <p
                      dangerouslySetInnerHTML={
                        {
                          __html: DOMPurify.sanitize(
                            JSON.parse(inputProperties.help[language])[priority],
                          ),
                        }
                      }
                      className={`help-${priority}`}
                    />
                  </ReactTooltip>
                </>
              )}
            </div>
          </th>
          <th colSpan="12">
            <table className="inner-staff-table">
              <thead>
                <tr>
                  <th colSpan="12">
                    {t(`staffingRequirements.tableHeading${priority}`)}
                    <br />
                    {t('staffingRequirements.tableSubtitle')}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="months-heading">

                  <td>{t('jan')}</td>
                  <td>{t('feb')}</td>
                  <td>{t('mar')}</td>
                  <td>{t('apr')}</td>
                  <td>{t('may')}</td>
                  <td>{t('jun')}</td>
                  <td>{t('jul')}</td>
                  <td>{t('aug')}</td>
                  <td>{t('sep')}</td>
                  <td>{t('oct')}</td>
                  <td>{t('nov')}</td>
                  <td>{t('dec')}</td>

                </tr>
              </tbody>
            </table>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="staffing-heading">{t('staffing')}</td>

          {[...Array(12)].map((s, i) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <td className="staff-table" key={`Input0${i}`}>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <input
                type="text"
                defaultValue={(table.length !== 0) ? (table[0][i]) : 0}
                className="input"
                id={`tableData${0}${i}${priority}`}
                onChange={(e) => { setTable(0, i, e.target.value); }}
              />
            </td>

          ))}

        </tr>
        <tr>
          <td className="in-office-heading">{t('staffingRequirements.inOffice.title')}</td>

          {[...Array(12)].map((s, i) => (

            /* eslint-disable-next-line react/no-array-index-key */
            <td className="staff" key={`Input1${i}`}>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <input
                type="text"
                defaultValue={(table.length !== 0) ? (table[1][i]) : 0}
                className="input"
                id={`tableData${1}${i}${priority}`}
                onChange={(e) => { setTable(1, i, e.target.value); }}
              />
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

StaffingTable.defaultProps = {
  setNextDisabled: () => { },
  table: [Array(12).fill(0), Array(12).fill(0)],
};

StaffingTable.propTypes = {
  unsavedChanges: PropTypes.func.isRequired,
  priority: PropTypes.string.isRequired,
  table: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.number),
  ),
  updatePlanTables: PropTypes.func.isRequired,
  setNextDisabled: PropTypes.func,
};

export default connect(
  null,
  { updatePlanTables: updatePlanTablesAction },
)(StaffingTable);
