import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Priorities from '../enums/Priorities';
import '../styles/components/StaffTable.scss';

// Review Stafing table component
const ReviewStaffTable = ({
  priorityLevel,
  plan,
}) => {
  const { t } = useTranslation('common');

  /**
   * Used for returning a table to be diaplyed with its priority level
   * @returns the matching staffing table to be displayed
   */
  const tableData = () => {
    if (priorityLevel === Priorities.Urgent) {
      return plan.urgentStaffTable;
    } if (priorityLevel === Priorities.High) {
      return plan.highStaffTable;
    } if (priorityLevel === Priorities.Medium) {
      return plan.medStaffTable;
    } if (priorityLevel === Priorities.Low) {
      return plan.lowStaffTable;
    }
    return null;
  };
  return (
    <>
      <table className="staff-table">
        <thead>
          <tr>
            <th className="priority-heading">{t(`${priorityLevel}`)}</th>
            <th colSpan="12">
              <table className="inner-staff-table">
                <thead>
                  <tr>
                    <th colSpan="12">
                      {t(`staffingRequirements.tableHeading${priorityLevel}`)}
                      <br />
                      {t('staffingRequirements.tableSubtitle')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="months-heading">
                    <td>{t('jan')}</td>
                    <td>{t('feb')}</td>
                    <td>{t('mar')}</td>
                    <td>{t('apr')}</td>
                    <td>{t('may')}</td>
                    <td>{t('jun')}</td>
                    <td>{t('jul')}</td>
                    <td>{t('aug')}</td>
                    <td>{t('sep')}</td>
                    <td>{t('oct')}</td>
                    <td>{t('nov')}</td>
                    <td>{t('dec')}</td>
                  </tr>
                </tbody>
              </table>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="staffing-heading">{t('staffing')}</td>

            {[...Array(12)].map((e, i) => (
              /* eslint-disable-next-line react/no-array-index-key */
              <td className="staff-table" key={`reviewInput0${i}`}>

                {tableData()
                  && (tableData().length > 1) ? (tableData()[0][i]) : (0)}

              </td>
            ))}

          </tr>
          <tr>
            <td className="in-office-heading">{t('staffingRequirements.inOffice.title')}</td>

            {[...Array(12)].map((e, i) => (
              /* eslint-disable-next-line react/no-array-index-key */
              <td className="staff" key={`reviewInput1${i}`}>
                {tableData()
                  && (tableData().length > 1) ? (tableData()[1][i]) : (0)}

              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </>
  );
};
ReviewStaffTable.propTypes = {
  priorityLevel: PropTypes.string.isRequired,
  plan: PropTypes.shape({
    name: PropTypes.string,
    _id: PropTypes.string,
    urgentStaffTable: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.arrayOf(PropTypes.number),
    ),
    highStaffTable: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.arrayOf(PropTypes.number),
    ),
    medStaffTable: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.arrayOf(PropTypes.number),
    ),
    lowStaffTable: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.arrayOf(PropTypes.number),
    ),

  }).isRequired,

};
export default ReviewStaffTable;
