import React from 'react';
import { Login } from 'kdc-component-library';
import { useTranslation } from 'react-i18next';
import '../../styles/components/pages/LoginPage.scss';

/** LoginPage component class */
const LoginPage = () => {
  const { t } = useTranslation('common');
  return (
  /**
   * React render method
   * @return {JSX} component jsx
   */
    <Login title={t('login')} namespace="Login" />
  );
};

export default LoginPage;
