import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import '../styles/components/ReviewRecoveryContactsLocations.scss';

const ReviewRecoveryContactsLocations = ({ planId, data, labels }) => {
  const history = useHistory();
  const { t } = useTranslation('common');
  const { i18n } = useTranslation();
  const findReqField = (arr) => {
    for (let i = arr.length - 1; i >= 0; i -= 1) {
      if (arr[i].isRequired) return arr[i].dataKey;
    }
    return false;
  };
  return (
    <>
      {labels.content.map((section, index) => {
        let reqField = findReqField(section.children);
        return (
          <React.Fragment key={section.dataKey}>
            <div id={`uniqueKey-section-${section.label[i18n.language]}`} className="review-section-title-row">
              <p className="subtitle">
                {section.label[i18n.language]}
              </p>
              {index === 0
              && (
                <button
                  type="button"
                  className="edit-button"
                  onClick={() => {
                    history.replace({
                      pathname: '/edit-plan',
                      search: `?planId=${planId}&sectionKey=${labels.dataKey}`,
                    });
                  }}
                >
                  {section.dataKey === 'BAU' ? t('editBAU') : `${t('edit')} ${labels.label[i18n.language]}`}

                </button>

              )}
            </div>
            {Array.isArray(data[section.dataKey]) ? (
              <table className="review-table">
                <thead>
                  <tr>
                    {section.children.map((item) => (
                      <th key={item.dataKey}>{item.label[i18n.language]}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data[section.dataKey].map((item) => (
                    item[reqField] && (
                      <tr key={item[reqField]}>
                        {Object.entries(item).map((value) => (
                          value[0] !== 'file' && value[0] !== 'id' && (
                            <td key={value[0]}>{value[1]}</td>
                          )
                        ))}
                      </tr>
                    )
                  ))}
                </tbody>
              </table>
            ) : (
              Object.entries(data[section.dataKey]).map((values, i) => {
                if (Array.isArray(values[1])) {
                  reqField = findReqField(section.children[i].children);
                  return (
                    <React.Fragment key={section.children[i].dataKey}>
                      <div className="smaller-title-container">
                        <p className="smaller-title">{section.children[i].label[i18n.language]}</p>
                      </div>
                      <table className="review-table">
                        <thead>
                          <tr>
                            <th>#</th>
                            {section.children[i].children.map((item) => (
                              <th key={item.dataKey}>{item.label[i18n.language]}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {values[1].map((item, j) => (
                            item[reqField] && (
                              <tr key={item[reqField]}>
                                <td>{j + 1}</td>
                                {Object.entries(item).map((value) => (
                                  <td key={value[0]}>{value[1]}</td>
                                ))}
                              </tr>
                            )
                          ))}
                        </tbody>
                      </table>
                    </React.Fragment>
                  );
                }
                return (
                  <React.Fragment key={section.children[i].dataKey}>
                    <div className="smaller-title-container">
                      <p className="smaller-title">{section.children[i].label[i18n.language]}</p>
                    </div>
                    <table className="review-table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{t('location')}</th>
                          {section.children[i].children[0].children.map((item) => (
                            <th key={item.dataKey}>{item.label[i18n.language]}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {section.children[i].children.map((item, j) => (
                          <tr key={item.dataKey}>
                            <td>{j + 1}</td>
                            <td>{item.label[i18n.language]}</td>
                            {item.children.map((value) => (
                              <td key={value.dataKey}>
                                {
                                  // eslint-disable-next-line max-len
                                  data[section.dataKey][section.children[i].dataKey][item.dataKey][value.dataKey]
                                }
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </React.Fragment>
                );
              })
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

ReviewRecoveryContactsLocations.propTypes = {
  planId: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired,
  labels: PropTypes.shape({
    dataKey: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.shape({})),
    label: PropTypes.shape({
      en: PropTypes.string,
    }),
  }).isRequired,
};

export default ReviewRecoveryContactsLocations;
