import React from 'react';

/**
 *  Context that passes down function to update the plan
 */
const PlanContext = React.createContext({});

/**
 *  Provider that passes down function to update the plan
 */
export const PlanProvider = PlanContext.Provider;

export default PlanContext;
