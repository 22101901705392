import React, {
  useEffect, useState, useRef,
} from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { useHistory, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NavigationPrompt from 'react-router-navigation-prompt';
import Modal from 'react-modal';
import { useMsal } from '@azure/msal-react';
import useQuery from '../../hooks/QueryHook';
import PlanSection from '../PlanSection';
import axios from '../../services/Api';
import updatePlanValuesAction from '../../store/actions/updatePlanValuesAction';
import updatePlanTablesAction from '../../store/actions/updatePlanTablesAction';
import Sidebar from '../Sidebar';
import renderSidebarAction from '../../store/actions/renderSidebarAction';
import SaveButton from '../SaveButton';
import MessageModal from '../MessageModal';
import OldSessionModal from '../OldSessionModal';
import { PlanProvider } from '../../context/PlanContext';
import { PlanType } from '../../enums/PlanType';
import validateId from '../../services/IdValidator';
import SaveErrorModal from '../SaveErrorModal';
import { Status } from '../../enums/PlanStatus';
import '../../styles/components/pages/EditPlanPage.scss';

/**
 * EditPlanPage functional component
 * @returns {JSX} component
 */
export const EditPlanPage = ({
  planValues,
  planTables,
  updatePlanValues,
  updatePlanTables,
  renderSidebar,
  updateSidebarRender,
  setSaveState,
}) => {
  const query = useQuery();
  const planId = query.get('planId');
  const sectionKey = query.get('sectionKey');
  const startActivity = query.get('startActivity');
  const history = useHistory();
  const mounted = useRef(true);
  const [labels, setLabels] = useState([]);
  const [plan, setPlan] = useState(null);
  const planRef = useRef(plan);
  const [oldSessionPlan, setOldSessionPlan] = useState(null);
  const [currentSection, setCurrentSection] = useState(null);
  const [editSidebar, setEditSidebar] = useState(false);
  const [renderModal, setRenderModal] = useState(false);
  const [oldSessionModalOpen, setOldSessionModalOpen] = useState(false);
  const [selectedContent, setSelectedContent] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [currentBusinessActivity, setCurrentBusinessActivity] = useState(0);
  const [newBusinessActivityClicked, setNewBusinessActivityClicked] = useState(false);
  const [cloneBusinessActivityClicked, setCloneBusinessActivityClicked] = useState(false);
  const [showUnsavedChangesBox, setShowUnsavedChangesBox] = useState(false);
  const [showNewBABox, setShowNewBABox] = useState(false);
  const [isUnsavedStaffingTable, setIsUnsavedStaffingTable] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [newOrClonedBA, setNewOrClonedBA] = useState(false);
  const [backButtonClicked, setBackButtonClicked] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [openSaveErrorModal, setOpenSaveErrorModal] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const preventClose = useRef(false);

  const { i18n } = useTranslation();
  const { instance } = useMsal();
  const { t } = useTranslation('common');
  const { language } = i18n;
  const customStyles = {
    overlay: {
      zIndex: '99',
      background: 'rgba(192,192,192,0.75)',
    },
    content: {
      margin: 'auto',
      height: 'fit-content',
      width: '40%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflow: 'visible',
      zIndex: '99',
    },
  };

  // Send user back to home page if planId is not a valid ID
  if (!validateId(planId)) {
    return <Redirect to="/" />;
  }

  // called with JSON.stringify to turn "" into null
  const replacer = ($key, $value) => {
    const tempValue = $value;
    // only need to check for strings is value is an array
    if (Array.isArray(tempValue)) {
      // loop through the array of objects and check fields
      tempValue.forEach((element, index) => {
        Object.entries(element).forEach((entry) => {
          if (entry[1] === '') {
            tempValue[index][entry[0]] = null;
          }
        });
      });
    }
    // otherwise, leave the value unchanged
    return tempValue;
  };

  /**
   * Gets the plan after the user saves
   * REFACTOR LATER! to get the plan from the save api call
   */
  const getPlan = async () => {
    const { data: formattedPlan } = await axios.get(
      `/plan/${planId}/formatted`,
    );
    if (mounted.current) {
      setPlan(formattedPlan);
    }
    if (formattedPlan.values.businessImpactAnalysis) {
      const { length } = formattedPlan.values.businessImpactAnalysis.businessActivities;
      const startIndex = parseInt(startActivity, 10);
      if (
        typeof startIndex === 'number'
        && startIndex < length
      ) setCurrentBusinessActivity(startIndex);
    }
  };

  /**
    * Deletes business activity
    * @param {*} index
    */
  const deleteBA = async (handleClose) => {
    const updatedPlan = { ...plan };
    updatedPlan.values.businessImpactAnalysis.businessActivities.splice(currentBusinessActivity, 1);
    await axios.put(`/plan/${planId}`, { values: updatedPlan.values });
    if (
      plan.values.businessImpactAnalysis.businessActivities.length
      === parseInt(currentBusinessActivity, 10)
    ) {
      setCurrentBusinessActivity(currentBusinessActivity - 1);
    }
    handleClose();
    window.location.reload();
  };

  /**
   * checks if the staffing table is valid
   * @returns boolean on whether the staffing table values are valid
   */
  const checkStaffingTableValid = () => {
    const priorityList = ['Urgent', 'High', 'Medium', 'Low'];
    const rows = 2;
    const cols = 12;

    return !priorityList.some((priority) => {
      let sum = 0;
      const table = document.getElementById(`staffTable${priority}`);
      if (table) {
        for (let i = 0; i < rows; i += 1) {
          for (let j = 0; j < cols; j += 1) {
            const val = parseInt(document.querySelector(`#staffTable${priority} #tableData${i}${j}${priority}`).value, 10);
            sum += val;
            if (i === 0 && val < 0) { return true; } // staffing validation

            /** Not currently needed, left for future implementation
            if (i === 1 && val < 0) { return true; } // in office validation
            */
          }
          if (i === 0 && sum <= 0) { return true; }// staffing validation
        }
      }
      return false;
    });
  };

  /*
    Called when editPlanPage umounts, and when sidebar is pressed
    Checks if plan useState variable === planValues redux state variable
  */
  const checkSaved = () => {
    if (!plan) return true;

    if (plan.status === Status.New) {
      setRenderModal(true);
      return false;
    }

    let saved;
    if (
      JSON.stringify(planValues, replacer)
      === JSON.stringify(plan.values, replacer)
      && !isUnsavedStaffingTable
    ) {
      saved = true;
    } else {
      saved = false;
    }
    // unmount and saved: return true
    if (saved) {
      return true;
    }
    // unmount and unsaved: return false + render modal
    setRenderModal(true); // render modal
    return false;
  };

  const resetSaveButtons = (section = null) => {
    const saveButtons = document.querySelectorAll('[id = save-button]');
    for (let i = 0; i < saveButtons.length; i += 1) saveButtons[i].classList.remove('green');

    if (selectedSection === 'businessAsUsualActivities' && !checkStaffingTableValid()) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }

    const sectionButton = document.querySelector(`[id = save-button-${section}]`);
    if (section && sectionButton) {
      sectionButton.classList.remove('green');
    } else {
      // No section when main save buttons are used, reset all colors
      const allSectionButtons = document.querySelectorAll('.repeat-input-button.green');
      for (let i = 0; i < allSectionButtons.length; i += 1) allSectionButtons[i].classList.remove('green');
    }

    preventClose.current = false;
    setShowUnsavedChangesBox(false);
    setIsUnsavedStaffingTable(false);
  };

  const unsavedChanges = (unsaved = false, section = null) => {
    const dif = JSON.stringify(planValues, replacer) !== JSON.stringify(plan.values, replacer);
    if (unsaved || dif) {
      preventClose.current = true;
      if (unsaved) {
        setIsUnsavedStaffingTable(true);
      }
      const saveButtons = document.querySelectorAll('[id = save-button]');
      for (let i = 0; i < saveButtons.length; i += 1) saveButtons[i].classList.add('green');

      setNextDisabled(true);
      const sectionButton = document.querySelector(`[id = save-button-${section}]`);
      if (section && sectionButton) {
        sectionButton.classList.add('green');
      }
      if (!showUnsavedChangesBox) {
        setShowUnsavedChangesBox(true);
      }
      // Prepare save state
      setSaveState({
        _id: plan._id,
        layout: plan.layout,
        values: planValues,
        ...planTables,
      });
    } else {
      preventClose.current = false;
      resetSaveButtons(section);
      setSaveState({ _id: null });
    }
  };

  // helper function to set className of sidebar label
  const selectedContentHelper = (index, contentObj) => {
    let label;
    // for 'Business Activites Title'
    if (contentObj.dataKey === 'businessActivities') {
      if (selectedContent === contentObj.dataKey) {
        label = 'subtitle-edit-selected';
      } else {
        label = 'subtitle-edit';
      }
    } else if (selectedContent === contentObj.dataKey) {
      label = 'item-edit-selected';
    } else {
      label = 'item-edit';
    }
    return label;
  };

  // function to loop through the plan and get the sidebar labels
  const getLabels = (tempPlan) => {
    const tempLabels = [];
    // getTitle
    tempLabels.push({
      id: tempPlan.name,
      onClick: () => undefined,
      label: tempPlan.name,
      className: 'title-edit',
    });
    tempPlan.layout.sections.forEach((section, index) => {
      // adding staffing level requirements label
      if (index === 1 && tempPlan.type === PlanType.BCP) {
        tempLabels.push({
          id: 'Staffing Level Requirements',
          onClick: () => {
            history.replace({
              pathname: '/edit-plan',
              search: `planId=${planId}&sectionKey=businessAsUsualActivities`,
            });
            document.getElementById('staff-requirements-table')?.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          },
          label: t('staffingRequirements.title'),
          className: 'subtitle-edit',
        });
      }
      if (section.dataKey !== 'businessAsUsualActivities') {
        tempLabels.push({
          id: section.label[language],
          label: section.label[language],
          path: `/edit-plan/${planId}/${section.dataKey}`,
          /** On click display the section and scroll to the location */
          onClick: () => {
            // if the values are saved, go to new section
            history.push({
              pathname: '/edit-plan',
              search: `planId=${planId}&sectionKey=${section.dataKey}`,
            });
            /** Added timeout to wait for the document to change. The document always shows loaded,
           * so, timeout was the only way that worked for this.
           */
            setTimeout(() => {
              const anchor = document.getElementById(
                `section-${section.label[language]}`,
              );
              if (anchor) {
                anchor.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                });
              }
            }, 10);
          },
          className:
            selectedSection === section.dataKey
              ? 'subtitle-edit-selected'
              : 'subtitle-edit',
        });
      }
      section.content.forEach((contentObj) => {
        if (contentObj.dataKey === 'businessActivities') {
          return; // removes the 'Business Activities subititle'
        }
        tempLabels.push({
          id: contentObj.label[language],
          label: contentObj.label[language],
          onClick: () => {
            // if user doesn't click the content they're currently on call onClick
            if (selectedContent !== contentObj.dataKey) {
              /** On click display the content and scroll to the exact location of the content */
              history.push({
                pathname: '/edit-plan',
                search: `?planId=${planId}&sectionKey=${section.dataKey}`,
                hash: `#${contentObj.dataKey}`,
              });
              /** Added timeout to wait for document to change. */
              setTimeout(() => {
                const anchor = document.getElementById(
                  `uniqueKey-input-${contentObj.label[language]}`,
                );
                if (anchor) {
                  anchor.scrollIntoView({
                    behavior: 'auto',
                    block: section.dataKey !== 'businessAsUsualActivities' ? 'center' : 'start',
                    inline: 'nearest',
                  });
                }
              }, 10);
            }
          },
          className: section.dataKey !== 'businessAsUsualActivities'
            ? selectedContentHelper(index, contentObj)
            : 'subtitle-edit no-border',
        });
      });
      // adding businessActivites if businessActivities exists
      if (index === 0 && tempPlan.values.businessImpactAnalysis) {
        tempPlan.values.businessImpactAnalysis.businessActivities.forEach(
          (activity, businessActivityIndex) => {
            tempLabels.push({
              id: activity.Activity,
              onClick: () => {
                history.replace({
                  pathname: '/edit-plan',
                  search: `?planId=${planId}&sectionKey=businessImpactAnalysis`,
                  hash: '#businessActivities',
                });
                setCurrentBusinessActivity(businessActivityIndex);
              },
              label:
                activity.Activity === null
                  ? `${businessActivityIndex + 1}. New...`
                  : `${businessActivityIndex + 1}. ${activity.Activity}`,
              className:
                currentBusinessActivity === businessActivityIndex
                  && selectedSection === section.dataKey
                  ? 'activity-edit-selected'
                  : 'activity-edit',
            });
          },
        );
      }
    });

    tempLabels.push(
      {
        id: 'Review',
        label: t('reviewMyPlan'),
        onClick: () => {
          history.push({
            pathname: '/review-plan/',
            search: `?planId=${planId}`,
          });
        },
        className: 'title-edit',
      },
      {
        id: 'Home',
        label: t('home'),
        url: '/',
        className: 'top-item-edit',
      },
      {
        id: 'Logout',
        label: t('logout'),
        onClick: () => instance.logoutRedirect({
          postLogoutRedirectUri: window.AZURE_REDIRECT_URL,
        }),
        className: 'top-item-edit',
      },
    );
    return tempLabels;
  };

  let isMounted = true;

  const handleBeforeUnload = (event) => {
    if (planRef.current?.status === Status.New || preventClose.current) {
      event.preventDefault();
    }
    return '';
  };

  /** When the component renders, retrieve the plan, save it, and generate the sidebar labels */
  useEffect(() => {
    isMounted = true;
    const getPlanAndLabels = async () => {
      const { data: formattedPlan } = await axios.get(`/plan/${planId}/formatted`);
      // Don't save plans if user left the page before data was retrieved
      if (isMounted) {
        setPlan(formattedPlan);
        // Initialize plan with any existing values
        updatePlanValues(JSON.parse(JSON.stringify(formattedPlan.values)));
        updatePlanTables(JSON.parse(JSON.stringify({
          urgentStaffTable: formattedPlan.urgentStaffTable,
          highStaffTable: formattedPlan.highStaffTable,
          medStaffTable: formattedPlan.medStaffTable,
          lowStaffTable: formattedPlan.lowStaffTable,
        })));
        setLabels(getLabels(formattedPlan));
      }
    };
    const getPlanSnapshot = async () => {
      const { data: saveState } = await axios.get(`/plan/temp/${planId}/formatted`);
      if (isMounted && saveState && saveState.oldId === planId) {
        setOldSessionPlan(saveState);
        setOldSessionModalOpen(true);
      }
    };
    getPlanAndLabels();
    getPlanSnapshot();
    setEditSidebar(true);

    // Set listener for back button and tab close
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      setEditSidebar(false);
      isMounted = false;
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (plan) {
      setLabels(getLabels(plan));
    }
  }, [language]);

  useEffect(() => {
    const missingFields = document.querySelectorAll('[id ^= "missing-"]');
    for (let i = 0; i < missingFields.length; i += 1) missingFields[i].remove();
    if (plan) {
      setCurrentSection(
        plan.layout.sections.find((s) => s.dataKey === sectionKey),
      );
      setBackButtonClicked(false);
      setNextDisabled(false);
    }
  }, [sectionKey]);

  useEffect(() => {
    mounted.current = true;
    if (plan) {
      setLabels(getLabels(plan));
    }
    return () => {
      mounted.current = false;
    };
  }, [selectedContent, selectedSection]);

  useEffect(() => {
    if (plan) {
      setLabels(getLabels(plan));
    }
    if (history.location.search.includes('sectionKey=businessImpactAnalysis')) {
      history.replace({
        pathname: '/edit-plan',
        search: `?planId=${planId}&sectionKey=businessImpactAnalysis&startActivity=${currentBusinessActivity}`,
      });
    }
  }, [currentBusinessActivity]);

  /** Makes copy of empty business activity */
  const getCopy = (objectToCopy) => {
    const copy = {};
    Object.keys(objectToCopy).forEach((key) => {
      if (
        objectToCopy[key]
        && typeof objectToCopy[key] === 'object'
        && !Array.isArray(objectToCopy[key])
      ) {
        copy[key] = getCopy(objectToCopy[key]);
      } else if (Array.isArray(objectToCopy[key])) {
        copy[key] = [];
        copy[key].push(getCopy(objectToCopy[key][0]));
      } else {
        copy[key] = null;
      }
    });
    return copy;
  };

  /**
   * Adds new business activity to active plan and sets focus to new activity
   */
  const addNewBusinessActivity = async () => {
    setNewBusinessActivityClicked(false);
    const newValues = plan.values.businessImpactAnalysis.businessActivities;
    newValues.push(getCopy(newValues[0]));
    newValues[newValues.length - 1].location = [];
    newValues[newValues.length - 1].priorityLevel[0].priorityLevelTiming = [];
    setPlan({
      ...plan,
      values: {
        ...plan.values,
        businessImpactAnalysis: {
          businessActivities: newValues,
        },
      },
    });
    updatePlanValues(JSON.parse(JSON.stringify(plan.values)));
    updatePlanTables(JSON.parse(JSON.stringify({
      urgentStaffTable: plan.urgentStaffTable,
      highStaffTable: plan.highStaffTable,
      medStaffTable: plan.medStaffTable,
      lowStaffTable: plan.lowStaffTable,
    })));
    setCurrentBusinessActivity(newValues.length - 1);
  };

  /**
   * Clones current business activity and sets focus to cloned activity
   */
  const cloneBusinessActivity = async () => {
    setCloneBusinessActivityClicked(false);
    const newValues = plan.values.businessImpactAnalysis.businessActivities;
    const clonedValue = { ...newValues[currentBusinessActivity] };
    clonedValue.Activity = '';
    newValues.push(clonedValue);
    setPlan({
      ...plan,
      values: {
        ...plan.values,
        businessImpactAnalysis: {
          businessActivities: newValues,
        },
      },
    });
    updatePlanValues(JSON.parse(JSON.stringify(plan.values)));
    updatePlanTables(JSON.parse(JSON.stringify({
      urgentStaffTable: plan.urgentStaffTable,
      highStaffTable: plan.highStaffTable,
      medStaffTable: plan.medStaffTable,
      lowStaffTable: plan.lowStaffTable,
    })));
    setCurrentBusinessActivity(newValues.length - 1);
  };

  /**
   * Loads the first available section by default
   * Otherwise, parse the section key provided
   * Also checks if new business activty button is clicked
   */
  useEffect(() => {
    if (plan) {
      planRef.current = plan;
      if (sectionKey) {
        setCurrentSection(
          plan.layout.sections.find((s) => s.dataKey === sectionKey),
        );
      } else {
        setCurrentSection(plan.layout.sections[0]);
        setSelectedSection(plan.layout.sections[0].dataKey);
        if (plan.layout.sections[0].content[0]) {
          setSelectedContent(plan.layout.sections[0].content[0].dataKey);
        }
      }
      if (
        document.querySelectorAll('[id ^= "missing-"]').length === 0
        && newBusinessActivityClicked
      ) {
        addNewBusinessActivity();
        setShowNewBABox(true);
      } else if (
        document.querySelectorAll('[id ^= "missing-"]').length === 0
        && cloneBusinessActivityClicked
      ) {
        cloneBusinessActivity();
        setShowNewBABox(true);
      }
    }
  }, [plan]);

  // dynamically change selectedContent and selectedSection whenever the url changes
  useEffect(() => {
    mounted.current = true;
    history.listen((location) => {
      const content = location.hash.substring(1);
      const sectionKeyMatch = location.search.match(/sectionKey=([A-Za-z]+)/);
      const section = sectionKeyMatch ? sectionKeyMatch[1] : '';
      if (mounted.current) {
        if (typeof content !== 'undefined') {
          if (selectedContent !== content) {
            setSelectedContent(content);
          }
          if (selectedSection !== section) {
            setSelectedSection(section);
          }
        } else {
          setSelectedSection(section);
          setSelectedContent('');
        }
      }
    });
    return () => {
      mounted.current = false;
    };
  }, [history]);

  const sidebarClose = () => {
    updateSidebarRender();
  };

  const handleMissingField = (id) => {
    const tag = document.createElement('center');
    const text = document.createTextNode('*This field is required');
    tag.appendChild(text);
    tag.setAttribute('id', `missing-${id}`);
    tag.style.color = 'red';
    tag.style.scrollMarginTop = '12rem';
    const element = document.getElementById(id);
    if (element) {
      element.style.alignItems = 'center';
      element.after(tag);
    }
  };

  const removeErrorMessage = (id) => {
    const element = document.getElementById(`missing-${id}`);
    if (element) element.remove();
  };

  const checkForMissingFields = (value, child, parent) => {
    let hasMissingField = false;
    let id;
    if (child.isRequired && Array.isArray(value) && value.length <= 1) {
      // checking if section is not applicable
      for (let i = 0; i < child.children.length; i += 1) {
        if (value[0][child.children[i].dataKey] === 'N/A') return false;
      }
      for (let i = 0; i < child.children.length; i += 1) {
        hasMissingField = checkForMissingFields(
          value[0][child.children[i].dataKey],
          child.children[i],
          child,
        );
      }
      if (!hasMissingField) {
        id = `input-${parent.dataKey}-${child.label?.en}`;
        removeErrorMessage(id);
      }
    } else if (child.isRequired && typeof value === 'object' && value !== null && !Array.isArray(value)) {
      Object.keys(value).forEach((key, index) => {
        hasMissingField = checkForMissingFields(value[key], child.children[index], child);
      });
    } else if (child.isRequired && !value) {
      id = `input-${parent.dataKey}-${child.label?.en}`;
      removeErrorMessage(id);
      handleMissingField(id);
      hasMissingField = true;
    } else if (child.isRequired && value) {
      if (child.label) id = `input-${parent.dataKey}-${child.label?.en}`;
      else if (child.itemLabel) id = `input-${parent.dataKey}-${child.itemLabel?.en}`;
      removeErrorMessage(id);
    }
    return hasMissingField;
  };

  const handleNewBusinessActivityClick = () => {
    /** Don't allow to go next if plan is not saved */
    if (!checkSaved()) {
      return;
    }

    setNewBusinessActivityClicked(true);
    setNewOrClonedBA(true);
    document.getElementById('save-button').click();
    if (document.querySelectorAll('[id ^= "missing-"]').length > 0) setNewBusinessActivityClicked(false);
  };

  const decrementBusinessActivity = () => {
    setNextClicked(false);
    setBackButtonClicked(true);

    let missingFields = document.querySelectorAll('[id ^= "missing-"]');

    if (missingFields.length !== 0) {
      setOpenSaveErrorModal(true);
      return;
    }

    const { search } = history.location;
    if (search.match(`sectionKey=${plan.layout.sections[0].dataKey}`)
      && currentBusinessActivity === 0) {
      history.replace('/');
    } else if (search.match('sectionKey=contacts')) {
      history.replace({
        pathname: '/edit-plan',
        search: `?planId=${planId}&sectionKey=recovery`,
      });
    } else if (search.match('sectionKey=recovery')) {
      history.replace({
        pathname: '/edit-plan',
        search: `?planId=${planId}&sectionKey=businessAsUsualActivities`,
      });
    } else if (search.match('sectionKey=businessAsUsualActivities')) {
      history.replace({
        pathname: '/edit-plan',
        search: `?planId=${planId}&sectionKey=businessImpactAnalysis&startActivity=${currentBusinessActivity}`,
      });
    } else if (search.match('sectionKey=recoveryLocations')) {
      history.replace({
        pathname: '/edit-plan',
        search: `?planId=${planId}&sectionKey=contacts`,
      });
    } else if (currentBusinessActivity !== 0) {
      setCurrentBusinessActivity(currentBusinessActivity - 1);
    } else if (currentBusinessActivity === 0) {
      history.replace('/');
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setNextDisabled(false);
    missingFields = document.querySelectorAll('[id ^= "missing-"]');
    for (let i = 0; i < missingFields.length; i += 1) missingFields[i].remove();
  };

  const incrementBusinessActivity = () => {
    setNextClicked(true);
    const missingFields = document.querySelectorAll('[id ^= "missing-"]');
    if (missingFields.length === 0) {
      let maxIndex;
      if (plan.values.businessImpactAnalysis) { // handles the case where some plans don't have BA's
        maxIndex = plan.values.businessImpactAnalysis.businessActivities.length - 1;
      } else {
        maxIndex = currentBusinessActivity;
      }
      const { search } = history.location;
      if (search.match(`sectionKey=${plan.layout.sections[plan.layout.sections.length - 1].dataKey}`)) {
        history.push({
          pathname: '/review-plan',
          search: `?planId=${planId}`,
        });
      } else if (search.match('sectionKey=recovery')) {
        history.replace({
          pathname: '/edit-plan',
          search: `?planId=${planId}&sectionKey=contacts`,
        });
      } else if (
        currentBusinessActivity === maxIndex
        && search.match('sectionKey=businessImpactAnalysis')
        && search.match(`startActivity=${currentBusinessActivity}`)
      ) {
        history.replace({
          pathname: '/edit-plan',
          search: `?planId=${planId}&sectionKey=businessAsUsualActivities`,
        });
      } else if (search.match('sectionKey=businessAsUsualActivities')) {
        history.replace({
          pathname: '/edit-plan',
          search: `?planId=${planId}&sectionKey=recovery`,
        });
      } else if (search.match('sectionKey=contacts')) {
        history.replace({
          pathname: '/edit-plan',
          search: `?planId=${planId}&sectionKey=recoveryLocations`,
        });
      } else if (search.match('sectionKey=recoveryLocations')) {
        history.replace({
          pathname: '/review-plan',
          search: `?planId=${planId}`,
        });
      } else if (currentBusinessActivity !== maxIndex) {
        setCurrentBusinessActivity(currentBusinessActivity + 1);
      }
    } else {
      setOpenSaveErrorModal(true);
    }
  };

  /**
   * Serves as an initial check to avoid empty required fields
   * @param {*} currentPlan plan being edited
   */
  const initialCheckForFields = () => {
    const section = plan.layout.sections.find((s) => s.dataKey === sectionKey);
    let value;
    let values;
    let sectionChildren;
    let parentSection;
    if (section) {
      if (section.dataKey === 'businessImpactAnalysis') {
        values = plan.values.businessImpactAnalysis
          .businessActivities[currentBusinessActivity];
        sectionChildren = section.content[0].children;
        parentSection = { ...section.content[0] };
      } else if (section.dataKey === 'businessAsUsualActivities') {
        values = plan.values.businessAsUsualActivities;
        sectionChildren = section.content;
        parentSection = section;
      } else if (section.dataKey === 'recovery') {
        values = plan.values.recovery;
        sectionChildren = section.content;
        parentSection = section;
      } else if (section.dataKey === 'recoveryLocations') {
        values = plan.values.recoveryLocations;
        sectionChildren = section.content;
        parentSection = section;
      } else if (section.dataKey === 'contacts') {
        values = plan.values.contacts;
        sectionChildren = section.content;
        parentSection = section;
      }
      if (sectionChildren) {
        for (let i = 0; i < sectionChildren.length; i += 1) {
          if (sectionChildren[i].isRequired) {
            if (values) {
              value = values[sectionChildren[i].dataKey];
              checkForMissingFields(value, sectionChildren[i], parentSection);
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (plan) {
      initialCheckForFields();
    }
  }, [plan, sectionKey, nextClicked, newOrClonedBA]);

  const handleCloneBusinessActivityClick = () => {
    setCloneBusinessActivityClicked(true);
    setNewOrClonedBA(true);
    document.getElementById('save-button').click();
    if (document.querySelectorAll('[id ^= "missing-"]').length > 0) setCloneBusinessActivityClicked(false);
  };

  const reviewPlan = () => {
    history.push({
      pathname: '/review-plan',
      search: `?planId=${planId}`,
    });
  };

  const onDiscard = () => {
    plan.values = planValues; // reset the component state
    resetSaveButtons();
    setNextDisabled(false);
  };

  return (
    <PlanProvider value={getPlan}>
      {plan && labels.length > 0 && renderSidebar && editSidebar && (
        <Sidebar
          closeSidebar={() => {
            sidebarClose();
          }}
          items={labels}
        />
      )}
      <div className="edit-my-plan">
        <div className="edit-my-plan-content">
          {currentSection && plan && (
            <>
              <div className="notification-container">
                {showUnsavedChangesBox && (
                  <div className="notification-div" id="unsaved-changes-div">
                    <p className="notification-text">
                      {t('scrollToTop')}
                    </p>
                    <button
                      type="button"
                      className="notification-button"
                      onClick={() => {
                        setShowUnsavedChangesBox(false);
                      }}
                    >
                      X
                    </button>
                  </div>
                )}
                {showNewBABox && (
                  <div className="notification-div" id="new-BA-div">
                    <p className="notification-text">
                      {t('newBusinessActivityCreated')}
                    </p>
                    <button
                      type="button"
                      className="notification-button"
                      onClick={() => {
                        setShowNewBABox(false);
                      }}
                    >
                      X
                    </button>
                  </div>
                )}
              </div>
              <div id="edit-plan-header">
                <span className="edit-page-plan-name" id="plan-name">{plan.name}</span>
                <div className="buttons-div">
                  <button
                    type="button"
                    id="decrement-button-1"
                    onClick={decrementBusinessActivity}
                  >
                    {t('back')}
                  </button>

                  <SaveButton
                    resetSaveButtons={resetSaveButtons}
                    setOpenWarningModal={setOpenWarningModal}
                    currentBusinessActivity={currentBusinessActivity}
                    checkForMissingFields={checkForMissingFields}
                    section={currentSection}
                    reduxSelectors={['values', 'urgentStaffTable', 'highStaffTable', 'medStaffTable', 'lowStaffTable']}
                    planId={planId}
                    newOrClonedBA={newOrClonedBA}
                    setNewOrClonedBA={setNewOrClonedBA}
                    saveDisabled={saveDisabled}
                    setSaveDisabled={setSaveDisabled}
                  />
                  <button
                    onClick={() => {
                      history.replace('/');
                    }}
                    type="button"
                  >
                    {t('cancel')}
                  </button>
                  <div className="nextButtonWrapper" data-tip data-for="incrementTip">
                    <button
                      id="increment-button-1"
                      type="button"
                      onClick={incrementBusinessActivity}
                      disabled={nextDisabled}
                    >
                      {t('next')}
                    </button>
                  </div>
                  <SaveButton
                    resetSaveButtons={resetSaveButtons}
                    setOpenWarningModal={setOpenWarningModal}
                    currentBusinessActivity={currentBusinessActivity}
                    checkForMissingFields={checkForMissingFields}
                    section={currentSection}
                    reduxSelectors={['values', 'urgentStaffTable', 'highStaffTable', 'medStaffTable', 'lowStaffTable']}
                    planId={planId}
                    newOrClonedBA={newOrClonedBA}
                    setNewOrClonedBA={setNewOrClonedBA}
                    saveDisabled={saveDisabled}
                    setSaveDisabled={setSaveDisabled}
                    reviewPlan={reviewPlan}
                    buttonLabel={t('review')}
                  />
                </div>
              </div>
              {Object.keys(planValues).length !== 0 && (
              <PlanSection
                unsavedChanges={unsavedChanges}
                resetSaveButtons={resetSaveButtons}
                currentBusinessActivity={currentBusinessActivity}
                checkForMissingFields={checkForMissingFields}
                removeErrorMessage={removeErrorMessage}
                section={currentSection}
                values={planValues}
                tables={planTables}
                planId={planId}
                plan={plan}
                deleteBA={deleteBA}
                handleCloneBusinessActivityClick={handleCloneBusinessActivityClick}
                handleNewBusinessActivityClick={handleNewBusinessActivityClick}
                setNextDisabled={setNextDisabled}
              />
              )}
              <div className="buttons-div-bottom">
                <div className="buttons-div">
                  <button
                    type="button"
                    id="decrement-button-2"
                    onClick={decrementBusinessActivity}
                  >
                    {t('back')}
                  </button>
                  <SaveButton
                    resetSaveButtons={resetSaveButtons}
                    setOpenWarningModal={setOpenWarningModal}
                    currentBusinessActivity={currentBusinessActivity}
                    checkForMissingFields={checkForMissingFields}
                    section={currentSection}
                    reduxSelectors={['values', 'urgentStaffTable', 'highStaffTable', 'medStaffTable', 'lowStaffTable']}
                    planId={planId}
                    newOrClonedBA={newOrClonedBA}
                    setNewOrClonedBA={setNewOrClonedBA}
                    saveDisabled={saveDisabled}
                    setSaveDisabled={setSaveDisabled}
                  />
                  <button
                    onClick={() => {
                      history.replace('/');
                    }}
                    type="button"
                  >
                    {t('cancel')}
                  </button>
                  <div className="nextButtonWrapper" data-tip data-for="incrementTip">
                    <button
                      id="increment-button-2"
                      type="button"
                      onClick={incrementBusinessActivity}
                      disabled={nextDisabled}
                    >
                      {t('next')}
                    </button>
                  </div>
                  {nextDisabled && (
                    <ReactTooltip id="incrementTip" place="bottom" type="light" effect="solid" border>
                      <div>{t('saveChanges')}</div>
                    </ReactTooltip>
                  )}
                  <SaveButton
                    resetSaveButtons={resetSaveButtons}
                    setOpenWarningModal={setOpenWarningModal}
                    currentBusinessActivity={currentBusinessActivity}
                    checkForMissingFields={checkForMissingFields}
                    section={currentSection}
                    reduxSelectors={['values', 'urgentStaffTable', 'highStaffTable', 'medStaffTable', 'lowStaffTable']}
                    planId={planId}
                    newOrClonedBA={newOrClonedBA}
                    setNewOrClonedBA={setNewOrClonedBA}
                    saveDisabled={saveDisabled}
                    setSaveDisabled={setSaveDisabled}
                    reviewPlan={reviewPlan}
                    buttonLabel={t('review')}
                  />
                </div>
              </div>
              {currentSection.dataKey === 'businessImpactAnalysis' && (
                <div className="new-business-activity-buttons-div">
                  <button
                    id="clone-business-activity"
                    type="button"
                    className="new-business-activity-buttons"
                    onClick={handleCloneBusinessActivityClick}
                  >
                    {t('cloneBusinessActivity')}
                  </button>
                  <button
                    id="add-new-business-activity"
                    type="button"
                    className="new-business-activity-buttons"
                    onClick={handleNewBusinessActivityClick}
                  >
                    {t('newBusinessActivity')}
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {
        openWarningModal && (
          <Modal
            isOpen={openWarningModal}
            handleClose={() => setOpenWarningModal(false)}
            style={customStyles}
            ariaHideApp={false}
          >
            <p id="modal-text-red">{t('staffingRequirements.warningMessage.cannotBeZero')}</p>
            <p className="modal-text">{t('staffingRequirements.warningMessage.body')}</p>
            <div className="modal-buttons">
              <button type="button" id="ok-button" onClick={() => setOpenWarningModal(false)}>
                {t('ok')}
              </button>
            </div>
          </Modal>
        )
      }
      <SaveErrorModal
        isOpen={openSaveErrorModal}
        handleClose={() => {
          setOpenSaveErrorModal(false);
          const missing = document.querySelectorAll('[id ^= "missing-"]');
          if (missing.length > 0) {
            setTimeout(() => document.getElementById(`${missing[0].id}`)
              .scrollIntoView({ behavior: 'smooth', block: 'start' }), 200);
          } else if (newOrClonedBA) {
            setTimeout(() => window.scrollTo({
              top: 0,
              behavior: 'smooth',
            }));
            setNewOrClonedBA(false);
          }
        }}
        message={t('saveErrorModalMessage')}
      />
      <OldSessionModal
        isOpen={oldSessionModalOpen}
        handleClose={() => setOldSessionModalOpen(false)}
        tempPlan={oldSessionPlan}
        setPlan={(p) => {
          updatePlanValues(JSON.parse(JSON.stringify(p.values)));
          updatePlanTables(JSON.parse(JSON.stringify({
            urgentStaffTable: p.urgentStaffTable,
            highStaffTable: p.highStaffTable,
            medStaffTable: p.medStaffTable,
            lowStaffTable: p.lowStaffTable,
          })));
          unsavedChanges(true);
        }}
        discard={() => axios.delete(
          `/plan/temp/${planId}`,
        )}
      />
      <NavigationPrompt when={(crntLocation, nextLocation) => {
        const beginningNextUrl = nextLocation.pathname.split('/')[1];
        const beginningCrntUrl = crntLocation.pathname.split('/')[1];
        /** only when user leaves /edit-plan/... */
        return (
          (beginningCrntUrl !== beginningNextUrl && !checkSaved())
          || (history.location.search.includes('sectionKey=businessAsUsualActivities')
            && crntLocation !== nextLocation
            && (!checkSaved()))
        );
      }}
      >
        {({ onConfirm, onCancel }) => (
          <MessageModal
            resetSaveButtons={resetSaveButtons}
            currentBusinessActivity={currentBusinessActivity}
            checkForMissingFields={checkForMissingFields}
            section={currentSection}
            action={backButtonClicked ? 'back' : 'next'}
            isOpen={renderModal}
            onConfirm={onConfirm}
            onCancel={onCancel}
            onDiscard={onDiscard}
            message={t('saveEntriesMessage')}
            handleClose={() => setRenderModal(false)}
            unmount
            planId={planId}
            setOpenWarningModal={setOpenWarningModal}
          />
        )}
      </NavigationPrompt>
    </PlanProvider>
  );
};

EditPlanPage.defaultProps = {
  renderSidebar: null,
  updatePlanValues: null,
  updatePlanTables: null,
  updateSidebarRender: null,
};

EditPlanPage.propTypes = {
  planValues: PropTypes.shape({}).isRequired,
  planTables: PropTypes.shape({
    urgentStaffTable: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    highStaffTable: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    medStaffTable: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    lowStaffTable: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  }).isRequired,
  renderSidebar: PropTypes.bool,
  updatePlanValues: PropTypes.func,
  updatePlanTables: PropTypes.func,
  updateSidebarRender: PropTypes.func,
  setSaveState: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  planValues: state.values,
  planTables: {
    urgentStaffTable: state.planTables.urgentStaffTable,
    highStaffTable: state.planTables.highStaffTable,
    medStaffTable: state.planTables.medStaffTable,
    lowStaffTable: state.planTables.lowStaffTable,
  },
  renderSidebar: state.sidebar.renderSidebar,
});

export default connect(mapStateToProps, {
  updatePlanValues: updatePlanValuesAction,
  updatePlanTables: updatePlanTablesAction,
  updateSidebarRender: renderSidebarAction,
})(withRouter(EditPlanPage));
